import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faFileDownload } from "@fortawesome/pro-duotone-svg-icons";

//Form
import Form, { GroupItem, Label, Item } from 'devextreme-react/form';

//Validator
import { EmailRule, CustomRule, RequiredRule } from 'devextreme-react/validator';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

async function validaFactura(payload) {
  let apiName = 'AdminSC';
  let path = '/validafactura';

  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'G9zn5KTz8y2PMll11TXx'
    },
    body: {
      payload
    }
  }
  const validaFacturaResponse = await API.post(apiName, path, myInit);

  return validaFacturaResponse;
}

export default function DownloadInvoice(props) {
  const [downloadInvoicePayload, setDownloadInvoicePayload] = useState({
    folioPedido: "",
    importePedido: "",
    apellido: "",
    pedidoValido: false,
    urlPDF: '',
    urlXML: '',
    nombreXML: '',
    nombrePDF: ''
  });

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  //Ref para el form
  const [formDownloadInvoiceRef, setFormDownloadInvoiceRef] = useState(React.createRef());

  async function searchInvoice() {
    if (formDownloadInvoiceRef.current) {
      var formDownloadInvoice = formDownloadInvoiceRef.current.instance();
      var isValid = formDownloadInvoice.validate();

      if (!isValid.isValid) {
        setVisibleToast(true);
        setMessageToast(isValid.brokenRules[0].message);
        setTypeToast('error');
        return;
      }

      var payload = JSON.parse(JSON.stringify(downloadInvoicePayload));

      setVisibleLoadPanel(true);

      const validaFacturaResponse = await validaFactura(payload);

      setVisibleLoadPanel(false);

      if (validaFacturaResponse.result.toString() === 'true') {
        payload.pedidoValido = true;
        payload.urlPDF = validaFacturaResponse.urlPDF;
        payload.urlXML = validaFacturaResponse.urlXML;
        payload.nombreXML = validaFacturaResponse.nombreXML;
        payload.nombrePDF = validaFacturaResponse.nombrePDF;

        setDownloadInvoicePayload(payload);
      }
      else {
        setVisibleToast(true);
        setMessageToast('Datos Incorrectos. Favor de comunicarse a los teléfonos de su asesor de ventas');
        setTypeToast('error')

        payload.pedidoValido = false;
        payload.urlPDF = '';
        payload.urlXML = '';
        payload.nombreXML = '';
        payload.nombrePDF = '';

        setDownloadInvoicePayload(payload);
      }
    }
  }

  function downloadXML() {
    var payload = JSON.parse(JSON.stringify(downloadInvoicePayload));

    payload.action = 'xml';

    const downloadXMLFile = async () => {
      let apiName = 'AdminSC';
      let path = '/downloadlinkfactura';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'G9zn5KTz8y2PMll11TXx'
        },
        body: {
          payload
        }
      }
      var downloadFileResponse = await API.post(apiName, path, myInit);

      const url = window.URL.createObjectURL(
        new Blob([downloadFileResponse]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        payload.nombreXML,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    downloadXMLFile()
  }

  function downloadPDF() {
    var payload = JSON.parse(JSON.stringify(downloadInvoicePayload));

    payload.action = 'pdf';

    const downloadPDFFile = async () => {
      let apiName = 'AdminSC';
      let path = '/downloadlinkfactura';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'G9zn5KTz8y2PMll11TXx'
        },
        body: {
          payload
        }
      }
      var downloadFileResponse = await API.post(apiName, path, myInit);

      const link = document.createElement('a');

      link.href = 'data:application/pdf;base64,' + downloadFileResponse;
      link.setAttribute(
        'download',
        payload.nombrePDF
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    downloadPDFFile()
  }

  const onFieldDatosGeneralesChanged = React.useCallback((e) => {
    var payload = JSON.parse(JSON.stringify(downloadInvoicePayload));

    payload.pedidoValido = false;
    payload.urlPDF = '';
    payload.urlXML = '';
    payload.nombreXML = '';
    payload.nombrePDF = '';

    setDownloadInvoicePayload(payload);
  }, [downloadInvoicePayload])

  const onHidingToast = React.useCallback(() => {
    setVisibleToast(false);
  }, []);

  var folioPedidoEditorOptions = { width: '40%', readOnly: false, inputAttr: { 'style': 'text-transform: uppercase' } };
  var importePedidoEditorOptions = { width: '40%', format: { type: 'currency', currency: 'MXN', precision: 2 }, readOnly: false };
  var apellidoEditorOptions = { width: '40%', readOnly: false, inputAttr: { 'style': 'text-transform: uppercase' } };

  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faFileDownload} size="xl" /> Descarga tu Factura</h1>
      </div>
      <div>
        <button onClick={searchInvoice} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faSearch} /> Buscar Factura
        </button>
        {downloadInvoicePayload.pedidoValido === true ? (
          <div class="inline-block">
            <button onClick={downloadXML} class="ml-2 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"> <FontAwesomeIcon icon={faFileAlt} /> Descargar XML</button>
            <button onClick={downloadPDF} type="button" class="ml-2 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"> <FontAwesomeIcon icon={faFilePdf} /> Descargar PDF</button>
          </div>
        ) : (<div class="inline-block"></div>)
        }
      </div>
      <div className="bg-white p-4 shadow-xl m-2">
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={visibleLoadPanel}
          showIndicator={true}
          message="Por favor espere..."
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />

        <Toast
          visible={visibleToast}
          message={messageToast}
          type={typeToast}
          onHiding={onHidingToast}
          displayTime={5000}
          width={'auto'}
          position={{
            my: 'top center',
            at: 'top center',
            of: window,
            offset: '0 130'
          }}
        />
        <Form
          id="formPassword"
          labelMode={'outside'}
          formData={downloadInvoicePayload}
          disabled={false}
          showColonAfterLabel={false}
          labelLocation={'top'}
          colCount={1}
          ref={formDownloadInvoiceRef}
          onFieldDataChanged={onFieldDatosGeneralesChanged}
        >
          <Item dataField="folioPedido" editorOptions={folioPedidoEditorOptions}>
            <Label text={"Número del Pedido"} />
            <RequiredRule message="Número del Pedido es requerido" />
          </Item>
          <Item dataField="importePedido" editorType="dxNumberBox" editorOptions={importePedidoEditorOptions}>
            <Label text={"Importe Total del Pedido"} />
            <RequiredRule message="Importe Total del Pedido es requerido" />
          </Item>
          <Item dataField="apellido" editorOptions={apellidoEditorOptions}>
            <Label text={"Apellido o razón social"} />
            <RequiredRule message="Apellido o razón social es requerido" />
          </Item>
        </Form>
      </div>
    </div>
  )
};