import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem } from 'devextreme-react/form';

import { Auth, API, Storage, label } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faInboxOut } from "@fortawesome/pro-duotone-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faScannerTouchscreen } from "@fortawesome/pro-solid-svg-icons";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { faCheckToSlot } from "@fortawesome/pro-solid-svg-icons";
import { faHand } from "@fortawesome/pro-solid-svg-icons";


import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import '../ListStyle.css';

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

import './FulfillmentOrderWMS.css';


import { confirm } from 'devextreme/ui/dialog';

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function FulfillmentOrderWMS(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [permissionLevel, setPermissionLevel] = useState(props.nivel);
    const [assignFulfillmentOrderWMS, setAssignFulfillmentOrderWMS] = useState(props.assignFulfillmentOrderWMS);
    const [fulfillmentOrderWMSPayload, setFulfillmentOrderWMSPayload] = useState({
        header: {},
        detail: [],
        movEstatusLog: []
    });

    const [assignFulfillmentOrderWMSPayload, setAssignFulfillmentOrderWMSPayload] = useState({
        header: {
            Articulo: undefined,
            NombreArticulo: undefined,
            Barcode: undefined,
            Posicion: undefined
        },
        detail: []
    });

    const [assignRenglon, setAssignRenglon] = useState(undefined);
    const [assignBarcode, setAssignBarcode] = useState(undefined);

    const [usuarioPick, setUsuarioPick] = useState([]);
    const [usuarioPack, setUsuarioPack] = useState([]);

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [popupSingleBarcodeVisible, setPopupSingleBarcodeVisible] = useState(false);
    const [popupSingleBarcodeTitle, setPopupSingleBarcodeTitle] = useState('');
    const [popupMultipleBarcodeVisible, setPopupMultipleBarcodeVisible] = useState(false);
    const [popupMultipleBarcodeTitle, setPopupMultipleBarcodeTitle] = useState('');

    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activateUpdateGrid, setActivateUpdateGrid] = useState(false);
    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activateAttachments, setActivateAttachments] = useState(false);
    const [activateCancelButton, setActivateCancelButton] = useState(false);
    const [activateEnableFulfillment, setActivateEnableFulfillment] = useState(false);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    //Ref para el form Assign Fulfillment Order WMS
    const [formAssignFulfillmentOrderWMSRef, setFormAssignFulfillmentOrderWMSRef] = useState(React.createRef());
    const [formAssignMultipleFulfillmentOrderWMSRef, setFormAssignMultipleFulfillmentOrderWMSRef] = useState(React.createRef());

    //Ref para el grid Assign Fulfillment Order WMS
    const [gridAssignFulfillmentOrderWMSRef, setGridAssignFulfillmentOrderWMSRef] = useState(React.createRef());
    const [gridAssignMultipleFulfillmentOrderWMSRef, setGridAssignMultipleFulfillmentOrderWMSRef] = useState(React.createRef());


    //Ref para el grid Items
    const [gridItemsRef, setGridItemsRef] = useState(React.createRef());

    const [attachments, setAttachments] = useState([]);

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Pendiente']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getFulfillmentOrdewWMSRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaordensurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    usuario: props.usuario,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var fulfillmentOrderWMSGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return fulfillmentOrderWMSGet;
    }

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getFulfillmentOrdewWMSRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    var fechaEmisionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var nombreAlmacenEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var referenciaEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var tipoSurtidoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var movimientoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var usuarioPickAsignadoEditorOptions = useMemo(() => {
        return {
            showClearButton: true
        };
    }, []);

    var articuloEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var nombreArticuloEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var barcodeEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var posicionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var cantidadEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    async function uploadChunk(file, uploadInfo) {
        try {
            var responseS3 = await Storage.put("/scwms/" + id + "/" + file.name, uploadInfo.chunkBlob, {
                contentType: file.type,
                ACL: 'public-read'
            });

            //Obtenemos el nombre del bucket
            let bucketName = Storage._config.AWSS3.bucket;


            let apiName = 'AdminSC';
            let path = '/uploadattachment';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'register',
                    bucketName: bucketName,
                    type: 'scwms',
                    id: id,
                    Key: "/scwms/" + id + "/" + file.name,
                    fileName: file.name,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var attachmentsResult = await API.post(apiName, path, myInit);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/scwms/" + id);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (err) {
            console.log("Error uploading file:", err);
        }
    }

    async function onAttachmentDeleting(e) {
        var fileName = e.item.key;

        //Primero borramos el archivo de S3
        var attachmentsDelete = await Storage.remove("/scwms/" + id + "/" + fileName);

        //Despues borramos el attachment en Intelisis
        let bucketName = Storage._config.AWSS3.bucket;

        let apiName = 'AdminSC';
        let path = '/uploadattachment';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
                action: 'delete',
                bucketName: bucketName,
                type: 'scwms',
                id: id,
                Key: "/scwms/" + id + "/" + fileName,
                fileName: fileName,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var attachmentsResult = await API.post(apiName, path, myInit);

        //Finalmente sincronizamos el state
        var attachmentsGet = await Storage.list("/scwms/" + id);
        var attachmentsAux = [];

        for (var attachmentsGetIndex in attachmentsGet) {
            var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

            var singleAttachment = {
                name: singleAttachmentsGet.key.split('/').pop(),
                isDirectory: false,
                size: singleAttachmentsGet.size,
            };

            attachmentsAux.push(singleAttachment);
        }

        setAttachments(attachmentsAux);
    };

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    async function onItemDownloading(e) {
        e.cancel = true;

        const downloadFileResponse = await Storage.get("/scwms/" + id + "/" + e.item.name, { download: true });

        downloadBlob(downloadFileResponse.Body, e.item.name);
    };

    function openBlob(blob, filename) {
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
    };

    async function onAttachmentOpen(e) {
        const downloadFileResponse = await Storage.get("/scwms/" + id + "/" + e.file.name, { download: true });

        openBlob(downloadFileResponse.Body, e.file.name);
    };

    async function getfulfillmentOrderWMSPayload(ID) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaordensurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    ID: ID,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setFulfillmentOrderWMSPayload(payloadGet);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/scwms/" + ID);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }

    const getUsuarioPick = React.useCallback(async (sqlFilter) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'UsuarioPick',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var usuarioPickGet = await API.get(apiName, path, myInit);

        setUsuarioPick(usuarioPickGet);
    }, []);

    const getUsuarioPack = React.useCallback(async (sqlFilter) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'UsuarioPack',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var usuarioPackGet = await API.get(apiName, path, myInit);

        setUsuarioPack(usuarioPackGet);
    }, []);

    const getAssignFulfillmentOrderWMSPayload = React.useCallback(async (ID, Renglon) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AsignarBarcode',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                ID: ID,
                Renglon: Renglon
            }
        }

        var assignFulfillmentOrderWMSGet = await API.get(apiName, path, myInit);

        setAssignFulfillmentOrderWMSPayload(assignFulfillmentOrderWMSGet);
    }, []);

    function editFulfillmentOrderWMS(data) {
        return (
            <Link to={`/FulfillmentOrderWMS/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
            </Link>
        )
    }

    async function editButton(ID) {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getfulfillmentOrderWMSPayload(ID);

                if (permissionLevel === 'Editar') {
                    if (payload.header.Estatus === 'Pendiente') {
                        setActivateUpdateForm(true);
                        setActivateUpdateGrid(true);
                        setActivateSaveButton(true);
                        setActivateConfirmButton(false);

                        setActivatePDFButton(false);
                        setActivateAttachments(true);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(false);

                        if (!payload.header.ActivarSurtido) {
                            setActivateEnableFulfillment(true);
                        }
                        else {
                            setActivateEnableFulfillment(false);
                        }
                    }
                    else if (payload.header.Estatus === 'Cancelado') {
                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(false);
                        setActivateSaveButton(false);
                        setActivateConfirmButton(false);

                        setActivatePDFButton(false);
                        setActivateAttachments(false);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(false);
                        setActivateEnableFulfillment(false);
                    }
                    else if (payload.header.Estatus === 'Concluido') {
                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(false);
                        setActivateSaveButton(false);
                        setActivateConfirmButton(false);

                        setActivatePDFButton(false);
                        setActivateAttachments(false);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(true);
                        setActivateEnableFulfillment(false);
                    }
                }
                else if (permissionLevel === 'Ver') {
                    setActivateUpdateForm(false);
                    setActivateUpdateGrid(false);
                    setActivateSaveButton(false);
                    setActivateConfirmButton(false);

                    setActivatePDFButton(false);
                    setActivateAttachments(false);
                    setActivateDeleteButton(false);
                    setActivateCancelButton(false);
                    setActivateEnableFulfillment(false);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);
        }
    };

    async function cancelEditButton() {
        setIsDetail(false);
    };

    async function saveButton() {
        try {
            setVisibleLoadPanel(true);

            var ID = fulfillmentOrderWMSPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaordensurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: fulfillmentOrderWMSPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se actualizó la Orden de Surtido con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function enableFulfillmentButton() {
        try {
            setVisibleLoadPanel(true);

            var ID = fulfillmentOrderWMSPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaordensurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: fulfillmentOrderWMSPayload,
                    action: 'enableAll',
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Surtido activado con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function enableItemFulfillmentButton(Renglon) {
        try {
            setVisibleLoadPanel(true);

            var ID = fulfillmentOrderWMSPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaordensurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: fulfillmentOrderWMSPayload,
                    action: 'enableFulfillment',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    renglon: Renglon
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Surtido activado con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function assignFulfillmentButton(ID, Renglon, EtiquetaPorPieza) {
        await getAssignFulfillmentOrderWMSPayload(ID, Renglon);

        if (EtiquetaPorPieza) {
            setAssignBarcode(undefined);
            setAssignRenglon(Renglon);

            if (gridAssignFulfillmentOrderWMSRef.current) {
                var gridAssignFulfillmentOrderWMS = gridAssignFulfillmentOrderWMSRef.current.instance();

                gridAssignFulfillmentOrderWMS.clearSelection();
            }

            setPopupSingleBarcodeTitle('Reasignar Barcode');
            setPopupSingleBarcodeVisible(true);
        }
        else {
            setAssignBarcode(undefined);
            setAssignRenglon(Renglon);

            if (gridAssignMultipleFulfillmentOrderWMSRef.current) {
                var gridAssignMultipleFulfillmentOrderWMS = gridAssignMultipleFulfillmentOrderWMSRef.current.instance();

                gridAssignMultipleFulfillmentOrderWMS.clearSelection();
            }

            setPopupMultipleBarcodeTitle('Reasignar Barcode');
            setPopupMultipleBarcodeVisible(true);
        }
    }

    async function cancelItemFulfillmentButton(Renglon) {
        try {
            setVisibleLoadPanel(true);

            var ID = fulfillmentOrderWMSPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaordensurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: fulfillmentOrderWMSPayload,
                    action: 'cancelFulfillment',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    renglon: Renglon
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Surtido cancelado con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function finishItemFulfillmentButton(Renglon) {
        try {
            setVisibleLoadPanel(true);

            var ID = fulfillmentOrderWMSPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaordensurtido';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: fulfillmentOrderWMSPayload,
                    action: 'finishFulfillment',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    renglon: Renglon
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Surtido finalizado con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance();

            gridTablero.refresh();
        }
    }

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=fulfillmentorderwms&id=" + id, "_blank");
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/FulfillmentOrderWMS') {
                history.push('/FulfillmentOrderWMS');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            await getUsuarioPick();

            await getUsuarioPack();

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/FulfillmentOrderWMS/', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
        }
        // eslint-disable-next-line
    }, []);

    function onHidingToast() {
        setVisibleToast(false);
    }

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    function enableFulfillItem(data) {
        if (permissionLevel === 'Editar' && data.data.Renglon && (!data.data.Estatus || data.data.Estatus === '') && fulfillmentOrderWMSPayload.header.Estatus === 'Pendiente')
            return (
                <button class="bg-green-500 hover:bg-green-700 text-white py-1 px-3 rounded" onClick={() => enableItemFulfillmentButton(data.data.Renglon)}><FontAwesomeIcon icon={faScannerTouchscreen} size='lg' /></button>
            )
        if (permissionLevel === 'Editar' && data.data.Renglon && data.data.Estatus === 'En Proceso' && fulfillmentOrderWMSPayload.header.Estatus === 'Pendiente' && !data.data.CantidadRecibida)
            return (
                <button class="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded" onClick={() => cancelItemFulfillmentButton(data.data.Renglon)}><FontAwesomeIcon icon={faBan} size='lg' /></button>
            )
        if (permissionLevel === 'Editar' && data.data.Renglon && data.data.Estatus === 'En Proceso' && fulfillmentOrderWMSPayload.header.Estatus === 'Pendiente' && data.data.CantidadRecibida)
            return (
                <button class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded" onClick={() => finishItemFulfillmentButton(data.data.Renglon)}><FontAwesomeIcon icon={faCheckToSlot} size='lg' /></button>
            )
    };

    function assignFulfillItem(data) {
        if (permissionLevel === 'Editar' && data.data.Renglon && (!data.data.Estatus || data.data.Estatus === '') && fulfillmentOrderWMSPayload.header.Estatus === 'Pendiente' && assignFulfillmentOrderWMS)
            return (
                <button class="bg-yellow-500 hover:bg-yellow-700 text-white py-1 px-3 rounded" onClick={() => assignFulfillmentButton(data.data.ID, data.data.Renglon, data.data.EtiquetaPorPieza)}><FontAwesomeIcon icon={faHand} size='lg' /></button>
            )
    };

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    function onEditorPreparing(e) {
        if (e.parentType === 'dataRow' && e.dataField === 'UsuarioPickAsignado') {
            if (fulfillmentOrderWMSPayload.header.Estatus !== 'Pendiente' || e.row.data.CantidadProcesada || e.row.data.CantidadEmpacada) {
                e.editorOptions.disabled = true;
            }
        }

        //Hacemos la data persistente en cuanto el usuario escriba en el campo
        if (e.parentType === "dataRow") {
            e.editorOptions.valueChangeEvent = "input";
        }
    }

    function hidePopupInfo() {
        setPopupSingleBarcodeVisible(false);
    };

    function hideMultiplePopupInfo() {
        setPopupMultipleBarcodeVisible(false);
    };

    function getTotalSelection() {
        var total = 0;

        if (gridAssignMultipleFulfillmentOrderWMSRef.current) {
            var gridAssignMultipleFulfillmentOrderWMS = gridAssignMultipleFulfillmentOrderWMSRef.current.instance();

            total = gridAssignMultipleFulfillmentOrderWMS.getTotalSummaryValue('CantidadA');

            return parseFloat(total);
        }
    };

    async function confirmAssignMultipleButton() {
        if (gridAssignMultipleFulfillmentOrderWMSRef.current) {
            var gridAssignMultipleFulfillmentOrderWMS = gridAssignMultipleFulfillmentOrderWMSRef.current.instance();
            var cantidad = parseFloat(assignFulfillmentOrderWMSPayload.header.Cantidad);
            var total = getTotalSelection();
            var selectedData = gridAssignMultipleFulfillmentOrderWMS.getSelectedRowsData();

            if (cantidad === total && total > 0) {
                var result = confirm("<i>Se asignarán los Barcodes . ¿Desea continuar?</i> ", "Resasignar Barcode");
                result.then(async (dialogResult) => {
                    if (dialogResult) {
                        try {
                            setVisibleLoadPanel(true);

                            var ID = fulfillmentOrderWMSPayload.header.ID;

                            var arrayBarcodes = [];

                            for (var selectedDataIndex in selectedData) {
                                var singleSelectedData = selectedData[selectedDataIndex];

                                if (parseFloat(singleSelectedData.CantidadA)) {
                                    arrayBarcodes.push({
                                        Barcode: singleSelectedData.Barcode,
                                        Cantidad: singleSelectedData.CantidadA
                                    });
                                }
                            }

                            //Enviamos todo al backend
                            let apiName = 'AdminSC';
                            let path = '/procesaordensurtido';

                            let myInit = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                                },
                                body: {
                                    ID: ID,
                                    renglon: assignRenglon,
                                    assignBarcode: assignBarcode,
                                    payload: {
                                        Barcodes: arrayBarcodes
                                    },
                                    action: 'assignBarcode',
                                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                                    usuario: props.usuario,
                                    sucursal: props.sucursal
                                }
                            };

                            var payloadPost = await API.post(apiName, path, myInit);

                            if (payloadPost.ok === 'true') {
                                setVisibleToast(true);
                                setMessageToast('Barcodes asignados con éxito');
                                setTypeToast('success');

                                setPopupMultipleBarcodeVisible(false);

                                getfulfillmentOrderWMSPayload(ID);
                            }
                            else if (payloadPost.ok === 'false') {
                                setVisibleToast(true);
                                setMessageToast(payloadPost.message);
                                setTypeToast('error')
                            }

                            editButton(ID);

                            setVisibleLoadPanel(false);
                        }
                        catch (e) {
                            setVisibleLoadPanel(false);

                            if (e === 'No current user') {
                                history.push('/');

                                window.location.reload();
                            }
                        }
                    }
                });
            }
            else {
                setVisibleToast(true);
                setMessageToast('La Cantidad no es igual a la Cantidad a Aplicar');
                setTypeToast('error')
            }
        }
    };

    async function confirmAssignButton() {
        if (assignBarcode) {
            var result = confirm("<i>Se asignará el Barcode " + assignBarcode.toString() + " . ¿Desea continuar?</i> ", "Resasignar Barcode");

            result.then(async (dialogResult) => {
                if (dialogResult) {
                    try {
                        setVisibleLoadPanel(true);

                        var ID = fulfillmentOrderWMSPayload.header.ID;

                        var arrayInvoice = [];

                        //Enviamos todo al backend
                        let apiName = 'AdminSC';
                        let path = '/procesaordensurtido';

                        let myInit = {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                            },
                            body: {
                                ID: ID,
                                renglon: assignRenglon,
                                assignBarcode: assignBarcode,
                                payload: {
                                    Barcodes: [{
                                        Barcode: assignBarcode,
                                        Cantidad: 1
                                    }]
                                },
                                action: 'assignBarcode',
                                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                                usuario: props.usuario,
                                sucursal: props.sucursal
                            }
                        }

                        var payloadPost = await API.post(apiName, path, myInit);

                        if (payloadPost.ok === 'true') {
                            setVisibleToast(true);
                            setMessageToast('Barcode asignado con éxito');
                            setTypeToast('success');

                            setPopupSingleBarcodeVisible(false);

                            getfulfillmentOrderWMSPayload(ID);
                        }
                        else if (payloadPost.ok === 'false') {
                            setVisibleToast(true);
                            setMessageToast(payloadPost.message);
                            setTypeToast('error')
                        }

                        editButton(ID);

                        setVisibleLoadPanel(false);
                    }
                    catch (e) {
                        setVisibleLoadPanel(false);

                        if (e === 'No current user') {
                            history.push('/');

                            window.location.reload();
                        }
                    }
                }
            });
        }
        else {
            setVisibleToast(true);
            setMessageToast('Favor de seleccionar el Barcode a asignar');
            setTypeToast('error')
        }
    };

    const onSelectionAssignChanged = React.useCallback(({ selectedRowsData }) => {
        const data = selectedRowsData[0];

        if (data) {
            setAssignBarcode(data.Barcode);
        }
        else {
            setAssignBarcode(undefined);
        }
    }, []);

    function onEditingAssignStart(e) {
        if (!e.component.isRowSelected(e.key)) {
            e.cancel = true;
        }
    };

    function onSelectionChanged(e) {
        if (gridAssignMultipleFulfillmentOrderWMSRef.current) {
            var gridAssignMultipleFulfillmentOrderWMS = gridAssignMultipleFulfillmentOrderWMSRef.current.instance();

            for (var deselectedIndex in e.currentDeselectedRowKeys) {
                var deselectedKey = e.currentDeselectedRowKeys[deselectedIndex];
                var rowIndex = gridAssignMultipleFulfillmentOrderWMS.getRowIndexByKey(deselectedKey);

                gridAssignMultipleFulfillmentOrderWMS.cellValue(rowIndex, 'CantidadA', null);

                gridAssignMultipleFulfillmentOrderWMS.refresh();
            }
        }
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Ordenes Surtido</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faInboxOut} size="xl" /> {(fulfillmentOrderWMSPayload.header ? fulfillmentOrderWMSPayload.header.Movimiento : 'Orden Surtido')}</h1>
                        <TransactionStatus {...props} Estatus={fulfillmentOrderWMSPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">

                        {permissionLevel === 'Editar' ?
                            (
                                <div>
                                    <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            ) :
                            (
                                <div>
                                    <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            )}

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerFulfillmentOrderWMS" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editFulfillmentOrderWMS} />
                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column dataField="Almacen" caption="Almacén" dataType="number" width={150} />
                            <Column dataField="ActivarSurtido" caption="Activar Surtido" dataType="boolean" width={150} />

                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} visible={false} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />
                            <Column dataField="Referencia" dataType="string" width={190} visible={false} />
                            <Column dataField="TipoSurtido" caption="Tipo de Surtido" dataType="string" width={190} visible={false} />

                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />

                            <Column dataField="NombreAlmacen" caption="Nombre Almacén" dataType="string" width={190} visible={false} />

                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/FulfillmentOrderWMS/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/FulfillmentOrderWMS">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateEnableFulfillment.toString() === 'true' && id ?
                                            (
                                                <button onClick={enableFulfillmentButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faScannerTouchscreen} size='lg' /> Activar Surtido
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <Popup
                                                visible={popupSingleBarcodeVisible}
                                                onHiding={hidePopupInfo}
                                                dragEnabled={true}
                                                hideOnOutsideClick={false}
                                                showCloseButton={true}
                                                showTitle={true}
                                                title={popupSingleBarcodeTitle}
                                                resizeEnabled={true}
                                            >
                                                <button onClick={confirmAssignButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faHand} /> Asignar Barcode
                                                </button>

                                                <Form
                                                    id="formAssignFulfillmentOrderWMS"
                                                    labelMode={'outside'}
                                                    formData={assignFulfillmentOrderWMSPayload}
                                                    readOnly={!activateUpdateForm}
                                                    showColonAfterLabel={false}
                                                    labelLocation={'top'}
                                                    ref={formAssignFulfillmentOrderWMSRef}
                                                >
                                                    <GroupItem caption="Datos Producto" colCount={3}>
                                                        <Item dataField="header.NombreArticulo" editorOptions={nombreArticuloEditorOptions}>
                                                            <Label text="Nombre Artículo" />
                                                        </Item>
                                                        <Item dataField="header.Barcode" editorOptions={barcodeEditorOptions}>
                                                            <Label text="Barcode" />
                                                        </Item>
                                                        <Item dataField="header.Posicion" editorOptions={posicionEditorOptions}>
                                                            <Label text="Posicion" />
                                                        </Item>
                                                    </GroupItem>
                                                    <GroupItem caption="Barcodes Disponibles">
                                                        <DataGrid
                                                            id="gridAssignFulfillmentOrderWMS"
                                                            dataSource={assignFulfillmentOrderWMSPayload.detail}
                                                            allowColumnReordering={true}
                                                            columnsAutoWidth={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={false}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                            onSelectionChanged={onSelectionAssignChanged}
                                                            ref={gridAssignFulfillmentOrderWMSRef}
                                                        >
                                                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                                                            <Paging defaultPageSize={10} />
                                                            <Pager
                                                                visible={true}
                                                                allowedPageSizes={allowedPageSizes}
                                                                displayMode='compact'
                                                                showPageSizeSelector={true}
                                                                showInfo={true}
                                                                showNavigationButtons={true}
                                                            />

                                                            <FilterRow visible={true} />
                                                            <FilterPanel visible={true} />
                                                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                                            <HeaderFilter visible={true} />

                                                            <GroupPanel visible={true} />
                                                            <SearchPanel visible={true} />
                                                            <ColumnChooser
                                                                enabled={true}
                                                                mode='select'
                                                            >
                                                                <ColumnChooserSearch
                                                                    enabled={true}
                                                                />

                                                                <ColumnChooserSelection
                                                                    allowSelectAll={true}
                                                                    selectByClick={true}
                                                                    recursive={true}
                                                                />
                                                            </ColumnChooser>
                                                            <Grouping autoExpandAll={autoExpandAll} />

                                                            <Selection mode="single" />

                                                            <Column dataField="Barcode" caption="Barcode" dataType="number" width={100} visible={true} />
                                                            <Column dataField="Almacen" caption="Almacén" dataType="string" width={100} visible={true} />
                                                            <Column dataField="Cantidad" caption="Cantidad" dataType="number" width={150} visible={true} format=',###' />
                                                            <Column dataField="Posicion" caption="Posición" dataType="string" width={120} visible={true} />
                                                            <Column dataField="FechaIngreso" caption="Fecha de Ingreso" dataType="date" width={150} visible={true} />
                                                        </DataGrid>
                                                    </GroupItem>
                                                </Form>
                                            </Popup>

                                            <Popup
                                                visible={popupMultipleBarcodeVisible}
                                                onHiding={hideMultiplePopupInfo}
                                                dragEnabled={true}
                                                hideOnOutsideClick={false}
                                                showCloseButton={true}
                                                showTitle={true}
                                                title={popupMultipleBarcodeTitle}
                                                resizeEnabled={true}
                                            >
                                                <button onClick={confirmAssignMultipleButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faHand} /> Asignar Barcode(s)
                                                </button>

                                                <Form
                                                    id="formAssignMultipleFulfillmentOrderWMS"
                                                    labelMode={'outside'}
                                                    formData={assignFulfillmentOrderWMSPayload}
                                                    readOnly={!activateUpdateForm}
                                                    showColonAfterLabel={false}
                                                    labelLocation={'top'}
                                                    ref={formAssignMultipleFulfillmentOrderWMSRef}
                                                >
                                                    <GroupItem caption="Datos Producto" colCount={4}>
                                                        <Item dataField="header.NombreArticulo" editorOptions={nombreArticuloEditorOptions}>
                                                            <Label text="Nombre Artículo" />
                                                        </Item>
                                                        <Item dataField="header.Barcode" editorOptions={barcodeEditorOptions}>
                                                            <Label text="Barcode" />
                                                        </Item>
                                                        <Item dataField="header.Posicion" editorOptions={posicionEditorOptions}>
                                                            <Label text="Posicion" />
                                                        </Item>
                                                        <Item dataField="header.Cantidad" editorOptions={cantidadEditorOptions}>
                                                            <Label text="Cantidad" />
                                                        </Item>
                                                    </GroupItem>
                                                    <GroupItem caption="Barcodes Disponibles">
                                                        <DataGrid
                                                            id="gridAssignMultipleFulfillmentOrderWMS"
                                                            keyExpr='Barcode'
                                                            dataSource={assignFulfillmentOrderWMSPayload.detail}
                                                            allowColumnReordering={true}
                                                            columnsAutoWidth={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={false}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                            onEditingStart={onEditingAssignStart}
                                                            onSelectionChanged={onSelectionChanged}
                                                            ref={gridAssignMultipleFulfillmentOrderWMSRef}
                                                            repaintChangesOnly={true}
                                                        >
                                                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                                                            <Paging defaultPageSize={10} />
                                                            <Pager
                                                                visible={true}
                                                                allowedPageSizes={allowedPageSizes}
                                                                displayMode='compact'
                                                                showPageSizeSelector={true}
                                                                showInfo={true}
                                                                showNavigationButtons={true}
                                                            />

                                                            <FilterRow visible={true} />
                                                            <FilterPanel visible={true} />
                                                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                                            <HeaderFilter visible={true} />

                                                            <GroupPanel visible={true} />
                                                            <SearchPanel visible={true} />
                                                            <ColumnChooser
                                                                enabled={true}
                                                                mode='select'
                                                            >
                                                                <ColumnChooserSearch
                                                                    enabled={true}
                                                                />

                                                                <ColumnChooserSelection
                                                                    allowSelectAll={true}
                                                                    selectByClick={true}
                                                                    recursive={true}
                                                                />
                                                            </ColumnChooser>
                                                            <Grouping autoExpandAll={autoExpandAll} />

                                                            <Selection mode="multiple" allowSelectAll={false} />

                                                            <Editing
                                                                mode="cell"
                                                                newRowPosition="last"
                                                                allowUpdating={activateUpdateGrid}
                                                                allowAdding={false}
                                                                allowDeleting={false}
                                                            />

                                                            <Column dataField="Barcode" caption="Barcode" dataType="number" width={100} visible={true} allowEditing={false} />
                                                            <Column dataField="Almacen" caption="Almacén" dataType="string" width={100} visible={true} allowEditing={false} />
                                                            <Column dataField="Cantidad" caption="Cantidad" dataType="number" width={150} visible={true} format=',###' allowEditing={false} />
                                                            <Column dataField="Posicion" caption="Posición" dataType="string" width={120} visible={true} allowEditing={false} />
                                                            <Column dataField="FechaIngreso" caption="Fecha de Ingreso" dataType="date" width={150} visible={true} allowEditing={false} />
                                                            <Column dataField="CantidadA" caption="Cantidad a aplicar" dataType="number" width={200} visible={true} format=',###' allowEditing={true} />
                                                            <Summary recalculateWhileEditing={true}>
                                                                <TotalItem
                                                                    column="CantidadA"
                                                                    summaryType="sum"
                                                                    valueFormat=",###"
                                                                    displayFormat="Cantidad a aplicar {0}"
                                                                    showInColumn="CantidadA"
                                                                >
                                                                </TotalItem>
                                                            </Summary>
                                                        </DataGrid>
                                                    </GroupItem>
                                                </Form>
                                            </Popup>

                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={fulfillmentOrderWMSPayload.header}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={6}>
                                                        <GroupItem caption="General" colSpan={6} colCount={2}>
                                                            <Item cssClass="boldText" dataField="Movimiento" editorOptions={movimientoEditorOptions} >
                                                                <RequiredRule message="Movimiento es requerido" />
                                                            </Item>
                                                            <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                <Label text={"Fecha Emisión"} />
                                                                <RequiredRule message="Fecha Emisión es requerida" />
                                                            </Item>
                                                            <Item dataField="NombreAlmacen" editorOptions={nombreAlmacenEditorOptions}>
                                                                <Label text={"Almacén"} />
                                                                <RequiredRule message="Almacén es requerido" />
                                                            </Item>
                                                            <Item dataField="TipoSurtido" editorOptions={tipoSurtidoEditorOptions}>
                                                                <Label text={"Tipo de Surtido"} />
                                                            </Item>
                                                            <Item dataField="Referencia" editorOptions={referenciaEditorOptions}>
                                                                <Label text={"Referencia"} />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Artículos" colSpan={6} >
                                                            <DataGrid
                                                                id="gridItems"
                                                                ref={gridItemsRef}
                                                                columnsAutoWidth={true}
                                                                dataSource={fulfillmentOrderWMSPayload.detail}
                                                                showBorders={true}
                                                                showColumnLines={true}
                                                                showRowLines={true}
                                                                rowAlternationEnabled={true}
                                                                allowColumnResizing={true}
                                                                columnResizingMode="widget"
                                                                allowColumnReordering={false}
                                                                repaintChangesOnly={true}
                                                                onEditorPreparing={onEditorPreparing}
                                                            >
                                                                <Editing
                                                                    mode="cell"
                                                                    allowUpdating={activateUpdateGrid}
                                                                    allowAdding={false}
                                                                    allowDeleting={false}
                                                                />
                                                                <Sorting mode="none" />

                                                                <Column dataField="Renglon" caption="Renglón" width={80} visible={false} allowEditing={false} />
                                                                <Column dataField="RenglonID" width={190} visible={false} allowEditing={false} />

                                                                <Column dataField="Almacen" caption="Almacén" width={80} allowEditing={false} dataType="string" />
                                                                <Column dataField="Posicion" caption="Posición" width={80} allowEditing={false} dataType="string" />
                                                                <Column dataField="Barcode" caption="Barcode" width={80} allowEditing={false} dataType="string" />
                                                                <Column dataField="ArtDescripcion" caption="Artículo" width={340} allowEditing={false} dataType="string" />
                                                                <Column dataField="Referencia" caption="Referencia" width={150} allowEditing={false} visible={false} dataType="string" />
                                                                <Column dataField="Cantidad" caption="Cantidad" width={80} allowEditing={false} dataType="string" format=',###' />
                                                                <Column dataField="CantidadProcesada" caption="Cant. Procesada" width={130} allowEditing={false} dataType="string" format=',###' />
                                                                <Column dataField="CantidadEmpacada" caption="Cant. Empacada" width={130} allowEditing={false} dataType="string" format=',###' />
                                                                <Column dataField="CantidadEnviada" caption="Cant. Enviada" width={130} allowEditing={false} dataType="string" format=',###' />
                                                                <Column dataField="UsuarioPickAsignado" caption="Usuario Pick" width={200} visible={true} dataType="string" editorOptions={usuarioPickAsignadoEditorOptions}>
                                                                    <Lookup
                                                                        dataSource={usuarioPick}
                                                                        displayExpr="name"
                                                                        valueExpr="value"
                                                                    />
                                                                </Column>
                                                                <Column caption="Estatus" alignment="center" width={120} cellRender={estatusRelatedRecords} />
                                                                <Column caption="" alignment="center" width={60} cellRender={enableFulfillItem} />
                                                                <Column caption="" alignment="center" width={60} cellRender={assignFulfillItem} />
                                                            </DataGrid>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="Observaciones" editorType="dxTextArea" >
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Mensajes WMS">
                                                        <DataGrid
                                                            id="gridMensajesWMS"
                                                            columnsAutoWidth={true}
                                                            dataSource={fulfillmentOrderWMSPayload.wmsmessage}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                                                            <Paging defaultPageSize={10} />
                                                            <Pager
                                                                visible={true}
                                                                allowedPageSizes={allowedPageSizes}
                                                                displayMode='compact'
                                                                showPageSizeSelector={true}
                                                                showInfo={true}
                                                                showNavigationButtons={true}
                                                            />

                                                            <FilterRow visible={true} />
                                                            <FilterPanel visible={true} />
                                                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                                            <HeaderFilter visible={true} />

                                                            <GroupPanel visible={true} />
                                                            <SearchPanel visible={true} />
                                                            <ColumnChooser
                                                                enabled={true}
                                                                mode='select'
                                                            >
                                                                <ColumnChooserSearch
                                                                    enabled={true}
                                                                />

                                                                <ColumnChooserSelection
                                                                    allowSelectAll={true}
                                                                    selectByClick={true}
                                                                    recursive={true}
                                                                />
                                                            </ColumnChooser>
                                                            <Grouping autoExpandAll={autoExpandAll} />

                                                            <Column dataField="Mensaje" dataType="string" width={120} caption="Mensaje" />
                                                            <Column dataField="Renglon" dataType="number" width={100} caption="Renglon" />
                                                            <Column dataField="Barcode" dataType="string" width={140} caption="Barcode" />
                                                            <Column dataField="Articulo" dataType="string" width={120} caption="Artículo" />
                                                            <Column dataField="Cantidad" dataType="number" width={120} caption="Cantidad" format=',###' />
                                                            <Column dataField="username" dataType="string" width={140} caption="Usuario WMS" />
                                                            <Column dataField="Fecha" dataType="date" width={100} caption="Fecha" />
                                                            <Column dataField="Almacen" dataType="string" width={120} caption="Almacén" />
                                                            <Column dataField="Posicion" dataType="string" width={120} caption="Posición" />
                                                            <Column dataField="BarcodeA" dataType="string" width={180} caption="Barcode Procesado" />
                                                            <Column dataField="BarcodeNuevo" dataType="string" width={180} caption="Nuevo Barcode" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={fulfillmentOrderWMSPayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Archivos Anexos" >
                                                        <div className="widget-container">
                                                            <FileUploader
                                                                multiple={true}
                                                                accept="*"
                                                                uploadMode="useButtons"
                                                                chunkSize={20000000}
                                                                maxFileSize={20000000}
                                                                uploadChunk={uploadChunk}
                                                                disabled={!activateAttachments}
                                                            />
                                                        </div>
                                                        <div id="wrapper" className="show-widget" >
                                                            <div id="widget-area">
                                                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                                                    <Permissions
                                                                        create={false}
                                                                        copy={false}
                                                                        move={false}
                                                                        delete={activateAttachments}
                                                                        rename={false}
                                                                        upload={false}
                                                                        download={true}
                                                                    >
                                                                    </Permissions>
                                                                </FileManager>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
