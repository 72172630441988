import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faWindowClose } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import Lambda from 'aws-sdk/clients/lambda';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function AdjustItemWMSLevel(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [permissionLevel, setPermissionLevel] = useState(props.nivel);
    const [approveAdjustItemWMS, setApproveAdjustItemWMS] = useState(props.approveAdjustItemWMS);
    const [adjustItemWMSPayload, setAdjustItemWMSPayload] = useState({
        header: {},
        movEstatusLog: []
    });

    const [almacenes, setAlmacenes] = useState([]);
    const [posiciones, setPosiciones] = useState([]);
    const [submensaje, setSubmensaje] = useState('');

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateCancelButton, setActivateCancelButton] = useState(false);
    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activateApproveButton, setActivateApproveButton] = useState(false);
    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);
    const [activateUpdateGrid, setActivateUpdateGrid] = useState(false);
    const [activateWarehouse, setActivateWarehouse] = useState(true);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    //Ref para el grid Items
    const [gridItemsRef, setGridItemsRef] = useState(React.createRef());

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Sin Afectar']];

    //Opciones Nivel de Permiso
    const subMensajeOptions = useMemo(() => {
        return [
            { value: 'Agregar', name: 'Agregar' },
            { value: 'Eliminar', name: 'Eliminar' },
            { value: 'Ajustar', name: 'Ajustar' }
        ];
    }, []);

    var almacenDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Almacen",
                data: almacenes
            }
        });
    }, [almacenes]);

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getAdjustItemWMSRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaajustewms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var customerPaymentsGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return customerPaymentsGet;
    }

    async function getAlmacenes() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AlmacenWMS',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var almacenesGet = await API.get(apiName, path, myInit);

        setAlmacenes(almacenesGet);
    };

    const getAlmPos = React.useCallback(async (Almacen) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AlmPos',
                Almacen: Almacen,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var posicionesGet = await API.get(apiName, path, myInit);

        setPosiciones(posicionesGet);
    }, []);

    const getAlmPosInfo = React.useCallback(async (Almacen, Posicion, Barcode) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AlmPosInfo',
                Almacen: Almacen,
                Posicion: Posicion,
                Barcode: Barcode,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var almPosInfoGet = await API.get(apiName, path, myInit);

        return almPosInfoGet;
    }, []);

    const getArticulo = React.useCallback(async (sqlFilter) => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ArticuloWMS',
                sqlFilter: sqlFilter,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        return articulosGet;
    }, []);

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getAdjustItemWMSRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    const storeArticulo = useMemo(() => {
        return new CustomStore({
            key: 'value',
            load(loadOptions) {
                let sqlFilter = '';
                [
                    'filter'
                ].forEach((i) => {
                    sqlFilter = loadOptions.searchValue;
                });

                async function getRecords() {
                    if (sqlFilter) {
                        var records = await getArticulo(sqlFilter);

                        return {
                            data: records,
                            totalCount: records.length
                        };
                    }
                    else {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                }

                return getRecords()
                    .then(function (result) {
                        return result;
                    })
                    .catch(() => { throw new Error('Data Loading Error'); });
            },
            byKey: (key) => {
                async function getRecords(key) {
                    var records = await getArticulo(key);

                    return records;
                }

                return getRecords(key);
            }
        })
    }, [getArticulo]);


    var fechaEmisionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var movimientoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var barcodeNuevoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: false,
            format: '#'
        };
    }, []);

    var autorizacionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true,
            format: '#'
        };
    }, []);

    try {
        var submensajeEditorOptions = useMemo(() => {
            return {
                items: subMensajeOptions,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (adjustItemWMSPayload.header.Submensaje ? adjustItemWMSPayload.header.Submensaje : ''),
                readOnly: !activateWarehouse
            };
        }, [subMensajeOptions, adjustItemWMSPayload.header.Submensaje, activateWarehouse]);
    }
    catch (e) {
        var submensajeEditorOptions = {
            items: subMensajeOptions,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: !activateWarehouse,
            value: '',
        };
    }

    //Opciones del campo Almacen
    try {
        var almacenEditorOptions = useMemo(() => {
            return {
                dataSource: almacenDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (adjustItemWMSPayload.header.Almacen ? adjustItemWMSPayload.header.Almacen : ''),
                width: '100%',
                readOnly: !activateWarehouse
            };
        }, [almacenDataSource, adjustItemWMSPayload.header.Almacen, activateWarehouse]);
    }
    catch (e) {
        var almacenEditorOptions = {
            dataSource: almacenDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%',
            readOnly: !activateWarehouse
        };
    }

    const getAdjustItemWMSPayload = React.useCallback(async (ID) => {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaajustewms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    ID: ID,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setAdjustItemWMSPayload(payloadGet);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }, [history])

    function editAdjustItemWMS(data) {
        return (
            <Link to={`/AdjustItemWMS/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
            </Link>
        )
    }

    const newButton = React.useCallback(async () => {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaajustewms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'new',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            };

            var payloadNew = await API.get(apiName, path, myInit);

            setAdjustItemWMSPayload(payloadNew);

            setSubmensaje(payloadNew.header.Submensaje);

            setIsDetail(true);
            setId(undefined);

            setActivateUpdateForm(true);
            setActivateSaveButton(true);
            setActivateDeleteButton(false);
            setActivateConfirmButton(false);
            setActivatePDFButton(false);
            setActivateUpdateGrid(true);
            setActivateCancelButton(false);

            setVisibleLoadPanel(false);

            setActivateWarehouse(true);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [history, props.sucursal, props.usuario]);

    const editButton = React.useCallback(async (ID) => {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getAdjustItemWMSPayload(ID);

                if (permissionLevel === 'Editar') {
                    if (payload.header.Estatus === 'Sin Afectar') {
                        setActivateUpdateForm(true);
                        setActivateSaveButton(true);
                        setActivateCancelButton(false);
                        setActivateDeleteButton(true);
                        setActivateConfirmButton(true);
                        setActivatePDFButton(true);
                        setActivateUpdateGrid(true);
                        setActivateApproveButton(false);

                        if (payload.header.Almacen) {
                            await getAlmPos(payload.header.Almacen);
                        }

                        if (payload.detail.length > 0) {
                            setActivateWarehouse(false);
                        }
                        else {
                            setActivateWarehouse(true);
                        }

                        setSubmensaje(payload.header.Submensaje);
                    }
                    if (payload.header.Estatus === 'Pendiente') {
                        setActivateUpdateForm(false);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(true);

                        if (approveAdjustItemWMS && payload.header.RequiereAutorizacion && !payload.header.Autorizado)
                            setActivateApproveButton(true);
                        else
                            setActivateApproveButton(false);

                        if (payload.header.Autorizado)
                            setActivateConfirmButton(true);
                        else
                            setActivateConfirmButton(false);

                        setActivatePDFButton(true);
                        setActivateUpdateGrid(false);

                        setSubmensaje(payload.header.Submensaje);
                    }
                    else if (payload.header.Estatus === 'Concluido') {
                        setActivateUpdateForm(false);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivatePDFButton(false);
                        setActivateUpdateGrid(false);
                        setActivateWarehouse(false);
                        setActivateCancelButton(false);
                        setActivateApproveButton(false);
                    }
                    else if (payload.header.Estatus === 'Cancelado') {
                        setActivateUpdateForm(false);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivatePDFButton(false);
                        setActivateUpdateGrid(false);
                        setActivateWarehouse(false);
                        setActivateCancelButton(false);
                        setActivateApproveButton(false);
                    }
                }
                else if (permissionLevel === 'Ver') {
                    setActivateUpdateForm(false);
                    setActivateSaveButton(false);
                    setActivateDeleteButton(false);
                    setActivateConfirmButton(false);
                    setActivatePDFButton(false);
                    setActivateUpdateGrid(false);
                    setActivateWarehouse(false);
                    setActivateCancelButton(false);
                    setActivateApproveButton(false);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (!ID || ID === 'undefined') {
                newButton();
            }
        }
    }, [getAdjustItemWMSPayload, newButton, permissionLevel, getAlmPos, approveAdjustItemWMS]);

    async function cancelEditButton() {
        setIsDetail(false);
    };

    async function voidButton() {
        try {
            if (!window.confirm('¿Desea cancelar el Ajuste?'))
                return;

            setVisibleLoadPanel(true);

            var ID = adjustItemWMSPayload.header.ID;

            //Primero guardamos los cambios del grid Articulos
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }


            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaajustewms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: adjustItemWMSPayload,
                    action: 'void',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se canceló el Ajuste');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function saveButton() {
        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance();
            var isValid = formDatosGenerales.validate();

            if (!isValid.isValid) {
                setVisibleToast(true);
                setMessageToast(isValid.brokenRules[0].message);
                setTypeToast('error');
                return;
            }
        }

        try {
            setVisibleLoadPanel(true);

            var ID = adjustItemWMSPayload.header.ID;

            //Primero guardamos los cambios del grid Articulos
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaajustewms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: adjustItemWMSPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                if (!ID) {
                    setVisibleToast(true);
                    setMessageToast('Se creó el Ajuste con éxito');
                    setTypeToast('success')

                    ID = payloadPost.newID;

                    editButton(ID);

                    history.push('/AdjustItemWMS/' + ID.toString());
                }
                else {
                    setVisibleToast(true);
                    setMessageToast('Se actualizó el Ajuste con éxito');
                    setTypeToast('success')
                }
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    const confirmButton = React.useCallback(async () => {
        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance();
            var isValid = formDatosGenerales.validate();

            if (!isValid.isValid) {
                setVisibleToast(true);
                setMessageToast(isValid.brokenRules[0].message);
                setTypeToast('error');
                return;
            }
        }

        try {
            setVisibleLoadPanel(true);

            var ID = adjustItemWMSPayload.header.ID;

            //Primero guardamos los cambios del grid Articulos
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'procesaAjusteWMS-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'procesaAjusteWMS-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'procesaAjusteWMS-prodleona';
            else
                lambdaFunction = 'procesaAjusteWMS-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            ID: ID,
                            payload: adjustItemWMSPayload,
                            action: 'apply',
                            username: props.username,
                            usuario: props.usuario,
                            sucursal: props.sucursal,
                            s3Bucket: Storage._config.AWSS3.bucket
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);

                            setVisibleToast(true);
                            setMessageToast(err.message);
                            setTypeToast('error');
                        }
                        else {
                            var payloadPost = JSON.parse(JSON.parse(data.Payload).body);

                            if (err) {
                                setVisibleLoadPanel(false);

                                setVisibleToast(true);
                                setMessageToast(err.message);
                                setTypeToast('error');
                            }
                            else {
                                var payloadPost = JSON.parse(JSON.parse(data.Payload).body);

                                if (payloadPost.ok === 'true') {
                                    setVisibleToast(true);
                                    setMessageToast('Se afectó el Ajuste con éxito');
                                    setTypeToast('success')
                                }
                                else if (payloadPost.ok === 'false') {
                                    setVisibleToast(true);
                                    setMessageToast(payloadPost.message);
                                    setTypeToast('error')
                                }

                                editButton(ID);

                                setVisibleLoadPanel(false);
                            }
                        }
                    });
                })
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [adjustItemWMSPayload, editButton, formDatosGeneralesRef, history, props.sucursal, props.username, props.usuario, gridItemsRef, setVisibleLoadPanel, setVisibleToast, setMessageToast, setTypeToast]);

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance();

            gridTablero.refresh();
        }
    }

    async function deleteButton() {
        try {
            if (!window.confirm('¿Desea eliminar el Ajuste?'))
                return;

            setVisibleLoadPanel(true);

            var ID = adjustItemWMSPayload.header.ID;

            if (!ID) {
                //Vamos al tablero de control
                history.push('/AdjustItemWMS');
                return;
            }

            let apiName = 'AdminSC';
            let path = '/procesaajustewms';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: adjustItemWMSPayload,
                    action: 'delete',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Ajuste eliminado con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Vamos al tablero de control
                history.push('/AdjustItemWMS');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    const approveButton = React.useCallback(async () => {
        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance();
            var isValid = formDatosGenerales.validate();

            if (!isValid.isValid) {
                setVisibleToast(true);
                setMessageToast(isValid.brokenRules[0].message);
                setTypeToast('error');
                return;
            }
        }

        try {
            setVisibleLoadPanel(true);

            var ID = adjustItemWMSPayload.header.ID;

            //Primero guardamos los cambios del grid Articulos
            if (gridItemsRef.current) {
                var gridItems = gridItemsRef.current.instance();

                gridItems.saveEditData();
            }

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'procesaAjusteWMS-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'procesaAjusteWMS-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'procesaAjusteWMS-prodleona';
            else
                lambdaFunction = 'procesaAjusteWMS-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            ID: ID,
                            payload: adjustItemWMSPayload,
                            action: 'approve',
                            username: props.username,
                            usuario: props.usuario,
                            sucursal: props.sucursal,
                            s3Bucket: Storage._config.AWSS3.bucket
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);

                            setVisibleToast(true);
                            setMessageToast(err.message);
                            setTypeToast('error');
                        }
                        else {
                            var payloadPost = JSON.parse(JSON.parse(data.Payload).body);

                            if (err) {
                                setVisibleLoadPanel(false);

                                setVisibleToast(true);
                                setMessageToast(err.message);
                                setTypeToast('error');
                            }
                            else {
                                var payloadPost = JSON.parse(JSON.parse(data.Payload).body);

                                if (payloadPost.ok === 'true') {
                                    setVisibleToast(true);
                                    setMessageToast('Se afectó el Ajuste con éxito');
                                    setTypeToast('success')
                                }
                                else if (payloadPost.ok === 'false') {
                                    setVisibleToast(true);
                                    setMessageToast(payloadPost.message);
                                    setTypeToast('error')
                                }

                                editButton(ID);

                                setVisibleLoadPanel(false);
                            }
                        }
                    });
                })
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [adjustItemWMSPayload, editButton, formDatosGeneralesRef, history, props.sucursal, props.username, props.usuario, gridItemsRef, setVisibleLoadPanel, setVisibleToast, setMessageToast, setTypeToast]);

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=adjustitemwms&id=" + id, "_blank");
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/AdjustItemWMS') {
                history.push('/AdjustItemWMS');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            await getAlmacenes();

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/AdjustItemWMS/', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
        }
    }, [editButton, newButton, props.location.pathname, props.location.search, props.match.isExact, props, history]);

    function onHidingLoadPanel() {
        setVisibleLoadPanel(false);
    };

    function onHidingToast() {
        setVisibleToast(false);
    };

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    const setCellValueArticulo = React.useCallback((rowData, value) => {
        if (value) {
            rowData.Articulo = value.value;
            rowData.ArtDescripcion = value.name;
            rowData.Cantidad = 1;
        }
        else {
            rowData.Articulo = undefined;
            rowData.ArtDescripcion = undefined;
            rowData.Cantidad = undefined;
        }
    }, []);

    const onEditorArticulosPreparing = React.useCallback((e) => {
        if (e.parentType === "dataRow") {
            if (e.dataField === "Articulo") {

                e.editorOptions.displayExpr = "name";
                e.editorOptions.valueExpr = "value";
                e.editorOptions.searchEnabled = true;
                e.editorOptions.showClearButton = true;
                e.editorOptions.acceptCustomValue = false;
                e.editorOptions.clearButtonText = 'Cerrar';
                e.editorOptions.noDataText = 'No se encontraron resultados';

                const defaultValueChangeHandler = e.editorOptions.onValueChanged;
                // var ListaPreciosEsp = estimatePayload.header.ListaPreciosEsp;                

                e.editorOptions.onValueChanged = function (ev) {
                    let selectedItem = ev.component.option("selectedItem");
                    e.setValue(selectedItem);
                };
            }
            if (e.dataField === "Cantidad") {
                e.editorOptions.min = 1;
            }
            if (e.dataField === "Precio") {
                e.editorOptions.min = 0.0;
            }
        }
    }, []);

    const onFieldDatosGeneralesChanged = React.useCallback(async (e) => {
        var dataField = e.dataField;
        var value = e.value;

        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance();

            if (e.dataField === 'Almacen') {
                if (e.value) {
                    await getAlmPos(e.value);
                }
            }

            if (e.dataField === 'Submensaje') {
                if (e.value) {
                    setSubmensaje(e.value);
                }
            }
        }
    }, [getAlmPos, formDatosGeneralesRef]);

    const onEditingStartArticulos = React.useCallback((e) => {
        var Almacen = adjustItemWMSPayload.header.Almacen;

        if (!Almacen) {
            setVisibleToast(true);
            setMessageToast('Falta especificar el Almacén')
            setTypeToast('error');
            e.cancel = true;
        }
    }, [adjustItemWMSPayload.header.Almacen]);

    const onRowInsertedArticulos = React.useCallback((e) => {
        if (gridItemsRef.current) {
            var gridItems = gridItemsRef.current.instance();

            if (gridItems.totalCount() > 0) {
                setActivateWarehouse(false);
            }
            else {
                setActivateWarehouse(true);
            }
        }

    }, [gridItemsRef]);

    const onRowRemovedArticulos = React.useCallback((e) => {
        if (gridItemsRef.current) {
            var gridItems = gridItemsRef.current.instance();

            if (gridItems.totalCount() > 0) {
                setActivateWarehouse(false);
            }
            else {
                setActivateWarehouse(true);
            }
        }
    }, [gridItemsRef]);

    const setCellValueBarcode = React.useCallback(async (rowData, value, currentRowData) => {
        if (submensaje !== 'Agregar') {
            if (value && currentRowData.Posicion) {
                var info = await getAlmPosInfo(adjustItemWMSPayload.header.Almacen, currentRowData.Posicion, value);

                if (info.length > 0) {
                    rowData.Barcode = value;
                    rowData.Articulo = info[0].Articulo;
                    rowData.ArtDescripcion = info[0].ArtDescripcion;
                    rowData.Cantidad = info[0].Cantidad;
                    rowData.CantidadOriginal = info[0].Cantidad;
                }
                else {
                    setVisibleToast(true);
                    setMessageToast('Barcode no encontrado');
                    setTypeToast('error');

                    rowData.Barcode = '';
                    rowData.Articulo = '';
                    rowData.ArtDescripcion = '';
                    rowData.Cantidad = '';
                    rowData.CantidadOriginal = '';
                }
            }
        }
        else {
            rowData.Barcode = value;
        }
    }, [adjustItemWMSPayload, getAlmPosInfo, submensaje]);

    const setCellValuePosicion = React.useCallback((rowData, value, currentRowData) => {
        if (submensaje !== 'Agregar') {
            rowData.Barcode = '';
            rowData.Articulo = '';
            rowData.ArtDescripcion = '';
            rowData.Cantidad = '';
            rowData.CantidadOriginal = '';
        }

        rowData.Posicion = value;
    }, [submensaje]);

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Ajustes</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faPenToSquare} size="xl" /> {(adjustItemWMSPayload.header ? adjustItemWMSPayload.header.Movimiento : 'Ajuste')}</h1>
                        <TransactionStatus {...props} Estatus={adjustItemWMSPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">

                        {permissionLevel === 'Editar' ?
                            (
                                <div>
                                    <Link to="/AdjustItemWMS/undefined">
                                        <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nuevo Ajuste</button>
                                    </Link>

                                    <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            ) :
                            (
                                <div>
                                    <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            )}

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>

                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerMoveItemWMS" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editAdjustItemWMS} />
                            <Column dataField="Submensaje" dataType="string" width={110} caption="Tipo Ajuste" />
                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column dataField="Almacen" caption="Almacén" dataType="number" width={150} />

                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} visible={false} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />

                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />

                            <Column dataField="NombreAlmacen" caption="Nombre Almacén" dataType="string" width={190} visible={false} />

                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />

                            <Column dataField="RequiereAutorizacion" dataType="boolean" caption="Requiere Autorización" width={200} visible={false} />
                            <Column dataField="Autorizado" dataType="boolean" width={150} visible={false} />
                            <Column dataField="Autoriza" caption="Autorizado Por" dataType="string" width={200} visible={false} />
                            <Column dataField="FechaAutorizacion" caption="Fecha Autorización" dataType="datetime" width={200} visible={false} />
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/AdjustItemWMS/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/AdjustItemWMS">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateConfirmButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faPlay} /> Afectar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateApproveButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={approveButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faThumbsUp} /> Autorizar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDeleteButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateCancelButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={voidButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faWindowClose} /> Cancelar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={adjustItemWMSPayload.header}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                ref={formDatosGeneralesRef}
                                                onFieldDataChanged={onFieldDatosGeneralesChanged}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={6}>
                                                        <GroupItem caption="General" colSpan={6} colCount={2}>
                                                            <Item cssClass="boldText" dataField="Movimiento" editorOptions={movimientoEditorOptions} >
                                                                <RequiredRule message="Movimiento es requerido" />
                                                            </Item>
                                                            <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                <Label text={"Fecha Emisión"} />
                                                                <RequiredRule message="Fecha Emisión es requerida" />
                                                            </Item>
                                                            <Item cssClass="boldText" dataField="Submensaje" editorType="dxSelectBox" editorOptions={submensajeEditorOptions} >
                                                                <Label text={"Tipo de Ajuste"} />
                                                                <RequiredRule message="Tipo de Ajuste es requerido" />
                                                            </Item>
                                                            <Item dataField="Almacen" editorType="dxSelectBox" editorOptions={almacenEditorOptions}>
                                                                <Label text={"Almacén"} />
                                                                <RequiredRule message="Almacén es requerido" />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Artículos" colSpan={6} >
                                                            <DataGrid
                                                                id="gridItems"
                                                                ref={gridItemsRef}
                                                                columnsAutoWidth={true}
                                                                dataSource={adjustItemWMSPayload.detail}
                                                                showBorders={true}
                                                                showColumnLines={true}
                                                                showRowLines={true}
                                                                rowAlternationEnabled={true}
                                                                allowColumnResizing={true}
                                                                columnResizingMode="widget"
                                                                allowColumnReordering={false}
                                                                repaintChangesOnly={true}
                                                                onEditorPreparing={onEditorArticulosPreparing}
                                                                onEditingStart={onEditingStartArticulos}
                                                                onRowInserted={onRowInsertedArticulos}
                                                                onRowRemoved={onRowRemovedArticulos}
                                                            >
                                                                <Scrolling
                                                                    mode='virtual'
                                                                />

                                                                <Editing
                                                                    mode="cell"
                                                                    newRowPosition="last"
                                                                    allowUpdating={activateUpdateGrid}
                                                                    allowAdding={activateUpdateGrid}
                                                                    allowDeleting={activateUpdateGrid}
                                                                />

                                                                <Sorting mode="none" />

                                                                <Column dataField="RenglonID" width={190} visible={false} allowEditing={false} />
                                                                <Column dataField="Posicion" caption="Posición" width={110} calculateDisplayValue="Posicion" setCellValue={setCellValuePosicion} >
                                                                    <Lookup
                                                                        dataSource={posiciones}
                                                                        displayExpr="name"
                                                                        valueExpr="value"
                                                                    />
                                                                    <RequiredRule message="Posición es requerida" />
                                                                </Column>
                                                                <Column dataField="Barcode" caption="Barcode" width={130} dataType="number" editorOptions={barcodeNuevoEditorOptions} setCellValue={setCellValueBarcode}>
                                                                    <RequiredRule message="Barcode es requerido" />
                                                                </Column>
                                                                <Column dataField="Articulo" caption="Artículo" width={400} setCellValue={setCellValueArticulo} calculateDisplayValue="ArtDescripcion" allowEditing={submensaje === 'Agregar'}>
                                                                    <Lookup
                                                                        dataSource={storeArticulo}
                                                                        displayExpr="name"
                                                                        valueExpr="value"
                                                                        searchEnabled={true}
                                                                        showClearButton={true}
                                                                        acceptCustomValue={false}
                                                                        clearButtonText='Cerrar'
                                                                        noDataText='No se encontraron resultados'
                                                                    />
                                                                    {submensaje === 'Agregar' && <RequiredRule message="Artículo es requerido" />}
                                                                </Column>
                                                                <Column dataField="ArtDescripcion" width={400} allowEditing={false} visible={false} />
                                                                <Column dataField="Cantidad" caption="Cantidad" width={80} dataType="number" allowEditing={submensaje === 'Agregar' || submensaje === 'Ajustar'} format=',###'>
                                                                    {(submensaje === 'Agregar' || submensaje === 'Ajustar') && <RequiredRule message="Cantidad es requerida" />}
                                                                </Column>
                                                                <Column dataField="CantidadOriginal" dataType="number" width={120} caption="Cant. Original" format=',###' allowEditing={false} />
                                                            </DataGrid>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Autorización" colCount={2}>
                                                        <Item dataField="RequiereAutorizacion" editorType="dxCheckBox" editorOptions={autorizacionEditorOptions} >
                                                            <Label text="Requiere autorización" />
                                                        </Item>
                                                        <Item dataField="Autorizado" editorType="dxCheckBox" editorOptions={autorizacionEditorOptions} >
                                                            <Label text="Autorizado" />
                                                        </Item>
                                                        <Item dataField="Autoriza" editorOptions={autorizacionEditorOptions} >
                                                            <Label text="Autorizado por" />
                                                        </Item>
                                                        <Item dataField="FechaAutorizacion" editorOptions={autorizacionEditorOptions} >
                                                            <Label text="Fecha Autorización" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="Observaciones" editorType="dxTextArea" >
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Registros Relacionados">
                                                        <DataGrid
                                                            id="gridRelatedRecords"
                                                            columnsAutoWidth={true}
                                                            dataSource={adjustItemWMSPayload.relatedrecords}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />

                                                            <Column dataField="name" dataType="string" width={300} caption="Transacción" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="FechaEmision" dataType="date" width={150} caption="Fecha Emisión" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Mensajes WMS">
                                                        <DataGrid
                                                            id="gridMensajesWMS"
                                                            columnsAutoWidth={true}
                                                            dataSource={adjustItemWMSPayload.wmsmessage}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                                                            <Paging defaultPageSize={10} />
                                                            <Pager
                                                                visible={true}
                                                                allowedPageSizes={allowedPageSizes}
                                                                displayMode='compact'
                                                                showPageSizeSelector={true}
                                                                showInfo={true}
                                                                showNavigationButtons={true}
                                                            />

                                                            <FilterRow visible={true} />
                                                            <FilterPanel visible={true} />
                                                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                                            <HeaderFilter visible={true} />

                                                            <GroupPanel visible={true} />
                                                            <SearchPanel visible={true} />
                                                            <ColumnChooser
                                                                enabled={true}
                                                                mode='select'
                                                            >
                                                                <ColumnChooserSearch
                                                                    enabled={true}
                                                                />

                                                                <ColumnChooserSelection
                                                                    allowSelectAll={true}
                                                                    selectByClick={true}
                                                                    recursive={true}
                                                                />
                                                            </ColumnChooser>
                                                            <Grouping autoExpandAll={autoExpandAll} />
                                                            
                                                            <Column dataField="Mensaje" dataType="string" width={120} caption="Mensaje" />
                                                            <Column dataField="Submensaje" dataType="string" width={120} caption="Tipo Ajuste" />
                                                            <Column dataField="Barcode" dataType="string" width={140} caption="Barcode" />
                                                            <Column dataField="Articulo" dataType="string" width={120} caption="Artículo" />
                                                            <Column dataField="CantidadOriginal" dataType="number" width={150} caption="Cantidad Original" format=',###' />
                                                            <Column dataField="Cantidad" dataType="number" width={120} caption="Cantidad" format=',###' />
                                                            <Column dataField="username" dataType="string" width={140} caption="Usuario WMS" />
                                                            <Column dataField="Fecha" dataType="date" width={100} caption="Fecha" />
                                                            <Column dataField="Almacen" dataType="string" width={120} caption="Almacén" />
                                                            <Column dataField="Posicion" dataType="string" width={120} caption="Posición" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={adjustItemWMSPayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
