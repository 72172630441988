import React, { useState, useEffect, useRef } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
  Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
  GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
  Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
  GroupItem, Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

import SelectBox from 'devextreme-react/select-box';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faPrint } from "@fortawesome/pro-solid-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import Lambda from 'aws-sdk/clients/lambda';

import '../ListStyle.css';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-dd"
  }
});

dxNumberBox.defaultOptions({
  options: {
    step: 0
  }
});

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

export default function ProcessInvoice(props) {
  const history = useHistory();

  //Controles para expander los grupos del grid de checklist
  const [autoExpandAllChecklist, setAutoExpandAllChecklist] = useState(true);

  const [autoExpandAll, setAutoExpandAll] = useState(true);

  //Ref para el grid Facturas
  const [gridFacturasRef, setGridFacturasRef] = useState(React.createRef());

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');

  const allowedPageSizes = [5, 10, 15, 20, 'all'];

  const exportFormats = ['xlsx'];

  function getOrderDay(rowData) {
    return (new Date(rowData.OrderDate)).getDay();
  }

  const filterBuilder = {
    customOperations: [{
      name: 'weekends',
      caption: 'Weekends',
      dataTypes: ['date'],
      icon: 'check',
      hasValue: false,
      calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
    }],
    allowHierarchicalFields: true,
  };

  const filterValue = [['Estatus', '=', 'Sin Afectar']];

  async function getInvoiceRecords(sqlFilter) {
    try {
      let apiName = 'AdminSC';
      let path = '/procesafacturaslote';

      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
          action: 'list',
          sqlFilter: sqlFilter,
          nivel: 'ProcesarFacturaSucursal',
          usuario: props.usuario,
          username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
        }
      }

      var invoicesGet = await API.get(apiName, path, myInit);
    }
    catch (e) {
      if (e === 'No current user') {
        history.push('/');

        window.location.reload();
      }
    }

    return invoicesGet;
  }

  const store = new CustomStore({
    key: 'ID',
    load(loadOptions) {
      let sqlFilter = '(';
      [
        'filter'
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          var filters = loadOptions[i];

          var filtersLength = filters.length;

          for (var i = 0; i <= filtersLength; i++) {
            var singleFilter = filters[i];
            var singleFilterType = typeof singleFilter;
            var singleFilterIsArray = Array.isArray(singleFilter);
            var singleFilterIsDate = singleFilter instanceof Date;

            if (singleFilter === 'NULL') {
              singleFilter = '0';
            }

            if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
              sqlFilter += '(';

              var subFiltersLength = singleFilter.length;

              for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                var singleSubFilter = singleFilter[i2];
                var singleSubFilterType = typeof singleSubFilter;
                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                var singleSubFilterIsDate = singleSubFilter instanceof Date

                if (singleSubFilter === 'NULL') {
                  singleSubFilter = '0';
                }

                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                  sqlFilter += '(';

                  var subSubFiltersLength = singleSubFilter.length;

                  for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                    var singleSubSubFilter = singleSubFilter[i3];
                    var singleSubSubFilterType = typeof singleSubSubFilter;
                    var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                    var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                    if (singleSubSubFilter === 'NULL') {
                      singleSubSubFilter = '0';
                    }

                    if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                      sqlFilter += '(';

                      var subSubSubFiltersLength = singleSubSubFilter.length;

                      for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                        var singleSubSubSubFilter = singleSubSubFilter[i4];
                        var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                        var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                        var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                        if (singleSubSubSubFilter === 'NULL') {
                          singleSubSubSubFilter = '0';
                        }

                        if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                          sqlFilter += '(';

                          var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                          for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                            var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                            var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                            var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                            var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                            if (singleSubSubSubSubFilter === 'NULL') {
                              singleSubSubSubSubFilter = '0';
                            }

                            if (singleSubSubSubSubFilterType.toString() === 'string') {
                              if (singleSubSubSubSubFilter === 'contains') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'notcontains') {
                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                singleSubSubSubSubFilter = 'NOT LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'startswith') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                              }
                              else if (singleSubSubSubSubFilter === 'endswith') {
                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                singleSubSubSubSubFilter = 'LIKE';

                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                              }
                              else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                if (!filters[i][i2][i3][i4][i5 + 1])
                                  filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                  filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                }
                              }
                              else if (singleSubSubSubSubFilter === '!') {
                                filters[i][i2][i3][i4][i5] = 'NOT';
                                singleSubSubSubSubFilter = 'NOT';
                              }

                              sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                              sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'number') {
                              sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                            }
                            else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                              if (singleSubSubSubSubFilter.toString() === 'true')
                                sqlFilter += '1 ';
                              else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                sqlFilter += '0 ';
                            }
                          }
                        }
                        else if (singleSubSubSubFilterType.toString() === 'string') {
                          if (singleSubSubSubFilter === 'contains') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'notcontains') {
                            filters[i][i2][i3][i4] = 'NOT LIKE';
                            singleSubSubSubFilter = 'NOT LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'startswith') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                          }
                          else if (singleSubSubSubFilter === 'endswith') {
                            filters[i][i2][i3][i4] = 'LIKE';
                            singleSubSubSubFilter = 'LIKE';

                            filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                          }
                          else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                            if (!filters[i][i2][i3][i4 + 1])
                              filters[i][i2][i3][i4 + 1] = 'NULL';
                            else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                              filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                            }
                          }
                          else if (singleSubSubSubFilter === '!') {
                            filters[i][i2][i3][i4] = 'NOT';
                            singleSubSubSubFilter = 'NOT';
                          }

                          sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                          sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'number') {
                          sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                        }
                        else if (singleSubSubSubFilterType.toString() === 'boolean') {
                          if (singleSubSubSubFilter.toString() === 'true')
                            sqlFilter += '1 ';
                          else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                            sqlFilter += '0 ';
                        }
                      }

                      sqlFilter += ')';
                    }
                    else if (singleSubSubFilterType.toString() === 'string') {
                      if (singleSubSubFilter === 'contains') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'notcontains') {
                        filters[i][i2][i3] = 'NOT LIKE';
                        singleSubSubFilter = 'NOT LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'startswith') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                      }
                      else if (singleSubSubFilter === 'endswith') {
                        filters[i][i2][i3] = 'LIKE';
                        singleSubSubFilter = 'LIKE';

                        filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                      }
                      else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                        if (!filters[i][i2][i3 + 1])
                          filters[i][i2][i3 + 1] = 'NULL';
                        else if (typeof filters[i][i2][i3 + 1] === 'string') {
                          filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                        }
                      }
                      else if (singleSubSubFilter === '!') {
                        filters[i][i2][i3] = 'NOT';
                        singleSubSubFilter = 'NOT';
                      }
                      sqlFilter += ' ' + singleSubSubFilter + ' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                      sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'number') {
                      sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                    }
                    else if (singleSubSubFilterType.toString() === 'boolean') {
                      if (singleSubSubFilter.toString() === 'true')
                        sqlFilter += '1 ';
                      else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                        sqlFilter += '0 ';
                    }
                  }

                  sqlFilter += ')';
                }
                else if (singleSubFilterType.toString() === 'string') {
                  if (singleSubFilter === 'contains') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'notcontains') {
                    filters[i][i2] = 'NOT LIKE';
                    singleSubFilter = 'NOT LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'startswith') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                  }
                  else if (singleSubFilter === 'endswith') {
                    filters[i][i2] = 'LIKE';
                    singleSubFilter = 'LIKE';

                    filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                  }
                  else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                    if (!filters[i][i2 + 1])
                      filters[i][i2 + 1] = 'NULL';
                    else if (typeof filters[i][i2 + 1] === 'string') {
                      filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                    }
                  }
                  else if (singleSubFilter === '!') {
                    filters[i][i2] = 'NOT';
                    singleSubFilter = 'NOT';
                  }
                  sqlFilter += ' ' + singleSubFilter + ' ';
                }
                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                  sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                }
                else if (singleSubFilterType.toString() === 'number') {
                  sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                }
                else if (singleSubFilterType.toString() === 'boolean') {
                  if (singleSubFilter.toString() === 'true')
                    sqlFilter += '1 ';
                  else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                    sqlFilter += '0 ';
                }
              }

              sqlFilter += ')';
            }
            else if (singleFilterType.toString() === 'string') {
              if (singleFilter === 'contains') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'notcontains') {
                filters[i] = 'NOT LIKE';
                singleFilter = 'NOT LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'startswith') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
              }
              else if (singleFilter === 'endswith') {
                filters[i] = 'LIKE';
                singleFilter = 'LIKE';

                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
              }
              else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                if (!filters[i + 1])
                  filters[i + 1] = 'NULL';
                else if (typeof filters[i + 1] === 'string') {
                  filters[i + 1] = '\'' + filters[i + 1] + '\'';
                }
              }
              else if (singleFilter === '!') {
                filters[i] = 'NOT';
                singleFilter = 'NOT';
              }
              sqlFilter += ' ' + singleFilter + ' ';
            }
            else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
              sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
            }
            else if (singleFilterType.toString() === 'number') {
              sqlFilter += ' ' + singleFilter.toString() + ' ';
            }
            else if (singleFilterType.toString() === 'boolean') {
              if (singleFilter.toString() === 'true')
                sqlFilter += '1 ';
              else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                sqlFilter += '0 ';
            }
          }

          sqlFilter += ')';
        }
      });

      async function getRecords() {
        if (sqlFilter === '(')
          sqlFilter = '';

        var records = await getInvoiceRecords(sqlFilter);
        return {
          data: records,
          totalCount: records.length
        };
      }

      return getRecords()
        .then(function (result) {
          return result;
        })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });

  function onAutoExpandAllChanged() {
    setAutoExpandAll(!autoExpandAll)
  };

  function onAutoExpandAllChecklistChanged() {
    setAutoExpandAllChecklist(!autoExpandAllChecklist)
  };

  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
  }

  function onOptionChanged(e) {
    if (e.name !== 'focusedRowIndex' && e.name !== 'focusedColumnIndex' && e.name !== 'selectedRowKeys') {
      e.component.on("contentReady", () => {
        e.component.off("contentReady");
        e.component.clearSelection();
      });
    }
  }

  function estatusInvoice(data) {
    return (
      <span className={data.data.Estatus === "Concluido" ? "px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm" : "px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"}> {data.data.Estatus} </span>
    )
  }

  async function processButton() {
    if (!props.usuario) {
      setVisibleToast(true);
      setMessageToast('Configuración de usuario incorrecta. Comunicarse con el área de sistemas');
      setTypeToast('error')
      return;
    }

    if (gridFacturasRef.current) {
      var gridFacturas = gridFacturasRef.current.instance();

      var keys = gridFacturas.getSelectedRowKeys();

      var arrayInvoice = [];

      for (var keysIndex in keys) {
        arrayInvoice.push({
          ID: keys[keysIndex]
        });
      }

      if (arrayInvoice.length === 0) {
        setVisibleToast(true);
        setMessageToast('Falta seleccionar las facturas a Afectar');
        setTypeToast('error')
      }
      else {
        if (!window.confirm('Se afectarán ' + arrayInvoice.length + ' facturas. ¿Desea continuar?'))
          return;

        setVisibleLoadPanel(true);

        var lambdaFunction;

        if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
          lambdaFunction = 'procesaFacturasLote-dev';
        else if (window.location.href.indexOf('sandboxtampico.') >= 0)
          lambdaFunction = 'procesaFacturasLote-devleona';
        else if (window.location.href.indexOf('tampico.') >= 0)
          lambdaFunction = 'procesaFacturasLote-prodleona';
        else
          lambdaFunction = 'procesaFacturasLote-prod';

        Auth.currentCredentials()
          .then(credentials => {
            const lambda = new Lambda({
              credentials: Auth.essentialCredentials(credentials),
              region: 'us-east-1'
            });

            var params = {
              FunctionName: lambdaFunction,
              Payload: JSON.stringify({
                action: 'process',
                usuario: props.usuario,
                invoices: arrayInvoice
              })
            };

            lambda.invoke(params, function (err, data) {
              if (err) {
                setVisibleLoadPanel(false);

                console.log(err, err.stack);
              }
              else {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Proceso concluido');
                setTypeToast('success');

                gridFacturas.refresh();
              }
            });
          })

      }
    }
  };

  function printButton() {
    if (!props.usuario) {
      setVisibleToast(true);
      setMessageToast('Configuración de usuario incorrecta. Comunicarse con el área de sistemas');
      setTypeToast('error')

      return;
    }

    if (gridFacturasRef.current) {
      var gridFacturas = gridFacturasRef.current.instance();

      var keys = gridFacturas.getSelectedRowKeys();

      var arrayInvoice = [];

      var pdfArray = '';

      for (var keysIndex in keys) {
        arrayInvoice.push({
          ID: keys[keysIndex]
        });

        pdfArray += keys[keysIndex].toString() + '%2C';
      }

      if (arrayInvoice.length === 0) {
        setVisibleToast(true);
        setMessageToast('Falta seleccionar las facturas a Imprimir');
        setTypeToast('error')
      }
      else {
        if (!window.confirm('Se imprimirán ' + arrayInvoice.length + ' facturas. ¿Desea continuar?'))
          return;

        setVisibleLoadPanel(true);

        var lambdaFunction;

        if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
          lambdaFunction = 'procesaFacturasLote-dev';
        else if (window.location.href.indexOf('sandboxtampico.') >= 0)
          lambdaFunction = 'procesaFacturasLote-devleona';
        else if (window.location.href.indexOf('tampico.') >= 0)
          lambdaFunction = 'procesaFacturasLote-prodleona';
        else
          lambdaFunction = 'procesaFacturasLote-prod';

        Auth.currentCredentials()
          .then(credentials => {
            const lambda = new Lambda({
              credentials: Auth.essentialCredentials(credentials),
              region: 'us-east-1'
            });

            var params = {
              FunctionName: lambdaFunction,
              Payload: JSON.stringify({
                action: 'printLoc',
                usuario: props.usuario,
                invoices: arrayInvoice
              })
            };

            lambda.invoke(params, function (err, data) {
              if (err) {
                setVisibleLoadPanel(false);

                console.log(err, err.stack);
              }
              else {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Proceso concluido');
                setTypeToast('success');

                gridFacturas.refresh();

                var baseUrl = window.location.href.split('/')[0];

                window.open(baseUrl + "/#/PdfRender?type=invoice&id=" + pdfArray, "_blank");
              }
            });
          })
      }
    }
  };

  function refreshButton() {
    if (gridFacturasRef.current) {
      var gridFacturas = gridFacturasRef.current.instance();

      gridFacturas.refresh();
    }
  }

  useEffect(() => {
    // eslint-disable-next-line

  }, []);

  function onHidingToast() {
    setVisibleToast(false);
  }

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      <div class="bg-gray-200 m-2">
        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Procesar Facturas - Sucursal</h1>
      </div>

      <div class="bg-gray-200 m-2">
        {props.afectarFacturas.toString() === 'true' ?
          (
            <button onClick={processButton} type="submit" class="mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faPlay} /> Afectar
            </button>
          ) :
          (
            <div></div>
          )
        }

        {props.afectarFacturas.toString() === 'true' ?
          (
            <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faPrint} /> Imprimir
            </button>
          ) :
          (
            <button onClick={printButton} type="submit" class="mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faPrint} /> Imprimir
            </button>
          )
        }

        <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
        </button>

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={visibleLoadPanel}
          showIndicator={true}
          message="Por favor espere..."
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />

        <Toast
          visible={visibleToast}
          message={messageToast}
          type={typeToast}
          onHiding={onHidingToast}
          displayTime={5000}
          width={'auto'}
          position={{
            my: 'top center',
            at: 'top center',
            of: window,
            offset: '0 130'
          }}
        />

        <div class="bg-white p-3 shadow-xl">
          <div className="options mb-2">
            <div className="option">
              <CheckBox text="Expandir todos los grupos"
                value={autoExpandAll}
                onValueChanged={onAutoExpandAllChanged}>
              </CheckBox>
            </div>
          </div>
          <DataGrid
            id="gridInvoices"
            columnsAutoWidth={true}
            filterBuilder={filterBuilder}
            dataSource={store}
            defaultFilterValue={filterValue}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            showBorders={true}
            rowAlternationEnabled={true}
            showRowLines={true}
            showColumnLines={true}
            remoteOperations={{ filtering: true }}
            onSelectionChanged={onSelectionChanged}
            onOptionChanged={onOptionChanged}
            ref={gridFacturasRef}
            onExporting={onExporting}
          >
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <Paging defaultPageSize={0} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode='compact'
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />

            <HeaderFilter visible={true} />

            <GroupPanel visible={true} />
            <SearchPanel visible={true} />
            <ColumnChooser
              enabled={true}
              mode='select'
            >
              <ColumnChooserSearch
                enabled={true}
              />

              <ColumnChooserSelection
                allowSelectAll={true}
                selectByClick={true}
                recursive={true}
              />
            </ColumnChooser>
            <Grouping autoExpandAll={autoExpandAll} />
            <StateStoring enabled={true} type="localStorage" storageKey="gridProcesarFacturasSucursal" />
            <Export enabled={true} />

            <Selection
              mode="multiple"
              selectAllMode={'page'}
              showCheckBoxesMode={'always'}
            />

            <Column dataField="ID" dataType="number" width={120} caption="ID" visible={false} />
            <Column dataField="Mov" dataType="string" width={110} caption="Movimiento" />
            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
            <Column caption="Estatus Transacción" alignment="center" width={120} cellRender={estatusInvoice} visible={false} />
            <Column dataField="Estatus" caption="Estatus" dataType="string" width={120} />
            <Column dataField="NombreSucursal" caption="Nombre Sucursal" dataType="string" width={250} />

            <Column dataField="Pedido" dataType="string" width={120} />
            <Column dataField="TipoPedido" caption="Tipo de Pedido" dataType="string" width={130} />
            <Column dataField="Importe" caption="Importe" dataType="number" width={150} visible={false}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="Impuestos" caption="Impuestos" dataType="number" width={150} visible={false}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="ImporteTotal" caption="Importe Total" dataType="number" width={150}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>
            <Column dataField="Cliente" caption="Cliente" dataType="string" width={150} />
            <Column dataField="NombreCliente" caption="Nombre Cliente" dataType="string" width={250} />

            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
            <Column dataField="Plaza" dataType="string" width={250} visible={false} />

            <Column dataField="IDPedido" caption="ID Pedido" dataType="number" width={150} visible={false} />
            <Column dataField="Anticipos" dataType="number" width={150} visible={false}>
              <Format
                type="currency"
                precision={2}
              />
            </Column>

            <Column dataField="EstatusProg" caption="Estatus Programación" dataType="string" width={150} />
            <Column dataField="TipoProgramacion" caption="Tipo Programación" dataType="string" width={150} />
            <Column dataField="FechaVenta" caption="Fecha de Venta" dataType="date" width={150} visible={false} />
            <Column dataField="FechaProgramada" caption="Fecha Programada" dataType="date" width={150} visible={false} />
            <Column dataField="Comentarios" dataType="string" width={150} visible={false} />
            <Column dataField="PorcEntrega" caption="% Entrega" dataType="number" width={150} >
              <Format
                type="percent"
              />
            </Column>

            <Column dataField="SituacionFacturacionSucursal" caption="Situación Facturación" width={150} visible={false} />
            <Column dataField="FechaImpresionSucursal" caption="Fecha Impresión" dataType="datetime" width={150} visible={false} />
            <Column dataField="UsuarioImpresion" caption="Usuario Impresión" width={150} visible={false} />
            <Column dataField="PlazaEntrega" dataType="string" width={250} caption="Plaza Entrega" visible={false} />

            <Summary>
              <GroupItem
                column="Importe"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Total {0}"
                showInGroupFooter={false}>
              </GroupItem>
              <TotalItem
                column="Importe"
                summaryType="sum"
                valueFormat="$,##0.##"
                displayFormat="Total {0}"
                showInColumn="Importe">
              </TotalItem>
            </Summary>
          </DataGrid>
        </div>
      </div>
    </div>
  );
}