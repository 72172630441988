import React, { useState, useEffect, useRef } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faUserPlus } from "@fortawesome/pro-duotone-svg-icons";


import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

//Form
import Form, { GroupItem, Label, Item } from 'devextreme-react/form';

//Validator
import { EmailRule, CustomRule, RequiredRule } from 'devextreme-react/validator';

export default function Register() {
  const [signUpPayload, setSignUpPayload] = useState({
    emailaddress: '',
    username: '',
    password: ''
  });

  const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

  const [visibleToast, setVisibleToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  const [typeToast, setTypeToast] = useState('success');
  const [ok, setOk] = useState(false);

  //Ref para el form
  const [formSignUpRef, setFormSignUpRef] = useState(React.createRef());

  var passwordEditorOptions = { width: '40%', disabled: false, mode: 'password' };
  var userNameEditorOptions = { width: '40%', readOnly: false };
  var emailEditorOptions = { width: '40%', disabled: false, mode: 'email' };


  async function saveButton() {
    if (formSignUpRef.current) {
      var formSignUp = formSignUpRef.current.instance();
      var isValid = formSignUp.validate();
      var okAdmin = false;

      if (!isValid.isValid) {
        setVisibleToast(true);
        setMessageToast(isValid.brokenRules[0].message);
        setTypeToast('error');
        return;
      }
    }

    var user = await Auth.currentAuthenticatedUser();

    var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    for (var groupIndex in groups) {
      var singleGroup = groups[groupIndex];

      if (singleGroup === 'Admin') {
        okAdmin = true;
      }
    }

    if (okAdmin === false) {
      setVisibleToast(true);
      setMessageToast('Unauthorized');
      setTypeToast('error');
      return;
    }

    var email = signUpPayload.emailaddress;
    var username = signUpPayload.username;
    var password = signUpPayload.password;

    if (password.length < 8) {
      setVisibleToast(true);
      setMessageToast('La contraseña debe tener mínimo 8 caracteres');
      setTypeToast('error')
      return;
    }

    setVisibleLoadPanel(true);

    try {
      // Obtenemos el token
      let apiNameToken = 'AdminSC';
      let pathToken = '/getValidationToken';

      let myInitToken = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        queryStringParameters: {
        }
      }

      var validationToken = await API.post(apiNameToken, pathToken, myInitToken);

      await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional but not in this case as MFA/Verification code wil be emailed
        },
        validationData: {
          qs: validationToken[0].Value
        }
      });

      setVisibleToast(true);
      setMessageToast('Usuario creado con éxito');
      setTypeToast('success')

    } catch (error) {
      setVisibleToast(true);
      setMessageToast(error.message);
      setTypeToast('error')
    }

    setVisibleLoadPanel(false);
  }

  function onHidingToast() {
    setVisibleToast(false);
  }

  useEffect(() => {
    async function initialize() {
      var user = await Auth.currentAuthenticatedUser();

      var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      for (var groupIndex in groups) {
        var singleGroup = groups[groupIndex];

        if (singleGroup === 'Admin') {
          setOk(true);
        }
      }
    }

    initialize();
  });

  return (
    <div>
      {ok === true ? (
        <div>
          <div class="bg-gray-200 m-2">
            <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faUserPlus} size="xl" /> Agregar Usuario</h1>
          </div>
          <div className="bg-white p-4 shadow-xl m-2">
            <LoadPanel
              shadingColor="rgba(0,0,0,0.4)"
              visible={visibleLoadPanel}
              showIndicator={true}
              message="Por favor espere..."
              shading={true}
              showPane={true}
              hideOnOutsideClick={false}
            />

            <Toast
              visible={visibleToast}
              message={messageToast}
              type={typeToast}
              onHiding={onHidingToast}
              displayTime={5000}
              width={'auto'}
              position={{
                my: 'top center',
                at: 'top center',
                of: window,
                offset: '0 130'
              }}
            />
            <Form
              id="formSignUp"
              labelMode={'outside'}
              formData={signUpPayload}
              disabled={false}
              showColonAfterLabel={false}
              labelLocation={'top'}
              colCount={1}
              ref={formSignUpRef}
            >
              <GroupItem caption="Datos usuario">
                <Item dataField="emailaddress" editorOptions={emailEditorOptions}>
                  <Label text={"Correo Electrónico"} />
                  <EmailRule message="eMail incorrecto" />
                  <RequiredRule message="eMail es requerido" />
                </Item>
                <Item dataField="username" editorOptions={userNameEditorOptions}>
                  <Label text={"Usuario"} />
                  <RequiredRule message="Usuario es requerido" />
                </Item>
                <Item dataField="password" editorOptions={passwordEditorOptions}>
                  <Label text={"Contraseña"} />
                  <RequiredRule message="Contraseña es requerida" />
                </Item>
              </GroupItem>
            </Form>

            <button onClick={saveButton} type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <FontAwesomeIcon icon={faSave} /> Guardar Cambios
            </button>
          </div>
        </div>
      ) : (<div></div>)
      }
    </div>
  )
}