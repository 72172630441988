import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//ContextMenu
import ContextMenu from 'devextreme-react/context-menu';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faPersonCarryBox } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { faWindowClose } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function DirectReturn(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [permissionLevel, setPermissionLevel] = useState(props.nivel);
    const [voidDeliveryTransactions, setVoidDeliveryTransactions] = useState(props.voidDeliveryTransactions);
    const [approveDelivery, setApproveDelivery] = useState(props.approveDelivery);
    const [directReturnPayload, setDirectReturnPayload] = useState({
        header: {},
        detail: [],
        movEstatusLog: []
    });

    const [paqueteria, setPaqueteria] = useState([]);
    const [almacenes, setAlmacenes] = useState([]);
    const [contextMenuItems, setContextMenuItems] = useState([]);
    const [autoExpandAll, setAutoExpandAll] = useState(true);

    // setValue para el control tab
    const [value, setValue] = React.useState(0);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activateUpdateGrid, setActivateUpdateGrid] = useState(false);
    const [activateCustomSalesOrderField, setActivateCustomSalesOrderField] = useState(false);
    const [activateCustomWarehouseField, setActivateCustomWarehouseField] = useState(false);
    const [acceptCustomValues, setAcceptCustomValue] = useState(false);
    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activateCancelButton, setActivateCancelButton] = useState(false);
    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activateApproveButton, setActivateApproveButton] = useState(false);
    const [activateGetItemButton, setActivateGetItemButton] = useState(false);
    const [activateSelectItemButton, setActivateSelectItemButton] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);
    const [activateCantidadButton, setActivateCantidadButton] = useState(false);
    const [activateCantidad2Button, setActivateCantidad2Button] = useState(false);
    const [activateAttachments, setActivateAttachments] = useState(false);
    const [activatePaqueteria, setActivatePaqueteria] = useState(false);

    //Ref para el grid Articulos
    const [gridArticulosRef, setGridArticulosRef] = useState(React.createRef());

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    const [attachments, setAttachments] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Sin Afectar']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getDirectReturnRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    usuario: props.usuario,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var directReturnGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return directReturnGet;
    }

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getDirectReturnRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    var fechaEmisionEditorOptions = { width: '100%', readOnly: true };
    var movimientoEditorOptions = { width: '100%', readOnly: true };
    var datosClienteEditorOptions = { width: '100%', readOnly: true };
    var estatusEditorOptions = { width: '100%', readOnly: true };
    var autorizacionEditorOptions = { width: '100%', readOnly: true };
    var numeroGuiaEditorOptions = { width: '100%' };

    var almacenDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Almacen",
                data: almacenes
            }
        });
    }, [almacenes]);

    var paqueteriaDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Paqueteria",
                data: paqueteria
            }
        });
    }, [paqueteria]);

    const storePedido = new CustomStore({
        key: 'value',
        load(loadOptions) {
            let sqlFilter = '';
            [
                'filter'
            ].forEach((i) => {
                sqlFilter = loadOptions.searchValue;
            });

            async function getRecords() {
                var records = await getPedidos(sqlFilter);

                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        byKey: (key) => {
            return {
                name: key,
                value: key
            };
        }
    });

    //Opciones del campo Pedido
    try {
        var pedidoEditorOptions = {
            dataSource: storePedido,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            showClearButton: true,
            acceptCustomValue: !activateCustomSalesOrderField,
            value: (directReturnPayload.header.Pedido ? directReturnPayload.header.Pedido : ''),
            disabled: !activateCustomSalesOrderField,
            searchTimeout: 1500,
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        };
    }
    catch (e) {
        var pedidoEditorOptions = {
            dataSource: storePedido,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: acceptCustomValues,
            value: '',
            width: '100%'
        };
    }

    //Opciones del campo Almacen
    try {
        var almacenEditorOptions = useMemo(() => {
            return {
                dataSource: almacenDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                acceptCustomValue: !activateCustomWarehouseField,
                value: (directReturnPayload.header.Almacen ? directReturnPayload.header.Almacen : ''),
                width: '100%'
            };
        }, [activateCustomWarehouseField, almacenDataSource, directReturnPayload.header.Almacen]);
    }
    catch (e) {
        var almacenEditorOptions = {
            dataSource: almacenDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: acceptCustomValues,
            value: '',
            width: '100%'
        };
    }

    //Opciones del campo Paqueteria
    try {
        var paqueteriaEditorOptions = {
            dataSource: paqueteriaDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: !acceptCustomValues,
            value: (directReturnPayload.header.Paqueteria ? directReturnPayload.header.Paqueteria : ''),
            width: '100%'
        };
    }
    catch (e) {
        var paqueteriaEditorOptions = {
            dataSource: paqueteriaDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            acceptCustomValue: acceptCustomValues,
            value: '',
            width: '100%'
        };
    }

    async function uploadChunk(file, uploadInfo) {
        try {
            var responseS3 = await Storage.put("/prog/" + id + "/" + file.name, uploadInfo.chunkBlob, {
                contentType: file.type,
                ACL: 'public-read'
            });

            //Obtenemos el nombre del bucket
            let bucketName = Storage._config.AWSS3.bucket;


            let apiName = 'AdminSC';
            let path = '/uploadattachment';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'register',
                    bucketName: bucketName,
                    type: 'prog',
                    id: id,
                    Key: "/prog/" + id + "/" + file.name,
                    fileName: file.name,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var attachmentsResult = await API.post(apiName, path, myInit);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/prog/" + id);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (err) {
            console.log("Error uploading file:", err);
        }
    }

    async function onAttachmentDeleting(e) {
        var fileName = e.item.key;

        //Primero borramos el archivo de S3
        var attachmentsDelete = await Storage.remove("/prog/" + id + "/" + fileName);

        //Despues borramos el attachment en Intelisis
        let bucketName = Storage._config.AWSS3.bucket;

        let apiName = 'AdminSC';
        let path = '/uploadattachment';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
                action: 'delete',
                bucketName: bucketName,
                type: 'prog',
                id: id,
                Key: "/prog/" + id + "/" + fileName,
                fileName: fileName,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var attachmentsResult = await API.post(apiName, path, myInit);

        //Finalmente sincronizamos el state
        var attachmentsGet = await Storage.list("/prog/" + id);
        var attachmentsAux = [];

        for (var attachmentsGetIndex in attachmentsGet) {
            var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

            var singleAttachment = {
                name: singleAttachmentsGet.key.split('/').pop(),
                isDirectory: false,
                size: singleAttachmentsGet.size,
            };

            attachmentsAux.push(singleAttachment);
        }

        setAttachments(attachmentsAux);
    };

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    async function onItemDownloading(e) {
        e.cancel = true;

        const downloadFileResponse = await Storage.get("/prog/" + id + "/" + e.item.name, { download: true });

        downloadBlob(downloadFileResponse.Body, e.item.name);
    };

    function openBlob(blob, filename) {
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
    };

    async function onAttachmentOpen(e) {
        const downloadFileResponse = await Storage.get("/prog/" + id + "/" + e.file.name, { download: true });

        openBlob(downloadFileResponse.Body, e.file.name);
    };


    async function getDirectReturnPayload(ID) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    ID: ID,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setDirectReturnPayload(payloadGet);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/prog/" + ID);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }

    async function fnSCWebEnableTransactionButtons(sucursal) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'fnSCWebEnableTransactionButtons',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sucursal: sucursal
            }
        }

        var permissionGet = await API.get(apiName, path, myInit);

        try {
            return permissionGet[0].Permission;
        }
        catch (e) {
            return false;
        }
    }

    function editDirectReturn(data) {
        return (
            <Link to={`/DirectReturn/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
            </Link>
        )
    }

    async function editButton(ID) {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getDirectReturnPayload(ID);

                if (payload.header.Pedido) {
                    setActivateCustomSalesOrderField(false);
                }
                else {
                    setActivateCustomSalesOrderField(true);
                }

                if (permissionLevel === 'Editar') {
                    if (payload.header.Estatus === 'Sin Afectar') {
                        setActivateUpdateForm(true);
                        setActivateUpdateGrid(true);
                        setAcceptCustomValue(false);
                        setActivateSaveButton(true);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(true);
                        setActivateApproveButton(false);
                        setActivateGetItemButton(true);
                        setActivateSelectItemButton(true);
                        setActivatePDFButton(true);
                        setActivateCustomWarehouseField(false);
                        setActivateCancelButton(true);
                        setActivateCantidadButton(true);
                        setActivateCantidad2Button(false);
                        setActivateAttachments(true);

                        setActivatePaqueteria(payload.header.EntregaPaqueteria);

                        setContextMenuItems([
                            { text: 'Seleccionar Todo', icon: 'selectall' },
                            { text: 'Eliminar Selección', icon: 'unselectall' }
                        ]);
                    }
                    else if (payload.header.Estatus === 'Pendiente') {
                        var enableButtons = await fnSCWebEnableTransactionButtons(payload.header.Sucursal);

                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(enableButtons);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(enableButtons);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(enableButtons);

                        if (payload.header.RequiereAutorizacion === true && payload.header.Autorizado === false && approveDelivery)
                            setActivateApproveButton(true);
                        else
                            setActivateApproveButton(false);

                        setActivateGetItemButton(false);
                        setActivateSelectItemButton(enableButtons);
                        setActivatePDFButton(true);
                        setActivateCustomWarehouseField(true);
                        setActivateCancelButton(enableButtons);
                        setActivateCantidadButton(false);
                        setActivateCantidad2Button(true);
                        setActivateAttachments(enableButtons);

                        setActivatePaqueteria(payload.header.EntregaPaqueteria);

                        if (enableButtons)
                            setContextMenuItems([
                                { text: 'Seleccionar Todo', icon: 'selectall' },
                                { text: 'Eliminar Selección', icon: 'unselectall' }
                            ]);
                        else
                            setContextMenuItems([]);
                    }
                    else if (payload.header.Estatus === 'Cancelado') {
                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivateApproveButton(false);
                        setActivateGetItemButton(false);
                        setActivateSelectItemButton(false);
                        setActivatePDFButton(true);
                        setActivateCustomWarehouseField(true);
                        setActivateCancelButton(false);
                        setActivateCantidadButton(false);
                        setActivateCantidad2Button(false);
                        setActivateAttachments(false);

                        setContextMenuItems([]);

                        setActivatePaqueteria(payload.header.EntregaPaqueteria);
                    }
                    else if (payload.header.Estatus === 'Concluido') {
                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivateApproveButton(false);
                        setActivateGetItemButton(false);
                        setActivateSelectItemButton(false);
                        setActivatePDFButton(true);
                        setActivateCustomWarehouseField(true);
                        setActivateCancelButton(voidDeliveryTransactions);
                        setActivateCantidadButton(false);
                        setActivateCantidad2Button(false);
                        setActivateAttachments(false);

                        setContextMenuItems([]);

                        setActivatePaqueteria(payload.header.EntregaPaqueteria);
                    }
                }
                else if (permissionLevel === 'Ver') {
                    setActivateUpdateForm(false);
                    setActivateUpdateGrid(false);
                    setAcceptCustomValue(true);
                    setActivateSaveButton(false);
                    setActivateDeleteButton(false);
                    setActivateConfirmButton(false);
                    setActivateGetItemButton(false);
                    setActivateSelectItemButton(false);
                    setActivatePDFButton(true);
                    setActivateCustomWarehouseField(true);
                    setActivateCancelButton(false);
                    setActivateCantidadButton(false);
                    setActivateCantidad2Button(false);
                    setActivateAttachments(false);

                    setContextMenuItems([]);

                    setActivatePaqueteria(payload.header.EntregaPaqueteria);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (!ID || ID === 'undefined') {
                newButton();
            }
        }
    };

    async function cancelEditButton() {
        setIsDetail(false);
    };

    async function saveButton() {
        try {
            if (directReturnPayload.header.Estatus === 'Sin Afectar') {
                if (!directReturnPayload.header.Pedido || directReturnPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!directReturnPayload.header.Almacen || directReturnPayload.header.Almacen === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Almacén')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = directReturnPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridArticulosRef.current) {
                var gridArticulos = gridArticulosRef.current.instance();

                gridArticulos.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                if (!ID) {
                    setVisibleToast(true);
                    setMessageToast('Se creó la Devolución Directa con éxito');
                    setTypeToast('success')

                    ID = payloadPost.newID;

                    editButton(ID);

                    history.push('/DirectReturn/' + ID.toString());
                }
                else {
                    setVisibleToast(true);
                    setMessageToast('Se actualizó la Devolución Directa con éxito');
                    setTypeToast('success')
                }
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function approveButton() {
        try {

            setVisibleLoadPanel(true);

            var ID = directReturnPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'approve',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Devolución Directa Autorizada');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function confirmButton() {
        var ID = directReturnPayload.header.ID;

        try {
            if (directReturnPayload.header.Estatus === 'Sin Afectar') {
                if (!directReturnPayload.header.Pedido || directReturnPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!directReturnPayload.header.Almacen || directReturnPayload.header.Almacen === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Almacén')
                    setTypeToast('error');
                    return;
                }
                if (ID && directReturnPayload.header.EntregaPaqueteria && (!directReturnPayload.header.Paqueteria || directReturnPayload.header.Paqueteria === '')) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar la Paquetería')
                    setTypeToast('error');
                    return;
                }
                if (ID && directReturnPayload.header.EntregaPaqueteria && (!directReturnPayload.header.NumeroGuia || directReturnPayload.header.NumeroGuia === '')) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Número de Guía')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);


            //Guardamos los cambios del grid
            if (gridArticulosRef.current) {
                var gridArticulos = gridArticulosRef.current.instance();

                gridArticulos.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'apply',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se afectó la Devolución Directa con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function getItemsButton() {
        try {
            if (directReturnPayload.header.Estatus === 'Sin Afectar') {
                if (!directReturnPayload.header.Pedido || directReturnPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!directReturnPayload.header.Almacen || directReturnPayload.header.Almacen === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Almacén')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = directReturnPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridArticulosRef.current) {
                var gridArticulos = gridArticulosRef.current.instance();

                gridArticulos.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'getItems',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Artículos actualizados con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function selectAllButton() {
        try {
            if (directReturnPayload.header.Estatus === 'Sin Afectar') {
                if (!directReturnPayload.header.Pedido || directReturnPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!directReturnPayload.header.Almacen || directReturnPayload.header.Almacen === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Almacén')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = directReturnPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridArticulosRef.current) {
                var gridArticulos = gridArticulosRef.current.instance();

                gridArticulos.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'selectAll',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Artículos seleccionados con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function voidButton() {
        try {
            if (!window.confirm('¿Desea cancelar la Devolución Directa?'))
                return;

            setVisibleLoadPanel(true);

            var ID = directReturnPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridArticulosRef.current) {
                var gridArticulos = gridArticulosRef.current.instance();

                gridArticulos.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'void',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se canceló la Devolución Directa');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function selectNothingButton() {
        try {
            if (directReturnPayload.header.Estatus === 'Sin Afectar') {
                if (!directReturnPayload.header.Pedido || directReturnPayload.header.Pedido === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Pedido')
                    setTypeToast('error');
                    return;
                }
                if (!directReturnPayload.header.Almacen || directReturnPayload.header.Almacen === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Almacén')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = directReturnPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridArticulosRef.current) {
                var gridArticulos = gridArticulosRef.current.instance();

                gridArticulos.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'selectNothing',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Artículos seleccionados con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance();

            gridTablero.refresh();
        }
    }

    async function newButton(salesorder) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'new',
                    salesorder: salesorder,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            };

            var payloadNew = await API.get(apiName, path, myInit);

            setDirectReturnPayload(payloadNew);

            setAttachments([]);

            setIsDetail(true);
            setId(undefined);

            setActivateCustomSalesOrderField(true);
            setActivateCustomWarehouseField(true);
            setActivateUpdateForm(true);
            setActivateUpdateGrid(true);
            setAcceptCustomValue(false);
            setActivateSaveButton(true);
            setActivateDeleteButton(false);
            setActivateConfirmButton(false);
            setActivatePDFButton(false);
            setActivateGetItemButton(false);
            setActivateSelectItemButton(false);
            setActivateCancelButton(false);
            setActivateCantidadButton(false);
            setActivateCantidad2Button(false);
            setActivateAttachments(false);

            setVisibleLoadPanel(false);
            setContextMenuItems([]);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function deleteButton() {
        try {
            if (!window.confirm('¿Desea eliminar la Devolución Directa?'))
                return;

            setVisibleLoadPanel(true);

            var ID = directReturnPayload.header.ID;

            if (!ID) {
                //Vamos al tablero de control
                history.push('/DirectReturn');
                return;
            }

            let apiName = 'AdminSC';
            let path = '/procesadevoluciondirecta';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: directReturnPayload,
                    action: 'delete',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Devolución Directa eliminada con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Vamos al tablero de control
                history.push('/DirectReturn');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=directreturn&id=" + id, "_blank");
    };

    function openRelatedRecord(redirect) {
        if (redirect) {
            var baseUrl = window.location.href.split('#/')[0];

            redirect = baseUrl + '#' + redirect;

            window.open(redirect, "_blank");
        }
    };

    function openRelatedRecordButton(data) {
        return (
            <button onClick={() => openRelatedRecord(data.data.redirect)}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
        )
    };

    async function getPedidos(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'PedidoDevolucionDirecta',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var pedidosGet = await API.get(apiName, path, myInit);
        var tmpPedidos = [];

        for (var pedidosGetIndex in pedidosGet) {

            tmpPedidos.push({
                name: pedidosGet[pedidosGetIndex].SCRama,
                value: pedidosGet[pedidosGetIndex].SCRama
            })
        }

        return tmpPedidos;
    };

    async function getAlmacenes() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'AlmacenNormal',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var pedidosGet = await API.get(apiName, path, myInit);

        setAlmacenes(pedidosGet);
    };

    async function getPaqueteria() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Paqueteria',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var paqueteriaGet = await API.get(apiName, path, myInit);

        setPaqueteria(paqueteriaGet);
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/DirectReturn') {
                history.push('/DirectReturn');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            await getAlmacenes();

            await getPaqueteria();

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/DirectReturn/', '');

            var search = props.location.search;
            var salesorder;

            if (search)
                salesorder = search.replace('?salesorder=', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
            else
                newButton(salesorder);
        }

        // eslint-disable-next-line
    }, []);

    function overrideOnValueChanged(e) {
        //Hacemos la data persistente en cuanto el usuario escriba en el campo
        // if (e.parentType === "dataRow") {
        //     e.editorOptions.valueChangeEvent = "input";
        // }
    }

    function onHidingToast() {
        setVisibleToast(false);
    }

    const onFieldDatosProgramacionChanged = React.useCallback((e) => {
        var dataField = e.dataField;
        var value = e.value;


        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance();

            if (e.dataField === 'Pedido') {
                if (e.value) {
                    async function getSalesOrderInfo() {
                        try {
                            setVisibleLoadPanel(true);

                            let apiName = 'AdminSC';
                            let path = '/procesadevoluciondirecta';

                            let myInit = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                                },
                                queryStringParameters: {
                                    action: 'getSalesOrderInfo',
                                    salesOrder: value,
                                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                                }
                            };

                            var payloadNew = await API.get(apiName, path, myInit);

                            formDatosGenerales.updateData('NombreCliente', payloadNew.header.NombreCliente);
                            formDatosGenerales.updateData('Telefono', payloadNew.header.Telefono);
                            formDatosGenerales.updateData('Direccion', payloadNew.header.Direccion);
                            formDatosGenerales.updateData('Colonia', payloadNew.header.Colonia);
                            formDatosGenerales.updateData('CodigoPostal', payloadNew.header.CodigoPostal);
                            formDatosGenerales.updateData('Estado', payloadNew.header.Estado);
                            formDatosGenerales.updateData('Municipio', payloadNew.header.Municipio);
                            formDatosGenerales.updateData('Pais', payloadNew.header.Pais);

                            setVisibleLoadPanel(false);
                        }
                        catch (e) {
                            if (e === 'No current user') {
                                history.push('/');

                                window.location.reload();
                            }
                        }
                    }

                    getSalesOrderInfo();
                }
                else {
                    formDatosGenerales.updateData('NombreCliente', undefined);
                    formDatosGenerales.updateData('Telefono', undefined);
                    formDatosGenerales.updateData('Direccion', undefined);
                    formDatosGenerales.updateData('Colonia', undefined);
                    formDatosGenerales.updateData('CodigoPostal', undefined);
                    formDatosGenerales.updateData('Estado', undefined);
                    formDatosGenerales.updateData('Municipio', undefined);
                    formDatosGenerales.updateData('Pais', undefined);
                }
            }
        }
    }, [formDatosGeneralesRef, history])

    function contextMenuItemClick(e) {
        if (e.itemData.text === 'Seleccionar Todo') {
            selectAllButton();
        }
        else if (e.itemData.text === 'Eliminar Selección') {
            selectNothingButton();
        }
    };

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Devoluciones Directas</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faPersonCarryBox} size="xl" /> {(directReturnPayload.header ? directReturnPayload.header.Movimiento : 'Devolución Directa')}</h1>
                        <TransactionStatus {...props} Estatus={directReturnPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">

                        {permissionLevel === 'Editar' ?
                            (
                                <div>
                                    <Link to="/DirectReturn/undefined">
                                        <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nueva Devolución Directa</button>
                                    </Link>

                                    <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            ) :
                            (
                                <div>
                                    <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            )}

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerDirectReturn" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editDirectReturn} />
                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="FechaProgramada" caption="Fecha Programada" dataType="date" width={130} visible={false} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column dataField="Pedido" dataType="string" width={120} />
                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} visible={false} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />

                            <Column dataField="Almacen" dataType="string" width={190} caption="Almacén" visible={false} />
                            <Column dataField="NombreAlmacen" dataType="string" width={190} caption="Nombre Almacén" visible={false} />
                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />
                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />
                            <Column dataField="UsuarioOriginal" dataType="string" width={190} visible={false} />
                            <Column dataField="RequiereAutorizacion" dataType="boolean" caption="Requiere Autorización" width={200} visible={false} />
                            <Column dataField="Autorizado" dataType="boolean" width={150} visible={false} />
                            <Column dataField="FechaAutorizacion" caption="Fecha Autorización" dataType="datetime" width={200} visible={false} />
                            <Column dataField="EntregaPaqueteria" dataType="boolean" caption="Entrega por Paquetería" width={200} visible={false} />
                            <Column dataField="Paqueteria" dataType="string" caption="Paquetería" width={200} visible={false} />
                            <Column dataField="NumeroGuia" dataType="string" caption="Número de Guía" width={200} visible={false} />
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/DirectReturn/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/DirectReturn">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateConfirmButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faPlay} /> Afectar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateApproveButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={approveButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faThumbsUp} /> Autorizar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateGetItemButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={getItemsButton} type="submit" class="ml-4 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faLightbulb} /> Obtener Artículos
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateSelectItemButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={selectAllButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faCircleCheck} /> Todo
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateSelectItemButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={selectNothingButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faCircleX} /> Nada
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDeleteButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateCancelButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={voidButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faWindowClose} /> Cancelar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <ContextMenu
                                                dataSource={contextMenuItems}
                                                width={200}
                                                target="#gridArticulos"
                                                onItemClick={contextMenuItemClick}
                                            />

                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={directReturnPayload.header}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                onFieldDataChanged={onFieldDatosProgramacionChanged}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={7}>
                                                        <GroupItem caption="General" colSpan={2} >
                                                            <Item cssClass="boldText" dataField="Movimiento" editorOptions={movimientoEditorOptions} />
                                                            <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                <Label text={"Fecha Emisión"} />
                                                            </Item>
                                                            <Item dataField="FechaProgramada" editorType="dxDateBox" >
                                                                <Label text={"Fecha Programada"} />
                                                            </Item>
                                                            <Item dataField="Pedido" editorType="dxLookup" editorOptions={pedidoEditorOptions} >
                                                                <Label text="Pedido" />
                                                            </Item>
                                                            <Item dataField="Almacen" editorType="dxSelectBox" editorOptions={almacenEditorOptions} >
                                                                <Label text="Almacén" />
                                                            </Item>
                                                            <Item dataField="EntregaPaqueteria" editorType="dxCheckBox" >
                                                                <Label text="Entrega por paquetería" />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Artículos" colSpan={5} >
                                                            <DataGrid
                                                                id="gridArticulos"
                                                                ref={gridArticulosRef}
                                                                columnsAutoWidth={true}
                                                                dataSource={directReturnPayload.detail}
                                                                showBorders={true}
                                                                showColumnLines={true}
                                                                showRowLines={true}
                                                                rowAlternationEnabled={true}
                                                                allowColumnResizing={true}
                                                                columnResizingMode="widget"
                                                                allowColumnReordering={false}
                                                                repaintChangesOnly={false}
                                                            >
                                                                <Editing
                                                                    mode="cell"
                                                                    allowUpdating={activateUpdateGrid}
                                                                    allowAdding={false}
                                                                    allowDeleting={false}
                                                                />
                                                                <Sorting mode="none" />

                                                                <Column dataField="Renglon" width={190} visible={false} allowEditing={false} />
                                                                <Column dataField="Articulo" caption="Artículo" width={100} allowEditing={false} />
                                                                <Column dataField="Descripcion" caption="Descripción" width={350} allowEditing={false} />
                                                                <Column dataField="Cantidad" width={80} allowEditing={false} dataType="number" />
                                                                <Column dataField="CantidadA" caption="Programación" width={110} allowEditing={activateCantidadButton} visible={directReturnPayload.header.Estatus === 'Sin Afectar' || directReturnPayload.header.Estatus === 'Pendiente' || directReturnPayload.header.Estatus === 'Disponible' || directReturnPayload.header.Estatus === 'Concluido'} dataType="number" />
                                                                <Column dataField="CantidadRecibidaWMS" caption="Rec. WMS" width={100} allowEditing={false} visible={directReturnPayload.header.Estatus === 'Pendiente' || directReturnPayload.header.Estatus === 'Concluido'} dataType="number" />
                                                                <Column dataField="CantidadA2" caption="Entrega" width={100} allowEditing={activateCantidad2Button} visible={directReturnPayload.header.Estatus === 'Pendiente' || directReturnPayload.header.Estatus === 'Concluido'} dataType="number" />
                                                            </DataGrid>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Datos del Cliente" colCount={3}>
                                                        <Item dataField="NombreCliente" editorOptions={datosClienteEditorOptions} >
                                                            <Label text="Nombre" />
                                                        </Item>
                                                        <Item dataField="Telefono" editorOptions={datosClienteEditorOptions} >
                                                            <Label text="Teléfono" />
                                                        </Item>
                                                        <Item dataField="Direccion" editorOptions={datosClienteEditorOptions} >
                                                            <Label text="Dirección" />
                                                        </Item>
                                                        <Item dataField="Colonia" editorOptions={datosClienteEditorOptions} />
                                                        <Item dataField="CodigoPostal" editorOptions={datosClienteEditorOptions} >
                                                            <Label text="C.P." />
                                                        </Item>
                                                        <Item dataField="Estado" editorOptions={datosClienteEditorOptions} />
                                                        <Item dataField="Municipio" editorOptions={datosClienteEditorOptions} />
                                                        <Item dataField="Pais" editorOptions={datosClienteEditorOptions} >
                                                            <Label text="País" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Paquetería" colCount={3} visible={activatePaqueteria}>
                                                        <Item dataField="Paqueteria" editorType="dxSelectBox" editorOptions={paqueteriaEditorOptions} >
                                                            <Label text="Paquetería" />
                                                        </Item>
                                                        <Item dataField="NumeroGuia" editorOptions={numeroGuiaEditorOptions} >
                                                            <Label text="Número de Guía" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="Observaciones" editorType="dxTextArea" >
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Registros Relacionados">
                                                        <DataGrid
                                                            id="gridRelatedRecords"
                                                            columnsAutoWidth={true}
                                                            dataSource={directReturnPayload.relatedRecords}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />

                                                            <Column dataField="name" dataType="string" width={300} caption="Transacción" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column caption="" alignment="center" width={50} cellRender={openRelatedRecordButton} />
                                                            <Column dataField="FechaEmision" dataType="date" width={150} caption="Fecha Emisión" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={directReturnPayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Archivos Anexos" >
                                                        <div className="widget-container">
                                                            <FileUploader
                                                                multiple={true}
                                                                accept="*"
                                                                uploadMode="useButtons"
                                                                chunkSize={20000000}
                                                                maxFileSize={20000000}
                                                                uploadChunk={uploadChunk}
                                                                disabled={!activateAttachments}
                                                            />
                                                        </div>
                                                        <div id="wrapper" className="show-widget" >
                                                            <div id="widget-area">
                                                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                                                    <Permissions
                                                                        create={false}
                                                                        copy={false}
                                                                        move={false}
                                                                        delete={activateAttachments}
                                                                        rename={false}
                                                                        upload={false}
                                                                        download={true}
                                                                    >
                                                                    </Permissions>
                                                                </FileManager>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
