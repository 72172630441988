import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//ContextMenu
import ContextMenu from 'devextreme-react/context-menu';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faWindowClose } from "@fortawesome/pro-solid-svg-icons";
import { faFiles } from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";


import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import Lambda from 'aws-sdk/clients/lambda';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function CustomerPayment(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [permissionLevel, setPermissionLevel] = useState(props.nivel);
    const [customerPaymentPayload, setCustomerPaymentPayload] = useState({
        header: {},
        movEstatusLog: []
    });

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    // setValue para el control tab
    const [value, setValue] = React.useState(0);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [acceptCustomValues, setAcceptCustomValue] = useState(false);
    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activateCancelButton, setActivateCancelButton] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);
    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activateAttachments, setActivateAttachments] = useState(false);
    const [activateRegenerateCFDi, setActivateRegenerateCFDi] = useState(false);
    const [activateSendCFDi, setActivateSendCFDi] = useState(false);

    const [requiereDigitosTarjeta, setRequiereDigitosTarjeta] = useState(false);
    const [esPostfechado, setEsPostfechado] = useState(false);

    const [formaPago, setFormaPago] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    const [regenerateCFDi, setRegenerateCFDi] = useState(props.regenerateCFDi);
    const [sendCFDi, setSendCFDi] = useState(props.sendCFDi);

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    const [attachments, setAttachments] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Sin Afectar']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getCustomerPaymentRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var customerPaymentsGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return customerPaymentsGet;
    }

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getCustomerPaymentRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    var fechaEmisionEditorOptions = { width: '100%', readOnly: true };
    var movimientoEditorOptions = { width: '100%', readOnly: true };
    var clienteEditorOptions = { width: '100%', readOnly: true };
    var nombreClienteEditorOptions = { width: '100%', readOnly: true };
    var importeEditorOptions = { width: '100%', format: { type: 'currency', currency: 'MXN', precision: 2 }, readOnly: (customerPaymentPayload.header.SCPedidoPagoUnico || customerPaymentPayload.header.Estatus !== 'Sin Afectar') };
    var impuestosEditorOptions = { width: '100%', format: { type: 'currency', currency: 'MXN', precision: 2 }, readOnly: true };
    var saldoPedidoEditorOptions = { width: '100%', format: { type: 'currency', currency: 'MXN', precision: 2 }, readOnly: true, visible: (customerPaymentPayload.header.Estatus === 'Sin Afectar') };
    var monedaEditorOptions = { width: '100%', readOnly: true };
    var tipoCambioEditorOptions = { width: '100%', format: { type: 'currency', currency: 'MXN', precision: 2 }, readOnly: true };
    var pedidoEditorOptions = { width: '100%', readOnly: true };
    var scPedidoPagoUnicoEditorOptions = { width: '100%', readOnly: true };
    var ctaDineroEditorOptions = { width: '100%', readOnly: true };
    var notaCancelacionEditorOptions = { width: '100%', readOnly: !activateCancelButton };
    var datosCFDIEditorOptions = { width: '100%', readOnly: true };
    var devolucionSaldoEditorOptions = { width: '100%', readOnly: true };

    var digitosTarjetaEditorOptions = { width: '100%', readOnly: !requiereDigitosTarjeta, min: 1111, max: 9999 };
    var postfechadoEditorOptions = { width: '100%' };
    var fechaPostfechadoEditorOptions = { width: '100%', readOnly: !esPostfechado, visible: esPostfechado };

    var usuarioDataSource = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: {
                type: "array",
                key: "Usuario",
                data: usuarios
            }
        });
    }, [usuarios]);

    const storeSCReferencia = new CustomStore({
        key: 'value',
        load(loadOptions) {
            let sqlFilter = '';
            [
                'filter'
            ].forEach((i) => {
                sqlFilter = loadOptions.searchValue;
            });

            async function getRecords() {
                var records = await getSCReferencia(sqlFilter);

                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
        byKey: (key) => {
            return {
                name: key,
                value: key
            };
        }
    });

    const formaPagoDataSource = new DataSource({
        store: formaPago,
        key: 'FormaPago',
        group: 'Tipo',
    });

    //Opciones del campo FormaCobro
    try {
        var formaCobroEditorOptions = {
            dataSource: formaPagoDataSource,
            grouped: true,
            searchEnabled: true,
            showClearButton: true,
            displayExpr: 'FormaPago',
            valueExpr: 'FormaPago',
            value: (customerPaymentPayload.header.FormaCobro ? customerPaymentPayload.header.FormaCobro : ''),
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        }
    }
    catch (e) {
        var formaCobroEditorOptions = {
            dataSource: formaPagoDataSource,
            grouped: true,
            searchEnabled: true,
            showClearButton: true,
            valueExpr: 'FormaPago',
            displayExpr: 'FormaPago',
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        };
    }

    //Opciones del campo Pedido
    try {
        var scReferenciaEditorOptions = {
            dataSource: storeSCReferencia,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            showClearButton: true,
            value: (customerPaymentPayload.header.SCReferencia ? customerPaymentPayload.header.SCReferencia : ''),
            searchTimeout: 1500,
            width: '100%',
            clearButtonText: 'Cerrar',
            noDataText: 'No se encontraron resultados'
        };
    }
    catch (e) {
        var scReferenciaEditorOptions = {
            dataSource: storeSCReferencia,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    }

    //Opciones Usuario Asociado del Usuario
    try {
        var usuarioAutorizaEditorOptions = useMemo(() => {
            return {
                dataSource: usuarioDataSource,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: true,
                value: (customerPaymentPayload.header.SCUsuarioCancela ? customerPaymentPayload.header.SCUsuarioCancela : ''), width: '100%',
                readOnly: !activateCancelButton
            };
        }, [usuarioDataSource, customerPaymentPayload.header.SCUsuarioCancela, activateCancelButton]);
    }
    catch (e) {
        var usuarioAutorizaEditorOptions = {
            dataSource: usuarioDataSource,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: true,
            value: '',
            width: '100%'
        };
    }

    async function uploadChunk(file, uploadInfo) {
        try {
            var responseS3 = await Storage.put("/cxc/" + id + "/" + file.name, uploadInfo.chunkBlob, {
                contentType: file.type,
                ACL: 'public-read'
            });

            //Obtenemos el nombre del bucket
            let bucketName = Storage._config.AWSS3.bucket;


            let apiName = 'AdminSC';
            let path = '/uploadattachment';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'register',
                    bucketName: bucketName,
                    type: 'cxc',
                    id: id,
                    Key: "/cxc/" + id + "/" + file.name,
                    fileName: file.name,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var attachmentsResult = await API.post(apiName, path, myInit);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/cxc/" + id);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (err) {
            console.log("Error uploading file:", err);
        }
    }

    async function onAttachmentDeleting(e) {
        var fileName = e.item.key;

        //Primero borramos el archivo de S3
        var attachmentsDelete = await Storage.remove("/cxc/" + id + "/" + fileName);

        //Despues borramos el attachment en Intelisis
        let bucketName = Storage._config.AWSS3.bucket;

        let apiName = 'AdminSC';
        let path = '/uploadattachment';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
                action: 'delete',
                bucketName: bucketName,
                type: 'cxc',
                id: id,
                Key: "/cxc/" + id + "/" + fileName,
                fileName: fileName,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var attachmentsResult = await API.post(apiName, path, myInit);

        //Finalmente sincronizamos el state
        var attachmentsGet = await Storage.list("/cxc/" + id);
        var attachmentsAux = [];

        for (var attachmentsGetIndex in attachmentsGet) {
            var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

            var singleAttachment = {
                name: singleAttachmentsGet.key.split('/').pop(),
                isDirectory: false,
                size: singleAttachmentsGet.size,
            };

            attachmentsAux.push(singleAttachment);
        }

        setAttachments(attachmentsAux);
    };

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    async function onItemDownloading(e) {
        e.cancel = true;

        const downloadFileResponse = await Storage.get("/cxc/" + id + "/" + e.item.name, { download: true });

        downloadBlob(downloadFileResponse.Body, e.item.name);
    };

    function openBlob(blob, filename) {
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
    };

    async function onAttachmentOpen(e) {
        const downloadFileResponse = await Storage.get("/cxc/" + id + "/" + e.file.name, { download: true });

        openBlob(downloadFileResponse.Body, e.file.name);
    };

    async function getCustomerPaymentPayload(ID) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    ID: ID,
                    action: 'payload',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setCustomerPaymentPayload(payloadGet);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/cxc/" + ID);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }

    async function getInvoicePayload(IDFactura) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'invoicePayload',
                    invoices: [{ ID: IDFactura }],
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            };

            var payloadGet = await API.post(apiName, path, myInit);

            return payloadGet;
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }

    function editCustomerPayment(data) {
        return (
            <Link to={`/CustomerPayment/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
            </Link>
        )
    }

    async function editButton(ID) {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getCustomerPaymentPayload(ID);
                setEsPostfechado(payload.header.SCPostfechado);

                if (payload.header.SCReferencia) {
                    await getFormaPago(payload.header.SCReferencia);
                }

                if (permissionLevel === 'Editar') {
                    if (payload.header.Estatus === 'Sin Afectar') {
                        setActivateUpdateForm(true);
                        setAcceptCustomValue(false);
                        setActivateSaveButton(true);
                        setActivateDeleteButton(true);
                        setActivateConfirmButton(true);
                        setActivatePDFButton(true);
                        setActivateCancelButton(false);
                        setActivateAttachments(true);
                        setActivateRegenerateCFDi(false);
                        setActivateSendCFDi(false);
                    }
                    else if (payload.header.Estatus === 'Concluido') {
                        setActivateUpdateForm(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivatePDFButton(true);
                        setActivateCancelButton(true);
                        setActivateAttachments(false);

                        if (regenerateCFDi && payload.header.CFDIUUID)
                            setActivateRegenerateCFDi(true);
                        else
                            setActivateRegenerateCFDi(false);

                        if (sendCFDi && payload.header.CFDIUUID)
                            setActivateSendCFDi(true);
                        else
                            setActivateSendCFDi(false);
                    }
                    else if (payload.header.Estatus === 'Cancelado') {
                        setActivateUpdateForm(false);
                        setAcceptCustomValue(true);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateConfirmButton(false);
                        setActivatePDFButton(true);
                        setActivateCancelButton(false);
                        setActivateAttachments(false);

                        if (regenerateCFDi && payload.header.CFDIUUID)
                            setActivateRegenerateCFDi(true);
                        else
                            setActivateRegenerateCFDi(false);

                        if (sendCFDi && payload.header.CFDIUUID)
                            setActivateSendCFDi(true);
                        else
                            setActivateSendCFDi(false);
                    }
                }
                else if (permissionLevel === 'Ver') {
                    setActivateUpdateForm(false);
                    setAcceptCustomValue(true);
                    setActivateSaveButton(false);
                    setActivateDeleteButton(false);
                    setActivateConfirmButton(false);
                    setActivatePDFButton(true);
                    setActivateCancelButton(false);
                    setActivateAttachments(false);
                    setActivateRegenerateCFDi(false);
                    setActivateSendCFDi(false);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (!ID || ID === 'undefined') {
                newButton();
            }
        }
    };

    async function cancelEditButton() {
        setIsDetail(false);
    };

    async function saveButton() {
        try {
            if (customerPaymentPayload.header.Estatus === 'Sin Afectar') {
                if (!customerPaymentPayload.header.SCReferencia || customerPaymentPayload.header.SCReferencia === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar la Referencia')
                    setTypeToast('error');
                    return;
                }
                if (!customerPaymentPayload.header.Cliente || customerPaymentPayload.header.Cliente === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Cliente')
                    setTypeToast('error');
                    return;
                }
                if (!customerPaymentPayload.header.FormaCobro || customerPaymentPayload.header.FormaCobro === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar la Forma de Cobro')
                    setTypeToast('error');
                    return;
                }
                if (!customerPaymentPayload.header.Importe || customerPaymentPayload.header.Importe <= 0) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Importe')
                    setTypeToast('error');
                    return;
                }
                if (customerPaymentPayload.header.SCPostfechado && !customerPaymentPayload.header.SCFechaPostfechado) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar la Fecha Postfechada')
                    setTypeToast('error');
                    return;
                }
                if (requiereDigitosTarjeta && (!customerPaymentPayload.header.SCDigitosTarjeta || customerPaymentPayload.header.SCDigitosTarjeta === 0)) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar los Dígitos de la Tarjeta')
                    setTypeToast('error');
                    return;
                }
            }

            setVisibleLoadPanel(true);

            var ID = customerPaymentPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: customerPaymentPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                if (!ID) {
                    setVisibleToast(true);
                    setMessageToast('Se creó el Anticipo Saldo con éxito');
                    setTypeToast('success')

                    ID = payloadPost.newID;

                    editButton(ID);

                    history.push('/CustomerPayment/' + ID.toString());
                }
                else {
                    setVisibleToast(true);
                    setMessageToast('Se actualizó el Anticipo Saldo con éxito');
                    setTypeToast('success')
                }
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function confirmButton() {
        var SaldoPedido = parseFloat(customerPaymentPayload.header.SaldoPedido);
        var Importe = parseFloat(customerPaymentPayload.header.Importe);
        var SCPedidoPagoUnico = parseFloat(customerPaymentPayload.header.SCPedidoPagoUnico);

        if (!SaldoPedido)
            SaldoPedido = 0.0;
        if (!Importe)
            Importe = 0.0;

        try {
            if (customerPaymentPayload.header.Estatus === 'Sin Afectar') {
                if (!customerPaymentPayload.header.SCReferencia || customerPaymentPayload.header.SCReferencia === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar la Referencia')
                    setTypeToast('error');
                    return;
                }
                if (!customerPaymentPayload.header.Cliente || customerPaymentPayload.header.Cliente === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Cliente')
                    setTypeToast('error');
                    return;
                }
                if (!customerPaymentPayload.header.FormaCobro || customerPaymentPayload.header.FormaCobro === '') {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar la Forma de Cobro')
                    setTypeToast('error');
                    return;
                }
                if (Importe <= 0) {
                    setVisibleToast(true);
                    setMessageToast('Falta especificar el Importe')
                    setTypeToast('error');
                    return;
                }
                if (SaldoPedido <= 0) {
                    setVisibleToast(true);
                    setMessageToast('El Pedido no tiene Saldo Pendiente')
                    setTypeToast('error');
                    return;
                }
                if (Importe > SaldoPedido) {
                    setVisibleToast(true);
                    setMessageToast('El Importe a aplicar es mayor que el Saldo Pendiente del Pedido')
                    setTypeToast('error');
                    return;
                }
            }

            if (Importe === SaldoPedido) {
                if (!window.confirm('Al aplicar el Anticipo por el Saldo del Pedido ya no podrá modificarse el Pedido. ¿Deseas continuar?'))
                    return;
            }

            setVisibleLoadPanel(true);

            var ID = customerPaymentPayload.header.ID;

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'procesaAnticipoSaldo-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'procesaAnticipoSaldo-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'procesaAnticipoSaldo-prodleona';
            else
                lambdaFunction = 'procesaAnticipoSaldo-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            ID: ID,
                            payload: customerPaymentPayload,
                            action: 'apply',
                            username: props.username,
                            usuario: props.usuario,
                            sucursal: props.sucursal,
                            s3Bucket: Storage._config.AWSS3.bucket
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);

                            setVisibleToast(true);
                            setMessageToast(err.message);
                            setTypeToast('error');
                        }
                        else {
                            var payloadPost = JSON.parse(JSON.parse(data.Payload).body);

                            if (payloadPost.ok === 'true') {
                                async function checkCFDiStatus() {
                                    //Primero obtenemos el payload actualizado
                                    var payload = await getCustomerPaymentPayload(id);

                                    if (payload.header.CFDIUUID) {
                                        // Despues generamos el PDF
                                        var pdfPost = await generateCFDiPDF(payload);

                                        // Finalmente enviamos el correo electrónico
                                        var sendCFDiPost = await sendCFDiFiles(true, payload.header.Movimiento);
                                    }

                                    if (payload.header.IDFactura) {
                                        // Primero obtenemos el payload de la factura
                                        var payloadFactura = await getInvoicePayload(payload.header.IDFactura);

                                        // Después generamos el PDF
                                        var pdfPost = await generateInvoiceCFDiPDF(payload, payloadFactura);

                                        // Finalmente enviamos el correo electrónico
                                        var sendCFDiPost = await sendInvoiceCFDiFiles(true, payload.header.MovimientoFactura, payload.header.IDFactura);
                                    }

                                    setVisibleToast(true);
                                    setMessageToast('Anticipo Saldo aplicado correctamente');
                                    setTypeToast('success');

                                    editButton(ID);

                                    setVisibleLoadPanel(false);
                                };

                                checkCFDiStatus();

                                window.opener.location.reload();
                            }
                            else if (payloadPost.ok === 'false') {

                                setVisibleToast(true);

                                if (!payloadPost.message || payloadPost.message === '' || payloadPost.message === 'Error SQL')
                                    setMessageToast('No se pudo aplicar el Anticipo Saldo');
                                else
                                    setMessageToast(payloadPost.message);

                                setTypeToast('error')

                                editButton(ID);

                                setVisibleLoadPanel(false);
                            }
                        }
                    });
                })
            /*
            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = { 
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: customerPaymentPayload,
                    action: 'apply',
                    username: props.username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if(payloadPost.ok === 'true'){
                //Primero obtenemos el payload actualizado
                var payload = await getCustomerPaymentPayload(id);

                // Despues generamos el PDF
                var pdfPost = await generateCFDiPDF(payload);

                // Finalmente enviamos el correo electrónico
                var sendCFDiPost = await sendCFDiFiles(true, payload.header.Movimiento);

                setVisibleToast(true);
                setMessageToast('Se afectó el Anticipo Saldo con éxito');
                setTypeToast('success')
            }
            else if(payloadPost.ok === 'false'){
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
            */
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function voidButton() {
        try {
            if (!customerPaymentPayload.header.SCUsuarioCancela || customerPaymentPayload.header.SCUsuarioCancela === '') {
                setVisibleToast(true);
                setMessageToast('Falta especificar el Usuario que autoriza la cancelación')
                setTypeToast('error');
                return;
            }

            if (!customerPaymentPayload.header.SCCancelaNota || customerPaymentPayload.header.SCCancelaNota === '') {
                setVisibleToast(true);
                setMessageToast('Falta especificar la Nota de cancelación')
                setTypeToast('error');
                return;
            }

            if (!window.confirm('¿Desea cancelar el Anticipo Saldo?'))
                return;

            setVisibleLoadPanel(true);

            var ID = customerPaymentPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: customerPaymentPayload,
                    action: 'void',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se canceló el Anticipo Saldo');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance();

            gridTablero.refresh();
        }
    }

    async function newButton(salesorder) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'new',
                    salesorder: salesorder,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                }
            };

            var payloadNew = await API.get(apiName, path, myInit);

            if (payloadNew.header.SCReferencia) {
                await getFormaPago(payloadNew.header.SCReferencia);
            }

            setCustomerPaymentPayload(payloadNew);

            setAttachments([]);

            setIsDetail(true);
            setId(undefined);

            setActivateUpdateForm(true);
            setAcceptCustomValue(false);
            setActivateSaveButton(true);
            setActivateDeleteButton(false);
            setActivateConfirmButton(false);
            setActivatePDFButton(false);
            setActivateCancelButton(false);
            setActivateAttachments(false);
            setActivateRegenerateCFDi(false);
            setActivateSendCFDi(false);
            setRequiereDigitosTarjeta(false);
            setEsPostfechado(false);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function deleteButton() {
        try {
            if (!window.confirm('¿Desea eliminar el Anticipo Saldo?'))
                return;

            setVisibleLoadPanel(true);

            var ID = customerPaymentPayload.header.ID;

            if (!ID) {
                //Vamos al tablero de control
                history.push('/CustomerPayment');
                return;
            }

            let apiName = 'AdminSC';
            let path = '/procesaanticiposaldo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: customerPaymentPayload,
                    action: 'delete',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Anticipo Saldo eliminado con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Vamos al tablero de control
                history.push('/CustomerPayment');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=customerpayment&id=" + id, "_blank");
    };

    async function regenerateCFDiFilesButton() {
        await generateCFDiFiles(false);
    };

    async function generateCFDiFiles(hideToast) {
        var modulo = 'cxc';
        var type = 'customerpayment';

        try {
            //Primero obtenemos el payload actualizado
            var payload = await getCustomerPaymentPayload(id);

            // En caso de no existir folio fiscal no es necesario crear los archivos
            if (!payload.header.CFDIUUID)
                return;

            if (!hideToast)
                setVisibleLoadPanel(true);

            // Despues enviamos el payload a la API crearPDF
            // *********************************************
            let apiName = 'AdminSC';
            let path = '/crearpdf';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    type: type,
                    payload: payload,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${id}/${payload.header.Mov.trim()} ${payload.header.MovID.trim()}.pdf`,
                    contentType: 'application/pdf',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var pdfPost = await API.post(apiName, path, myInit);
            // *********************************************

            if (pdfPost.message.indexOf('Error') >= 0) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(pdfPost.message);
                setTypeToast('error');
                return;
            }

            // Despues enviamos el payload a la API crearXML
            // *********************************************
            apiName = 'AdminSC';
            path = '/crearxml';

            myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    modulo: modulo,
                    id: id,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${id}/${payload.header.Mov.trim()} ${payload.header.MovID.trim()}.xml`,
                    contentType: 'text/xml',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var xmlPost = await API.post(apiName, path, myInit);
            // *********************************************
            if (!hideToast) {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Se generaron los archivos CFDi con éxito');
                setTypeToast('success')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

    };

    async function generateCFDiPDF(payload) {
        var modulo = 'cxc';
        var type = 'customerpayment';

        try {
            // En caso de no existir folio fiscal no es necesario crear los archivos
            if (!payload.header.CFDIUUID)
                return;

            // Despues enviamos el payload a la API crearPDF
            // *********************************************
            let apiName = 'AdminSC';
            let path = '/crearpdf';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    type: type,
                    payload: payload,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${id}/${payload.header.Mov.trim()} ${payload.header.MovID.trim()}.pdf`,
                    contentType: 'application/pdf',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var pdfPost = await API.post(apiName, path, myInit);
            // *********************************************

            if (pdfPost.message.indexOf('Error') >= 0) {
                setVisibleToast(true);
                setMessageToast(pdfPost.message);
                setTypeToast('error');
                return;
            }
            else {
                return pdfPost;
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

    };

    async function generateInvoiceCFDiPDF(payload, payloadFactura) {
        var modulo = 'vtas';
        var type = 'invoice';

        try {
            // Despues enviamos el payload a la API crearPDF
            // *********************************************
            let apiName = 'AdminSC';
            let path = '/crearpdf';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    type: type,
                    payload: payloadFactura,
                    bucketName: Storage._config.AWSS3.bucket,
                    fileKey: `public//${modulo}/${payload.header.IDFactura}/${payload.header.MovimientoFactura.trim()}.pdf`,
                    contentType: 'application/pdf',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var pdfPost = await API.post(apiName, path, myInit);

            // *********************************************

            if (pdfPost.message.indexOf('Error') >= 0) {
                setVisibleToast(true);
                setMessageToast(pdfPost.message);
                setTypeToast('error');
                return;
            }
            else {
                return pdfPost;
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    async function sendCFDiFilesButton() {
        await sendCFDiFiles(false, customerPaymentPayload.header.Movimiento);
    }

    async function sendCFDiFiles(hideToast, Movimiento) {
        try {
            let apiName = 'AdminSC';
            let path = '/enviarcorreo';

            let pdfFile = await Storage.get("/cxc/" + id + "/" + Movimiento.trim() + '.pdf', { download: true });
            let xmlFile = await Storage.get("/cxc/" + id + "/" + Movimiento.trim() + '.xml', { download: true });

            if (!pdfFile || !xmlFile) {
                return;
            }

            if (!hideToast)
                setVisibleLoadPanel(true);

            let xmlFileContents = await blobToBase64(xmlFile.Body);
            let pdfFileContents = await blobToBase64(pdfFile.Body);

            xmlFileContents = xmlFileContents.toString().split('base64,')[1];
            pdfFileContents = pdfFileContents.toString().split('base64,')[1];

            if (!xmlFileContents || !pdfFileContents) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Archivos CFDi incorrectos');
                setTypeToast('error');
                return;
            }

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ToAddresses: [customerPaymentPayload.header.cte_CorreoElectronico],
                    // BccAddresses: [],
                    // CcAddresses: [],
                    Body: 'CFDi ' + Movimiento.trim(),
                    Subject: 'CFDi ' + Movimiento.trim(),
                    Source: 'admin@supercolchones.com',
                    Attachments: [{
                        filename: Movimiento.trim() + '.xml',
                        content: xmlFileContents,
                        encoding: 'base64'
                    },
                    {
                        filename: Movimiento.trim() + '.pdf',
                        content: pdfFileContents,
                        encoding: 'base64'
                    }
                    ],
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var sendCFDiPost = await API.post(apiName, path, myInit);

            if (sendCFDiPost.message.indexOf('Error') >= 0) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(sendCFDiPost.message);
                setTypeToast('error');
            }
            else {
                if (hideToast === false) {
                    setVisibleLoadPanel(false);

                    setVisibleToast(true);
                    setMessageToast(sendCFDiPost.message);
                    setTypeToast('success');
                }
            }

            return sendCFDiPost;
        }
        catch (e) {
            setVisibleToast(true);
            setMessageToast(e.message);
            setTypeToast('error');
        }
    }

    async function sendInvoiceCFDiFiles(hideToast, MovimientoFactura, IDFactura) {
        try {
            let apiName = 'AdminSC';
            let path = '/enviarcorreo';

            let pdfFile = await Storage.get("/vtas/" + IDFactura + "/" + MovimientoFactura.trim() + '.pdf', { download: true });
            let xmlFile = await Storage.get("/vtas/" + IDFactura + "/" + MovimientoFactura.trim() + '.xml', { download: true });

            if (!pdfFile || !xmlFile) {
                return;
            }

            if (!hideToast)
                setVisibleLoadPanel(true);

            let xmlFileContents = await blobToBase64(xmlFile.Body);
            let pdfFileContents = await blobToBase64(pdfFile.Body);

            xmlFileContents = xmlFileContents.toString().split('base64,')[1];
            pdfFileContents = pdfFileContents.toString().split('base64,')[1];

            if (!xmlFileContents || !pdfFileContents) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Archivos CFDi incorrectos');
                setTypeToast('error');
                return;
            }

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ToAddresses: [customerPaymentPayload.header.cte_CorreoElectronico],
                    // BccAddresses: [],
                    // CcAddresses: [],
                    Body: 'CFDi ' + MovimientoFactura.trim(),
                    Subject: 'CFDi ' + MovimientoFactura.trim(),
                    Source: 'admin@supercolchones.com',
                    Attachments: [{
                        filename: MovimientoFactura.trim() + '.xml',
                        content: xmlFileContents,
                        encoding: 'base64'
                    },
                    {
                        filename: MovimientoFactura.trim() + '.pdf',
                        content: pdfFileContents,
                        encoding: 'base64'
                    }
                    ],
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var sendCFDiPost = await API.post(apiName, path, myInit);

            if (sendCFDiPost.message.indexOf('Error') >= 0) {
                if (!hideToast)
                    setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(sendCFDiPost.message);
                setTypeToast('error');
            }
            else {
                if (hideToast === false) {
                    setVisibleLoadPanel(false);

                    setVisibleToast(true);
                    setMessageToast(sendCFDiPost.message);
                    setTypeToast('success');
                }
            }

            return sendCFDiPost;
        }
        catch (e) {
            setVisibleToast(true);
            setMessageToast(e.message);
            setTypeToast('error');
        }
    }

    async function getSCReferencia(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'SCReferenciaAnticipoSaldo',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: sqlFilter
            }
        }

        var scReferencia = await API.get(apiName, path, myInit);
        var tmpSCReferencia = [];

        for (var scReferenciaIndex in scReferencia) {

            tmpSCReferencia.push({
                name: scReferencia[scReferenciaIndex].SCReferencia,
                value: scReferencia[scReferenciaIndex].SCReferencia
            })
        }

        return tmpSCReferencia;
    };

    async function getFormaPago(SCReferencia) {
        try {
            let apiName = 'AdminSC';
            let path = '/getcatalogo';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    type: 'FormaPago',
                    screferencia: SCReferencia,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var formaPagoGet = await API.get(apiName, path, myInit);

            setFormaPago(formaPagoGet);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/CustomerPayment') {
                history.push('/CustomerPayment');

                window.location.reload();
            }
        });
        async function initialize() {
            setVisibleLoadPanel(true);

            await getUsuario();

            setVisibleLoadPanel(false);
        }

        initialize();


        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/CustomerPayment/', '');

            var search = props.location.search;
            var salesorder;

            if (search)
                salesorder = search.replace('?salesorder=', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
            else
                newButton(salesorder);
        }

        // eslint-disable-next-line
    }, []);

    function overrideOnValueChanged(e) {
        //Hacemos la data persistente en cuanto el usuario escriba en el campo
        // if (e.parentType === "dataRow") {
        //     e.editorOptions.valueChangeEvent = "input";
        // }
    };

    function onHidingToast() {
        setVisibleToast(false);
    };

    const onFieldDatosGeneralesChanged = React.useCallback((e) => {
        var dataField = e.dataField;
        var value = e.value;

        if (formDatosGeneralesRef.current) {
            var formDatosGenerales = formDatosGeneralesRef.current.instance();

            if (e.dataField === 'SCReferencia') {
                if (e.value) {
                    async function getSalesOrderInfo() {
                        try {
                            setVisibleLoadPanel(true);

                            let apiName = 'AdminSC';
                            let path = '/procesaanticiposaldo';

                            let myInit = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                                },
                                queryStringParameters: {
                                    action: 'getSalesOrderInfo',
                                    screferencia: value,
                                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                                }
                            };

                            var payloadNew = await API.get(apiName, path, myInit);

                            formDatosGenerales.updateData('Pedido', payloadNew.header.Pedido);
                            formDatosGenerales.updateData('Cliente', payloadNew.header.Cliente);
                            formDatosGenerales.updateData('NombreCliente', payloadNew.header.NombreCliente);
                            formDatosGenerales.updateData('Importe', payloadNew.header.Importe);
                            formDatosGenerales.updateData('SaldoPedido', payloadNew.header.Importe);
                            formDatosGenerales.updateData('Impuestos', payloadNew.header.Impuestos);
                            formDatosGenerales.updateData('SCPedidoPagoUnico', payloadNew.header.SCPedidoPagoUnico);

                            setVisibleLoadPanel(false);
                        }
                        catch (e) {
                            if (e === 'No current user') {
                                history.push('/');

                                window.location.reload();
                            }
                        }
                    }

                    getSalesOrderInfo();

                    async function getFormaPagoReferencia(SCReferencia) {
                        try {
                            let apiName = 'AdminSC';
                            let path = '/getcatalogo';

                            let myInit = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                                },
                                queryStringParameters: {
                                    type: 'FormaPago',
                                    screferencia: SCReferencia,
                                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                                }
                            }

                            var formaPagoGet = await API.get(apiName, path, myInit);

                            setFormaPago(formaPagoGet);
                        }
                        catch (e) {
                            if (e === 'No current user') {
                                history.push('/');

                                window.location.reload();
                            }
                        }
                    };

                    getFormaPagoReferencia(e.value);
                }
                else {
                    formDatosGenerales.updateData('Pedido', undefined);
                    formDatosGenerales.updateData('Cliente', undefined);
                    formDatosGenerales.updateData('NombreCliente', undefined);
                    formDatosGenerales.updateData('Importe', undefined);
                    formDatosGenerales.updateData('SaldoPedido', undefined);
                    formDatosGenerales.updateData('Impuestos', undefined);
                    formDatosGenerales.updateData('SCPedidoPagoUnico', false);

                    setFormaPago([]);
                }
            }
            else if (e.dataField === 'FormaCobro') {
                formDatosGenerales.updateData('SCDigitosTarjeta', undefined);

                for (var formaPagoIndex in formaPago) {
                    var singleFormaPago = formaPago[formaPagoIndex];

                    if (singleFormaPago.FormaPago === value) {
                        setRequiereDigitosTarjeta(singleFormaPago.SCRequiereDigitosTarjeta);
                    }
                }
            }
            else if (e.dataField === 'SCPostfechado') {
                formDatosGenerales.updateData('SCFechaPostfechado', undefined);

                setEsPostfechado(value);
            }
        }
    }, [formDatosGeneralesRef, history, formaPago])

    async function getUsuario() {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'Usuario',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var usuariosGet = await API.get(apiName, path, myInit);

        setUsuarios(usuariosGet);
    };

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Anticipos Saldo</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faPiggyBank} size="xl" /> {(customerPaymentPayload.header ? customerPaymentPayload.header.Movimiento : 'Anticipo Saldo')}</h1>
                        <TransactionStatus {...props} Estatus={customerPaymentPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">

                        {permissionLevel === 'Editar' ?
                            (
                                <div>
                                    <Link to="/CustomerPayment/undefined">
                                        <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nuevo Anticipo Saldo</button>
                                    </Link>

                                    <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            ) :
                            (
                                <div>
                                    <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            )}

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>

                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerCustomerPayment" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editCustomerPayment} />

                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column dataField="NombreCliente" caption="Nombre Cliente" dataType="string" width={250} />
                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} visible={false} />
                            <Column dataField="Pedido" dataType="string" width={150} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />
                            <Column dataField="Importe" caption="Importe" dataType="number" width={150} visible={false}>
                                <Format
                                    type="currency"
                                    precision={2}
                                />
                            </Column>
                            <Column dataField="Impuestos" caption="Impuestos" dataType="number" width={150} visible={false}>
                                <Format
                                    type="currency"
                                    precision={2}
                                />
                            </Column>
                            <Column dataField="ImporteTotal" caption="Importe Total" dataType="number" width={150}>
                                <Format
                                    type="currency"
                                    precision={2}
                                />
                            </Column>

                            <Column dataField="Cliente" dataType="string" width={190} visible={false} />
                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />
                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />
                            <Column dataField="FormaCobro" dataType="string" width={190} visible={false} />
                            <Column dataField="CFDIFechaTimbrado" dataType="datetime" caption="CFDI - Fecha Certificación" width={190} visible={false} />
                            <Column dataField="CFDIUUID" dataType="string" caption="CFDI - UUID" width={190} visible={false} />
                            <Column dataField="CFDICancelado" dataType="boolean" caption="CFDI - Cancelado" width={190} visible={false} />
                            <Column dataField="DevolucionSaldo" dataType="string" caption="Devolución Saldo" width={190} visible={false} />

                            <Column dataField="ReferenciaBanco" dataType="string" caption="Referencia Banco" width={190} visible={false} />
                            <Column dataField="CorteCaja" dataType="string" caption="Corte Caja" width={190} visible={false} />

                            <Summary>
                                <GroupItem
                                    column="Importe"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe {0}"
                                    showInGroupFooter={false}>
                                </GroupItem>
                                <GroupItem
                                    column="Impuestos"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Impuestos {0}"
                                    showInGroupFooter={false}>
                                </GroupItem>
                                <GroupItem
                                    column="ImporteTotal"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe Total {0}"
                                    showInGroupFooter={false}>
                                </GroupItem>
                                <TotalItem
                                    column="Importe"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe {0}"
                                    showInColumn="Importe">
                                </TotalItem>
                                <TotalItem
                                    column="Impuestos"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Impuestos {0}"
                                    showInColumn="Impuestos">
                                </TotalItem>
                                <TotalItem
                                    column="ImporteTotal"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe Total {0}"
                                    showInColumn="ImporteTotal">
                                </TotalItem>
                            </Summary>
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/CustomerPayment/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/CustomerPayment">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateConfirmButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faPlay} /> Afectar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDeleteButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateCancelButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={voidButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faWindowClose} /> Cancelar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateRegenerateCFDi && id ?
                                            (
                                                <button onClick={regenerateCFDiFilesButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFiles} /> Regenerar CFDi
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateSendCFDi && id ?
                                            (
                                                <button onClick={sendCFDiFilesButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faEnvelope} /> Reenviar CFDi
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={customerPaymentPayload.header}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                onFieldDataChanged={onFieldDatosGeneralesChanged}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={2}>
                                                        <GroupItem caption="General" colCount={2}>
                                                            <Item cssClass="boldText" colSpan={2} dataField="Movimiento" editorOptions={movimientoEditorOptions} />
                                                            <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                <Label text={"Fecha Emisión"} />
                                                            </Item>
                                                            <Item dataField="CtaDinero" editorOptions={ctaDineroEditorOptions} >
                                                                <Label text="Cuenta" />
                                                            </Item>
                                                            <Item dataField="Moneda" editorOptions={monedaEditorOptions} >
                                                                <Label text="Moneda" />
                                                            </Item>
                                                            <Item dataField="TipoCambio" editorType="dxNumberBox" editorOptions={tipoCambioEditorOptions} >
                                                                <Label text="Tipo de Cambio" />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Pago" colCount={2}>
                                                            <Item dataField="FormaCobro" colSpan={2} editorType="dxLookup" editorOptions={formaCobroEditorOptions} >
                                                                <Label text="Forma de Cobro" />
                                                            </Item>
                                                            <Item dataField="SCDigitosTarjeta" editorType="dxNumberBox" editorOptions={digitosTarjetaEditorOptions} >
                                                                <Label text="Dígitos Tarjeta" />
                                                            </Item>
                                                            <EmptyItem />
                                                            <Item dataField="Importe" editorType="dxNumberBox" editorOptions={importeEditorOptions} >
                                                                <Label text="Importe" />
                                                            </Item>
                                                            <Item dataField="Impuestos" editorType="dxNumberBox" editorOptions={impuestosEditorOptions} >
                                                                <Label text="Impuestos" />
                                                            </Item>
                                                            <Item dataField="SCPostfechado" editorType="dxCheckBox" editorOptions={postfechadoEditorOptions} >
                                                                <Label text="Postfechado" />
                                                            </Item>
                                                            <Item dataField="SCFechaPostfechado" editorType="dxDateBox" editorOptions={fechaPostfechadoEditorOptions} >
                                                                <Label text="Fecha" visible={esPostfechado} />
                                                            </Item>

                                                        </GroupItem>
                                                        <GroupItem caption="Pedido" colCount={2}>
                                                            <Item dataField="SCReferencia" colSpan={2} editorType="dxLookup" editorOptions={scReferenciaEditorOptions} >
                                                                <Label text="Referencia" />
                                                            </Item>
                                                            <Item dataField="Cliente" editorOptions={clienteEditorOptions} >
                                                                <Label text="Cliente" />
                                                            </Item>
                                                            <Item dataField="Pedido" editorOptions={pedidoEditorOptions} >
                                                                <Label text="Pedido" />
                                                            </Item>
                                                            <Item dataField="NombreCliente" colSpan={2} editorOptions={nombreClienteEditorOptions} >
                                                                <Label text="Nombre Cliente" />
                                                            </Item>
                                                            <Item dataField="SaldoPedido" editorType="dxNumberBox" editorOptions={saldoPedidoEditorOptions} >
                                                                <Label text="Saldo" visible={(customerPaymentPayload.header.Estatus === 'Sin Afectar')} />
                                                            </Item>
                                                            <Item dataField="SCPedidoPagoUnico" editorType="dxCheckBox" colSpan={2} editorOptions={scPedidoPagoUnicoEditorOptions} >
                                                                <Label text="Pagado en una exhibición" />
                                                            </Item>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="Observaciones" editorType="dxTextArea" >
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Datos Cancelación" visible={customerPaymentPayload.header.Estatus === 'Concluido' || customerPaymentPayload.header.Estatus === 'Cancelado'} >
                                                        <GroupItem caption="Autorización" colCount={1}>
                                                            <Item dataField="SCUsuarioCancela" editorType="dxSelectBox" editorOptions={usuarioAutorizaEditorOptions}>
                                                                <Label text="Usuario Autorización" />
                                                            </Item>
                                                            <Item dataField="SCCancelaNota" editorType="dxTextArea" editorOptions={notaCancelacionEditorOptions}>
                                                                <Label text="Nota Cancelación" />
                                                            </Item>
                                                        </GroupItem>
                                                        <GroupItem caption="Cancelación Movimiento" colCount={1}>
                                                            <Item dataField="DevolucionSaldo" editorOptions={devolucionSaldoEditorOptions}>
                                                                <Label text="Devolución Saldo" />
                                                            </Item>
                                                        </GroupItem>
                                                    </Tab>

                                                    <Tab title="CFDi" colCount={2}>
                                                        <Item dataField="CFDIFechaTimbrado" editorOptions={datosCFDIEditorOptions}>
                                                            <Label text="Fecha Certificación" />
                                                        </Item>
                                                        <Item dataField="CFDIUUID" editorOptions={datosCFDIEditorOptions}>
                                                            <Label text="UUID" />
                                                        </Item>
                                                        <Item dataField="CFDICancelado" editorType="dxCheckBox" editorOptions={datosCFDIEditorOptions}>
                                                            <Label text="CFDi Cancelado" />
                                                        </Item>
                                                        <Item dataField="cfdi_TipoComprobante" editorOptions={datosCFDIEditorOptions}>
                                                            <Label text="Tipo de Comprobante" />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={customerPaymentPayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Archivos Anexos" >
                                                        <div className="widget-container">
                                                            <FileUploader
                                                                multiple={true}
                                                                accept="*"
                                                                uploadMode="useButtons"
                                                                chunkSize={20000000}
                                                                maxFileSize={20000000}
                                                                uploadChunk={uploadChunk}
                                                                disabled={!activateAttachments}
                                                            />
                                                        </div>
                                                        <div id="wrapper" className="show-widget" >
                                                            <div id="widget-area">
                                                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                                                    <Permissions
                                                                        create={false}
                                                                        copy={false}
                                                                        move={false}
                                                                        delete={activateAttachments}
                                                                        rename={false}
                                                                        upload={false}
                                                                        download={true}
                                                                    >
                                                                    </Permissions>
                                                                </FileManager>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
