import React, { useState, useEffect, useRef, useMemo } from 'react';

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

// Toast
import { Toast } from 'devextreme-react/toast';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';

import { Auth, API, Storage } from 'aws-amplify';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

import { GroupItem as GroupItemGrid } from 'devextreme-react/data-grid';

//CheckBox
import CheckBox from 'devextreme-react/check-box';

//ContextMenu
import ContextMenu from 'devextreme-react/context-menu';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCashRegister } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { faLightbulb } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faWindowClose } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons";
import { faLinkSlash } from "@fortawesome/pro-solid-svg-icons";

import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import '../ListStyle.css';

//FileUploader
import FileUploader from 'devextreme-react/file-uploader';

//FileManager
import FileManager, { Permissions } from 'devextreme-react/file-manager';

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import TransactionStatus from "../TransactionStatus/TransactionStatus.js";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function CashRegister(props) {
    const history = useHistory();

    const [isDetail, setIsDetail] = useState(false);
    const [id, setId] = useState();
    const [permissionLevel, setPermissionLevel] = useState(props.nivel);
    const [cashRegisterPayload, setCashRegisterPayload] = useState({
        header: {},
        detail: [],
        movEstatusLog: []
    });

    const [contextMenuItems, setContextMenuItems] = useState([]);
    const [autoExpandAll, setAutoExpandAll] = useState(true);

    // setValue para el control tab
    const [value, setValue] = React.useState(0);

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    const [activateUpdateForm, setActivateUpdateForm] = useState(false);
    const [activateUpdateGrid, setActivateUpdateGrid] = useState(false);
    const [activateDeleteGrid, setActivateDeleteGrid] = useState(false);
    const [activateSaveButton, setActivateSaveButton] = useState(false);
    const [activateDeleteButton, setActivateDeleteButton] = useState(false);
    const [activateCancelButton, setActivateCancelButton] = useState(false);
    const [activateConfirmButton, setActivateConfirmButton] = useState(false);
    const [activateGetCustPaymentButton, setActivateGetCustPaymentButton] = useState(false);
    const [activatePDFButton, setActivatePDFButton] = useState(false);
    const [activateAttachments, setActivateAttachments] = useState(false);
    const [activateDates, setActivateDates] = useState(false);
    const [activateClearPayments, setActivateClearPayments] = useState(false);

    //Ref para el grid Articulos
    const [gridTransaccionesCashRegisterRef, setGridTransaccionesCashRegisterRef] = useState(React.createRef());

    //Ref para el form
    const [formDatosGeneralesRef, setFormDatosGeneralesRef] = useState(React.createRef());

    const [attachments, setAttachments] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const exportFormats = ['xlsx'];

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    const filterValue = [['Estatus', '=', 'Sin Afectar']];

    //Ref para el grid Tablero de Control
    const [gridTableroRef, setGridTableroRef] = useState(React.createRef());

    function onAutoExpandAllChanged() {
        setAutoExpandAll(!autoExpandAll)
    };

    async function getCashRegisterRecords(sqlFilter) {
        try {
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'list',
                    sqlFilter: sqlFilter,
                    salesAccess: props.salesAccess,
                    sucursal: props.sucursal,
                    usuario: props.usuario,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var cashRegisterGet = await API.get(apiName, path, myInit);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return cashRegisterGet;
    }

    const store = new CustomStore({
        key: 'ID',
        load(loadOptions) {
            let sqlFilter = '(';
            [
                'filter'
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    var filters = loadOptions[i];

                    var filtersLength = filters.length;

                    for (var i = 0; i <= filtersLength; i++) {
                        var singleFilter = filters[i];
                        var singleFilterType = typeof singleFilter;
                        var singleFilterIsArray = Array.isArray(singleFilter);
                        var singleFilterIsDate = singleFilter instanceof Date;

                        if (singleFilter === 'NULL') {
                            singleFilter = '0';
                        }

                        if (singleFilterType.toString() === 'object' && singleFilterIsArray) {
                            sqlFilter += '(';

                            var subFiltersLength = singleFilter.length;

                            for (var i2 = 0; i2 <= subFiltersLength; i2++) {
                                var singleSubFilter = singleFilter[i2];
                                var singleSubFilterType = typeof singleSubFilter;
                                var singleSubFilterIsArray = Array.isArray(singleSubFilter);
                                var singleSubFilterIsDate = singleSubFilter instanceof Date

                                if (singleSubFilter === 'NULL') {
                                    singleSubFilter = '0';
                                }

                                if (singleSubFilterType.toString() === 'object' && singleSubFilterIsArray) {
                                    sqlFilter += '(';

                                    var subSubFiltersLength = singleSubFilter.length;

                                    for (var i3 = 0; i3 <= subSubFiltersLength; i3++) {
                                        var singleSubSubFilter = singleSubFilter[i3];
                                        var singleSubSubFilterType = typeof singleSubSubFilter;
                                        var singleSubSubFilterIsArray = Array.isArray(singleSubSubFilter);
                                        var singleSubSubFilterIsDate = singleSubSubFilter instanceof Date

                                        if (singleSubSubFilter === 'NULL') {
                                            singleSubSubFilter = '0';
                                        }

                                        if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsArray) {
                                            sqlFilter += '(';

                                            var subSubSubFiltersLength = singleSubSubFilter.length;

                                            for (var i4 = 0; i4 <= subSubSubFiltersLength; i4++) {
                                                var singleSubSubSubFilter = singleSubSubFilter[i4];
                                                var singleSubSubSubFilterType = typeof singleSubSubSubFilter;
                                                var singleSubSubSubFilterIsArray = Array.isArray(singleSubSubSubFilter);
                                                var singleSubSubSubFilterIsDate = singleSubSubSubFilter instanceof Date

                                                if (singleSubSubSubFilter === 'NULL') {
                                                    singleSubSubSubFilter = '0';
                                                }

                                                if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsArray) {
                                                    sqlFilter += '(';

                                                    var subSubSubSubFiltersLength = singleSubSubSubFilter.length;

                                                    for (var i5 = 0; i5 <= subSubSubSubFiltersLength; i5++) {
                                                        var singleSubSubSubSubFilter = singleSubSubSubFilter[i5];
                                                        var singleSubSubSubSubFilterType = typeof singleSubSubSubSubFilter;
                                                        var singleSubSubSubSubFilterIsArray = Array.isArray(singleSubSubSubSubFilter);
                                                        var singleSubSubSubSubFilterIsDate = singleSubSubSubSubFilter instanceof Date

                                                        if (singleSubSubSubSubFilter === 'NULL') {
                                                            singleSubSubSubSubFilter = '0';
                                                        }

                                                        if (singleSubSubSubSubFilterType.toString() === 'string') {
                                                            if (singleSubSubSubSubFilter === 'contains') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'notcontains') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT LIKE';
                                                                singleSubSubSubSubFilter = 'NOT LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'startswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '%\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === 'endswith') {
                                                                filters[i][i2][i3][i4][i5] = 'LIKE';
                                                                singleSubSubSubSubFilter = 'LIKE';

                                                                filters[i][i2][i3][i4][i5 + 1] = '\'%' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                            }
                                                            else if (singleSubSubSubSubFilter === '=' || singleSubSubSubSubFilter === '<>' || singleSubSubSubSubFilter === '>' || singleSubSubSubSubFilter === '>=' || singleSubSubSubSubFilter === '<' || singleSubSubSubSubFilter === '<=') {
                                                                if (!filters[i][i2][i3][i4][i5 + 1])
                                                                    filters[i][i2][i3][i4][i5 + 1] = 'NULL';
                                                                else if (typeof filters[i][i2][i3][i4][i5 + 1] === 'string') {
                                                                    filters[i][i2][i3][i4][i5 + 1] = '\'' + filters[i][i2][i3][i4][i5 + 1] + '\'';
                                                                }
                                                            }
                                                            else if (singleSubSubSubSubFilter === '!') {
                                                                filters[i][i2][i3][i4][i5] = 'NOT';
                                                                singleSubSubSubSubFilter = 'NOT';
                                                            }

                                                            sqlFilter += ' ' + singleSubSubSubSubFilter + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'object' && singleSubSubSubSubFilterIsDate) {
                                                            sqlFilter += ' \'' + singleSubSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'number') {
                                                            sqlFilter += ' ' + singleSubSubSubSubFilter.toString() + ' ';
                                                        }
                                                        else if (singleSubSubSubSubFilterType.toString() === 'boolean') {
                                                            if (singleSubSubSubSubFilter.toString() === 'true')
                                                                sqlFilter += '1 ';
                                                            else if (singleSubSubSubSubFilter.toString() === 'false' || singleSubSubSubSubFilter === null || singleSubSubSubSubFilter === undefined)
                                                                sqlFilter += '0 ';
                                                        }
                                                    }
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'string') {
                                                    if (singleSubSubSubFilter === 'contains') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'notcontains') {
                                                        filters[i][i2][i3][i4] = 'NOT LIKE';
                                                        singleSubSubSubFilter = 'NOT LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'startswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '%\'';
                                                    }
                                                    else if (singleSubSubSubFilter === 'endswith') {
                                                        filters[i][i2][i3][i4] = 'LIKE';
                                                        singleSubSubSubFilter = 'LIKE';

                                                        filters[i][i2][i3][i4 + 1] = '\'%' + filters[i][i2][i3][i4 + 1] + '\'';
                                                    }
                                                    else if (singleSubSubSubFilter === '=' || singleSubSubSubFilter === '<>' || singleSubSubSubFilter === '>' || singleSubSubSubFilter === '>=' || singleSubSubSubFilter === '<' || singleSubSubSubFilter === '<=') {
                                                        if (!filters[i][i2][i3][i4 + 1])
                                                            filters[i][i2][i3][i4 + 1] = 'NULL';
                                                        else if (typeof filters[i][i2][i3][i4 + 1] === 'string') {
                                                            filters[i][i2][i3][i4 + 1] = '\'' + filters[i][i2][i3][i4 + 1] + '\'';
                                                        }
                                                    }
                                                    else if (singleSubSubSubFilter === '!') {
                                                        filters[i][i2][i3][i4] = 'NOT';
                                                        singleSubSubSubFilter = 'NOT';
                                                    }

                                                    sqlFilter += ' ' + singleSubSubSubFilter + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'object' && singleSubSubSubFilterIsDate) {
                                                    sqlFilter += ' \'' + singleSubSubSubFilter.toISOString().split('T')[0] + '\' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'number') {
                                                    sqlFilter += ' ' + singleSubSubSubFilter.toString() + ' ';
                                                }
                                                else if (singleSubSubSubFilterType.toString() === 'boolean') {
                                                    if (singleSubSubSubFilter.toString() === 'true')
                                                        sqlFilter += '1 ';
                                                    else if (singleSubSubSubFilter.toString() === 'false' || singleSubSubSubFilter === null || singleSubSubSubFilter === undefined)
                                                        sqlFilter += '0 ';
                                                }
                                            }

                                            sqlFilter += ')';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'string') {
                                            if (singleSubSubFilter === 'contains') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'notcontains') {
                                                filters[i][i2][i3] = 'NOT LIKE';
                                                singleSubSubFilter = 'NOT LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'startswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '%\'';
                                            }
                                            else if (singleSubSubFilter === 'endswith') {
                                                filters[i][i2][i3] = 'LIKE';
                                                singleSubSubFilter = 'LIKE';

                                                filters[i][i2][i3 + 1] = '\'%' + filters[i][i2][i3 + 1] + '\'';
                                            }
                                            else if (singleSubSubFilter === '=' || singleSubSubFilter === '<>' || singleSubSubFilter === '>' || singleSubSubFilter === '>=' || singleSubSubFilter === '<' || singleSubSubFilter === '<=') {
                                                if (!filters[i][i2][i3 + 1])
                                                    filters[i][i2][i3 + 1] = 'NULL';
                                                else if (typeof filters[i][i2][i3 + 1] === 'string') {
                                                    filters[i][i2][i3 + 1] = '\'' + filters[i][i2][i3 + 1] + '\'';
                                                }
                                            }
                                            else if (singleSubSubFilter === '!') {
                                                filters[i][i2][i3] = 'NOT';
                                                singleSubSubFilter = 'NOT';
                                            }
                                            sqlFilter += ' ' + singleSubSubFilter + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'object' && singleSubSubFilterIsDate) {
                                            sqlFilter += ' \'' + singleSubSubFilter.toISOString().split('T')[0] + '\' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'number') {
                                            sqlFilter += ' ' + singleSubSubFilter.toString() + ' ';
                                        }
                                        else if (singleSubSubFilterType.toString() === 'boolean') {
                                            if (singleSubSubFilter.toString() === 'true')
                                                sqlFilter += '1 ';
                                            else if (singleSubSubFilter.toString() === 'false' || singleSubSubFilter === null || singleSubSubFilter === undefined)
                                                sqlFilter += '0 ';
                                        }
                                    }

                                    sqlFilter += ')';
                                }
                                else if (singleSubFilterType.toString() === 'string') {
                                    if (singleSubFilter === 'contains') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'notcontains') {
                                        filters[i][i2] = 'NOT LIKE';
                                        singleSubFilter = 'NOT LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'startswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '%\'';
                                    }
                                    else if (singleSubFilter === 'endswith') {
                                        filters[i][i2] = 'LIKE';
                                        singleSubFilter = 'LIKE';

                                        filters[i][i2 + 1] = '\'%' + filters[i][i2 + 1] + '\'';
                                    }
                                    else if (singleSubFilter === '=' || singleSubFilter === '<>' || singleSubFilter === '>' || singleSubFilter === '>=' || singleSubFilter === '<' || singleSubFilter === '<=') {
                                        if (!filters[i][i2 + 1])
                                            filters[i][i2 + 1] = 'NULL';
                                        else if (typeof filters[i][i2 + 1] === 'string') {
                                            filters[i][i2 + 1] = '\'' + filters[i][i2 + 1] + '\'';
                                        }
                                    }
                                    else if (singleSubFilter === '!') {
                                        filters[i][i2] = 'NOT';
                                        singleSubFilter = 'NOT';
                                    }
                                    sqlFilter += ' ' + singleSubFilter + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'object' && singleSubFilterIsDate) {
                                    sqlFilter += ' \'' + singleSubFilter.toISOString().split('T')[0] + '\' ';
                                }
                                else if (singleSubFilterType.toString() === 'number') {
                                    sqlFilter += ' ' + singleSubFilter.toString() + ' ';
                                }
                                else if (singleSubFilterType.toString() === 'boolean') {
                                    if (singleSubFilter.toString() === 'true')
                                        sqlFilter += '1 ';
                                    else if (singleSubFilter.toString() === 'false' || singleSubFilter === null || singleSubFilter === undefined)
                                        sqlFilter += '0 ';
                                }
                            }

                            sqlFilter += ')';
                        }
                        else if (singleFilterType.toString() === 'string') {
                            if (singleFilter === 'contains') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'notcontains') {
                                filters[i] = 'NOT LIKE';
                                singleFilter = 'NOT LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'startswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'' + filters[i + 1] + '%\'';
                            }
                            else if (singleFilter === 'endswith') {
                                filters[i] = 'LIKE';
                                singleFilter = 'LIKE';

                                filters[i + 1] = '\'%' + filters[i + 1] + '\'';
                            }
                            else if (singleFilter === '=' || singleFilter === '<>' || singleFilter === '>' || singleFilter === '>=' || singleFilter === '<' || singleFilter === '<=') {
                                if (!filters[i + 1])
                                    filters[i + 1] = 'NULL';
                                else if (typeof filters[i + 1] === 'string') {
                                    filters[i + 1] = '\'' + filters[i + 1] + '\'';
                                }
                            }
                            else if (singleFilter === '!') {
                                filters[i] = 'NOT';
                                singleFilter = 'NOT';
                            }
                            sqlFilter += ' ' + singleFilter + ' ';
                        }
                        else if (singleFilterType.toString() === 'object' && singleFilterIsDate) {
                            sqlFilter += ' \'' + singleFilter.toISOString().split('T')[0] + '\' ';
                        }
                        else if (singleFilterType.toString() === 'number') {
                            sqlFilter += ' ' + singleFilter.toString() + ' ';
                        }
                        else if (singleFilterType.toString() === 'boolean') {
                            if (singleFilter.toString() === 'true')
                                sqlFilter += '1 ';
                            else if (singleFilter.toString() === 'false' || singleFilter === null || singleFilter === undefined)
                                sqlFilter += '0 ';
                        }
                    }

                    sqlFilter += ')';
                }
            });

            async function getRecords() {
                if (sqlFilter === '(')
                    sqlFilter = '';

                var records = await getCashRegisterRecords(sqlFilter);
                return {
                    data: records,
                    totalCount: records.length
                };
            }

            return getRecords()
                .then(function (result) {
                    return result;
                })
                .catch(() => { throw new Error('Data Loading Error'); });
        },
    });

    var fechaEmisionEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var fechaPostfechadoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: !activateDates
        };
    }, [activateDates]);

    var fechaRealDepositoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: !activateDates
        };
    }, [activateDates]);

    var movimientoEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    var cajaEditorOptions = useMemo(() => {
        return {
            width: '100%',
            readOnly: true
        };
    }, []);

    async function uploadChunk(file, uploadInfo) {
        try {
            var responseS3 = await Storage.put("/sccc/" + id + "/" + file.name, uploadInfo.chunkBlob, {
                contentType: file.type,
                ACL: 'public-read'
            });

            //Obtenemos el nombre del bucket
            let bucketName = Storage._config.AWSS3.bucket;


            let apiName = 'AdminSC';
            let path = '/uploadattachment';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    action: 'register',
                    bucketName: bucketName,
                    type: 'sccc',
                    id: id,
                    Key: "/sccc/" + id + "/" + file.name,
                    fileName: file.name,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var attachmentsResult = await API.post(apiName, path, myInit);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/sccc/" + id);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (err) {
            console.log("Error uploading file:", err);
        }
    }

    async function onAttachmentDeleting(e) {
        var fileName = e.item.key;

        //Primero borramos el archivo de S3
        var attachmentsDelete = await Storage.remove("/sccc/" + id + "/" + fileName);

        //Despues borramos el attachment en Intelisis
        let bucketName = Storage._config.AWSS3.bucket;

        let apiName = 'AdminSC';
        let path = '/uploadattachment';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            body: {
                action: 'delete',
                bucketName: bucketName,
                type: 'sccc',
                id: id,
                Key: "/sccc/" + id + "/" + fileName,
                fileName: fileName,
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var attachmentsResult = await API.post(apiName, path, myInit);

        //Finalmente sincronizamos el state
        var attachmentsGet = await Storage.list("/sccc/" + id);
        var attachmentsAux = [];

        for (var attachmentsGetIndex in attachmentsGet) {
            var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

            var singleAttachment = {
                name: singleAttachmentsGet.key.split('/').pop(),
                isDirectory: false,
                size: singleAttachmentsGet.size,
            };

            attachmentsAux.push(singleAttachment);
        }

        setAttachments(attachmentsAux);
    };

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };

        a.addEventListener('click', clickHandler, false);

        a.click();

        return a;
    };

    async function onItemDownloading(e) {
        e.cancel = true;

        const downloadFileResponse = await Storage.get("/sccc/" + id + "/" + e.item.name, { download: true });

        downloadBlob(downloadFileResponse.Body, e.item.name);
    };

    function openBlob(blob, filename) {
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
    };

    async function onAttachmentOpen(e) {
        const downloadFileResponse = await Storage.get("/sccc/" + id + "/" + e.file.name, { download: true });

        openBlob(downloadFileResponse.Body, e.file.name);
    };

    const getCashRegisterPayload = React.useCallback(async (ID) => {
        try {
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    ID: ID,
                    action: 'payload',
                    usuario: props.usuario,
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
                }
            }

            var payloadGet = await API.get(apiName, path, myInit);

            setCashRegisterPayload(payloadGet);

            //Obtenemos los attachments
            var attachmentsGet = await Storage.list("/sccc/" + ID);
            var attachmentsAux = [];

            for (var attachmentsGetIndex in attachmentsGet) {
                var singleAttachmentsGet = attachmentsGet[attachmentsGetIndex];

                var singleAttachment = {
                    name: singleAttachmentsGet.key.split('/').pop(),
                    isDirectory: false,
                    size: singleAttachmentsGet.size,
                };

                attachmentsAux.push(singleAttachment);
            }

            setAttachments(attachmentsAux);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }

        return payloadGet;
    }, [history, props.usuario]);

    function editCashRegister(data) {
        return (
            <Link to={`/CashRegister/${data.data.ID}`}>
                <button class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded" onClick={() => editButton(data.data.ID)}><FontAwesomeIcon icon={permissionLevel === 'Editar' ? faPencilAlt : faGlasses} /> {permissionLevel === 'Editar' ? 'Editar' : 'Ver'}</button>
            </Link>
        )
    }

    const newButton = React.useCallback(async () => {
        var sucursal = parseInt(props.sucursal);

        if (!sucursal && sucursal !== 0) {
            alert('Configuración de usuario incorrecta. Comunicarse con el área de sistemas');

            setCashRegisterPayload({
                header: {},
                detail: [],
                movEstatusLog: []
            });

            setIsDetail(false);

            return;
        }

        try {
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                queryStringParameters: {
                    action: 'new',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            };

            var payloadNew = await API.get(apiName, path, myInit);

            setCashRegisterPayload(payloadNew);

            setAttachments([]);

            setIsDetail(true);
            setId(undefined);

            setActivateUpdateForm(true);
            setActivateUpdateGrid(true);
            setActivateDeleteGrid(true);
            setActivateSaveButton(true);
            setActivateDeleteButton(false);
            setActivateCancelButton(false);
            setActivateConfirmButton(false);
            setActivateGetCustPaymentButton(false);
            setActivateClearPayments(false);
            setActivatePDFButton(false);
            setActivateAttachments(false);
            setActivateDates(false);

            setVisibleLoadPanel(false);
            setContextMenuItems([]);
        }
        catch (e) {
            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [history, props.sucursal, props.usuario]);

    const editButton = React.useCallback(async (ID) => {
        try {
            if (!window.event.metaKey && !window.event.ctrlKey) {
                setVisibleLoadPanel(true);

                setIsDetail(true);
                setId(ID);

                var payload = await getCashRegisterPayload(ID);

                if (permissionLevel === 'Editar') {
                    if (payload.header.Estatus === 'Sin Afectar') {
                        setActivateUpdateForm(true);
                        setActivateUpdateGrid(true);
                        setActivateDeleteGrid(true);
                        setActivateSaveButton(true);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(true);
                        setActivateConfirmButton(true);
                        setActivateGetCustPaymentButton(true);
                        setActivateClearPayments(true);
                        setActivatePDFButton(true);
                        setActivateAttachments(true);
                        setActivateDates(false);

                        setVisibleLoadPanel(false);
                        setContextMenuItems([]);
                    }
                    else if (payload.header.Estatus === 'Pendiente') {
                        setActivateUpdateForm(true);
                        setActivateUpdateGrid(true);
                        setActivateDeleteGrid(false);
                        setActivateSaveButton(true);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(true);
                        setActivateConfirmButton(true);
                        setActivateGetCustPaymentButton(false);
                        setActivateClearPayments(true);
                        setActivatePDFButton(true);
                        setActivateAttachments(true);
                        setActivateDates(true);

                        setVisibleLoadPanel(false);
                        setContextMenuItems([]);
                    }
                    else if (payload.header.Estatus === 'En Revision') {
                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(false);
                        setActivateDeleteGrid(false);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(false);
                        setActivateConfirmButton(false);
                        setActivateGetCustPaymentButton(false);
                        setActivateClearPayments(false);
                        setActivatePDFButton(true);
                        setActivateAttachments(false);
                        setActivateDates(false);

                        setVisibleLoadPanel(false);
                        setContextMenuItems([]);
                    }
                    else if (payload.header.Estatus === 'Cancelado') {
                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(false);
                        setActivateDeleteGrid(false);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(false);
                        setActivateConfirmButton(false);
                        setActivateGetCustPaymentButton(false);
                        setActivateClearPayments(false);
                        setActivatePDFButton(true);
                        setActivateAttachments(false);
                        setActivateDates(false);

                        setVisibleLoadPanel(false);
                        setContextMenuItems([]);
                    }
                    else if (payload.header.Estatus === 'Concluido') {
                        setActivateUpdateForm(false);
                        setActivateUpdateGrid(false);
                        setActivateDeleteGrid(false);
                        setActivateSaveButton(false);
                        setActivateDeleteButton(false);
                        setActivateCancelButton(false);
                        setActivateConfirmButton(false);
                        setActivateGetCustPaymentButton(false);
                        setActivateClearPayments(false);
                        setActivatePDFButton(true);
                        setActivateAttachments(false);
                        setActivateDates(false);

                        setVisibleLoadPanel(false);
                        setContextMenuItems([]);
                    }
                }
                else if (permissionLevel === 'Ver') {
                    setActivateUpdateForm(false);
                    setActivateUpdateGrid(false);
                    setActivateDeleteGrid(false);
                    setActivateSaveButton(false);
                    setActivateDeleteButton(false);
                    setActivateCancelButton(false);
                    setActivateConfirmButton(false);
                    setActivateGetCustPaymentButton(false);
                    setActivateClearPayments(false);
                    setActivatePDFButton(true);
                    setActivateAttachments(false);
                    setActivateDates(false);

                    setVisibleLoadPanel(false);
                    setContextMenuItems([]);
                }

                setVisibleLoadPanel(false);
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (!ID || ID === 'undefined') {
                newButton();
            }
        }
    }, [getCashRegisterPayload, newButton, permissionLevel]);

    async function cancelEditButton() {
        setIsDetail(false);
    };

    const saveButton = React.useCallback(async () => {
        try {
            setVisibleLoadPanel(true);

            var ID = cashRegisterPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridTransaccionesCashRegisterRef.current) {
                var gridTransacciones = gridTransaccionesCashRegisterRef.current.instance();

                gridTransacciones.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'saveChanges',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal,
                    s3Bucket: Storage._config.AWSS3.bucket
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                if (!ID) {
                    setVisibleToast(true);
                    setMessageToast('Se creó el Corte de Caja con éxito');
                    setTypeToast('success')

                    ID = payloadPost.newID;

                    editButton(ID);

                    history.push('/CashRegister/' + ID.toString());
                }
                else {
                    setVisibleToast(true);
                    setMessageToast('Se actualizó el Corte de Caja con éxito');
                    setTypeToast('success')
                }
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [cashRegisterPayload, editButton, gridTransaccionesCashRegisterRef, history, props.sucursal, props.usuario]);

    async function confirmButton() {
        var ID = cashRegisterPayload.header.ID;

        try {
            setVisibleLoadPanel(true);

            //Guardamos los cambios del grid
            if (gridTransaccionesCashRegisterRef.current) {
                var gridTransacciones = gridTransaccionesCashRegisterRef.current.instance();

                gridTransacciones.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'apply',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se afectó el Corte de Caja con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function getCustPaymentsButton() {
        try {
            setVisibleLoadPanel(true);

            var ID = cashRegisterPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridTransaccionesCashRegisterRef.current) {
                var gridTransacciones = gridTransaccionesCashRegisterRef.current.instance();

                gridTransacciones.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'getCustPaymentInfo',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Transacciones actualizadas con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function updFechaListado() {
        await saveButton();

        var ID = cashRegisterPayload.header.ID;

        try {
            setVisibleLoadPanel(true);

            //Guardamos los cambios del grid
            if (gridTransaccionesCashRegisterRef.current) {
                var gridTransacciones = gridTransaccionesCashRegisterRef.current.instance();

                gridTransacciones.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'updFechaListado',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Fechas actualizadas con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function updFechaDeposito() {
        await saveButton();

        var ID = cashRegisterPayload.header.ID;

        try {
            setVisibleLoadPanel(true);

            //Guardamos los cambios del grid
            if (gridTransaccionesCashRegisterRef.current) {
                var gridTransacciones = gridTransaccionesCashRegisterRef.current.instance();

                gridTransacciones.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'updFechaDeposito',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Fechas actualizadas con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    async function releasePaymentsButton() {
        try {
            if (!window.confirm('¿Desea liberar todos los Cobros?'))
                return;

            setVisibleLoadPanel(true);

            var ID = cashRegisterPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'releasePayments',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se liberaron los Cobros del Corte de Caja');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    async function voidButton() {
        try {
            if (!window.confirm('¿Desea cancelar el Corte de Caja?'))
                return;

            setVisibleLoadPanel(true);

            var ID = cashRegisterPayload.header.ID;

            //Guardamos los cambios del grid
            if (gridTransaccionesCashRegisterRef.current) {
                var gridTransacciones = gridTransaccionesCashRegisterRef.current.instance();

                gridTransacciones.saveEditData();
            }

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'void',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se canceló el Corte de Caja');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    };

    function refreshButton() {
        if (gridTableroRef.current) {
            var gridTablero = gridTableroRef.current.instance();

            gridTablero.refresh();
        }
    }

    async function deleteButton() {
        try {
            if (!window.confirm('¿Desea eliminar el Corte de Caja?'))
                return;

            setVisibleLoadPanel(true);

            var ID = cashRegisterPayload.header.ID;

            if (!ID) {
                //Vamos al tablero de control
                history.push('/CashRegister');
                return;
            }

            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    payload: cashRegisterPayload,
                    action: 'delete',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast('Corte de Caja eliminado con éxito');
                setTypeToast('success')

                setIsDetail(false);

                //Vamos al tablero de control
                history.push('/CashRegister');
            }
            else if (payloadPost.ok === 'false') {
                setVisibleLoadPanel(false);

                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    function printButton() {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/PdfRender?type=cashregister&id=" + id, "_blank");
    };

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            var hash;

            try {
                hash = e.target.location.hash;
            }
            catch (e) {
                hash = '';
            }

            if (hash === '#/CashRegister') {
                history.push('/CashRegister');

                window.location.reload();
            }
        });

        async function initialize() {
            setVisibleLoadPanel(true);

            setVisibleLoadPanel(false);
        }

        initialize();

        //En caso de solicitar un ID especifico, redireccionamos a ese ID
        if (!props.match.isExact) {
            var urlID = props.location.pathname.replace('/CashRegister/', '');

            if (urlID === 'undefined')
                urlID = undefined;

            if (urlID)
                editButton(urlID);
        }

        // eslint-disable-next-line
    }, []);

    function onHidingToast() {
        setVisibleToast(false);
    }

    function contextMenuItemClick(e) {
        // if(e.itemData.text === 'Seleccionar Todo'){
        //     selectAllButton();
        // }
        // else if(e.itemData.text === 'Eliminar Selección'){
        //     selectNothingButton();
        // }
    };

    function estatusRelatedRecords(data) {
        return (
            <TransactionStatus {...props} Estatus={data.data.Estatus} />
        )
    };

    async function deleteCustPymtButton(ID, CxcID, MovID) {
        try {
            if (!window.confirm('¿Desea liberar el Cobro ' + MovID + '?'))
                return;

            setVisibleLoadPanel(true);

            var ID = cashRegisterPayload.header.ID;

            //Despues enviamos todo al backend
            let apiName = 'AdminSC';
            let path = '/procesacortecaja';

            let myInit = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                },
                body: {
                    ID: ID,
                    CxcID: CxcID,
                    payload: cashRegisterPayload,
                    action: 'releaseCustPymt',
                    username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                    usuario: props.usuario,
                    sucursal: props.sucursal
                }
            }

            var payloadPost = await API.post(apiName, path, myInit);

            if (payloadPost.ok === 'true') {
                setVisibleToast(true);
                setMessageToast('Se eliminó el Cobro con éxito');
                setTypeToast('success')
            }
            else if (payloadPost.ok === 'false') {
                setVisibleToast(true);
                setMessageToast(payloadPost.message);
                setTypeToast('error')
            }

            editButton(ID);

            setVisibleLoadPanel(false);
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }

    function deleteCustPymt(data) {
        if (permissionLevel === 'Editar' && cashRegisterPayload.header.HabilitarLiberar === 'true') {
            return (
                <button class="bg-red-500 hover:bg-red-700 text-white  py-1 px-3 rounded" onClick={() => deleteCustPymtButton(data.data.ID, data.data.CxcID, data.data.MovID)}><FontAwesomeIcon icon={faTrash} /></button>
            );
        }
    };

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    return (
        <div>
            {isDetail === false ? (
                <div class="bg-gray-200 m-2">
                    <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faList} size="lg" /> Cortes Caja</h1>
                </div>
            ) :
                (
                    <div class="bg-gray-200 m-2">
                        <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faCashRegister} size="xl" /> {(cashRegisterPayload.header ? cashRegisterPayload.header.Movimiento : 'Corte Caja')}</h1>
                        <TransactionStatus {...props} Estatus={cashRegisterPayload.header.Estatus} />
                    </div>
                )}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="bg-gray-200 m-2">
                {isDetail === false ? (
                    <div class="bg-white p-3 shadow-xl">

                        {permissionLevel === 'Editar' ?
                            (
                                <div>
                                    <Link to="/CashRegister/undefined">
                                        <button class="mb-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => newButton()}><FontAwesomeIcon icon={faFileAlt} /> Nuevo Corte Caja</button>
                                    </Link>

                                    <button onClick={refreshButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            ) :
                            (
                                <div>
                                    <button onClick={refreshButton} type="submit" class="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                                    </button>
                                </div>
                            )}

                        <div className="options mb-2">
                            <div className="option">
                                <CheckBox text="Expandir todos los grupos"
                                    value={autoExpandAll}
                                    onValueChanged={onAutoExpandAllChanged}></CheckBox>
                            </div>
                        </div>
                        <DataGrid
                            height={800}
                            id="gridContainer"
                            columnsAutoWidth={true}
                            filterBuilder={filterBuilder}
                            dataSource={store}
                            defaultFilterValue={filterValue}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnResizingMode={"widget"}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showRowLines={true}
                            showColumnLines={true}
                            remoteOperations={{ filtering: true }}
                            ref={gridTableroRef}
                            onExporting={onExporting}
                        >
                            <Scrolling rowRenderingMode='virtual'></Scrolling>
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode='compact'
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true}
                            />
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={filterBuilderPopupPosition} />

                            <HeaderFilter visible={true} />

                            <GroupPanel visible={true} />
                            <SearchPanel visible={true} />
                            <ColumnChooser
                                enabled={true}
                                mode='select'
                            >
                                <ColumnChooserSearch
                                    enabled={true}
                                />

                                <ColumnChooserSelection
                                    allowSelectAll={true}
                                    selectByClick={true}
                                    recursive={true}
                                />
                            </ColumnChooser>
                            <Grouping autoExpandAll={autoExpandAll} />
                            <StateStoring enabled={true} type="localStorage" storageKey="gridContainerCashRegister" />
                            <Export enabled={true} />

                            <Column caption="" alignment="center" width={100} cellRender={editCashRegister} />
                            <Column dataField="MovID" dataType="string" width={110} caption="Folio" />
                            <Column dataField="FechaEmision" caption="Fecha Emisión" dataType="date" width={130} />
                            <Column dataField="Estatus" dataType="string" width={120} />
                            <Column caption="Plaza" dataField="Plaza" dataType="string" width={150} visible={false} />

                            <Column dataField="ID" dataType="number" width={150} visible={false} />
                            <Column dataField="Mov" dataType="string" width={190} caption="Tipo Movimiento" visible={false} />
                            <Column dataField="Movimiento" dataType="string" width={190} visible={false} />
                            <Column dataField="Caja" caption="Caja" dataType="string" width={150} />
                            <Column dataField="NombreCaja" caption="Nombre Caja" dataType="string" width={150} visible={false} />
                            <Column dataField="Importe" caption="Importe" dataType="number" width={150} >
                                <Format
                                    type="currency"
                                    precision={2}
                                />
                            </Column>
                            <Column dataField="SCFechaPostfechado" caption="Fecha Listado" dataType="date" width={200} />
                            <Column dataField="SCFechaRealDeposito" caption="Fecha Real Depósito" dataType="date" width={200} />

                            <Column dataField="Sucursal" dataType="number" width={150} visible={false} />
                            <Column dataField="NombreSucursal" dataType="string" width={190} visible={false} />
                            <Column dataField="Usuario" dataType="string" width={190} visible={false} />
                            <Column dataField="NombreUsuario" dataType="string" width={190} visible={false} />

                            <Summary>
                                <GroupItem
                                    column="Importe"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe {0}"
                                    showInGroupFooter={false}>
                                </GroupItem>
                                <TotalItem
                                    column="Importe"
                                    summaryType="sum"
                                    valueFormat="$,##0.##"
                                    displayFormat="Importe {0}"
                                    showInColumn="Importe">
                                </TotalItem>
                            </Summary>
                        </DataGrid>
                    </div>
                ) : (<div />)}
                <Route exact path="/CashRegister/:ID" render={({ match }) => {
                    return (
                        <div class="bg-gray-200">
                            {isDetail.toString() === 'true' ?
                                (
                                    <div>
                                        <Link to="/CashRegister">
                                            <button onClick={cancelEditButton} type="submit" class="mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} /> Regresar
                                            </button>
                                        </Link>

                                        {activateSaveButton.toString() === 'true' ?
                                            (
                                                <button onClick={saveButton} type="submit" class="ml-4 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faSave} /> Guardar Cambios
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateConfirmButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={confirmButton} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faPlay} /> Afectar
                                                </button>
                                            ) : (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateGetCustPaymentButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={getCustPaymentsButton} type="submit" class="ml-4 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faLightbulb} /> Sugerir Corte
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDates.toString() === 'true' && id ?
                                            (
                                                <button onClick={updFechaListado} type="submit" class="ml-4 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faCalendarDays} /> Fecha Listado
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDates.toString() === 'true' && id ?
                                            (
                                                <button onClick={updFechaDeposito} type="submit" class="ml-4 mb-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faCalendarDays} /> Fecha Real Depósito
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateClearPayments.toString() === 'true' && id ?
                                            (
                                                <button onClick={releasePaymentsButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faLinkSlash} /> Liberar Cobros
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateDeleteButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={deleteButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activateCancelButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={voidButton} type="submit" class="ml-4 mb-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faWindowClose} /> Cancelar
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        {activatePDFButton.toString() === 'true' && id ?
                                            (
                                                <button onClick={printButton} type="submit" class="ml-4 mb-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                                                    <FontAwesomeIcon icon={faFilePdf} /> PDF
                                                </button>
                                            ) :
                                            (
                                                <div class="inline-block"></div>
                                            )}

                                        <div class="bg-white p-4 shadow-xl">
                                            <ContextMenu
                                                dataSource={contextMenuItems}
                                                width={200}
                                                target="#gridTransacciones"
                                                onItemClick={contextMenuItemClick}
                                            />

                                            <Form
                                                id="formTransaccion"
                                                labelMode={'outside'}
                                                formData={cashRegisterPayload.header}
                                                readOnly={!activateUpdateForm}
                                                showColonAfterLabel={false}
                                                labelLocation={'top'}
                                                ref={formDatosGeneralesRef}
                                            >
                                                <TabbedItem>
                                                    <TabPanelOptions deferRendering={false} showNavButtons={true} />
                                                    <Tab title="Datos Generales" colCount={3}>
                                                        <GroupItem>
                                                            <GroupItem caption="General">
                                                                <Item cssClass="boldText" dataField="Movimiento" editorOptions={movimientoEditorOptions} />
                                                                <Item dataField="FechaEmision" editorType="dxDateBox" editorOptions={fechaEmisionEditorOptions}>
                                                                    <Label text={"Fecha Emisión"} />
                                                                </Item>
                                                                <Item dataField="NombreCaja" editorOptions={cajaEditorOptions}>
                                                                    <Label text={"Caja"} />
                                                                </Item>
                                                            </GroupItem>
                                                            <GroupItem caption="Fechas">
                                                                <Item dataField="SCFechaPostfechado" editorType="dxDateBox" editorOptions={fechaPostfechadoEditorOptions}>
                                                                    <Label text={"Fecha Listado"} />
                                                                </Item>
                                                                <Item dataField="SCFechaRealDeposito" editorType="dxDateBox" editorOptions={fechaRealDepositoEditorOptions}>
                                                                    <Label text={"Fecha Real Depósito"} />
                                                                </Item>
                                                            </GroupItem>
                                                        </GroupItem>
                                                        <GroupItem caption="Transacciones" colSpan={2} >
                                                            <DataGrid
                                                                id="gridTransaccionesCashRegister"
                                                                columnsAutoWidth={true}
                                                                filterBuilder={filterBuilder}
                                                                dataSource={cashRegisterPayload.detail}
                                                                allowColumnReordering={true}
                                                                allowColumnResizing={true}
                                                                columnResizingMode={"widget"}
                                                                showBorders={true}
                                                                rowAlternationEnabled={true}
                                                                showRowLines={true}
                                                                showColumnLines={true}
                                                                ref={gridTransaccionesCashRegisterRef}
                                                                onExporting={onExporting}
                                                            >
                                                                <Scrolling rowRenderingMode='virtual'></Scrolling>
                                                                <Paging defaultPageSize={10} />
                                                                <Pager
                                                                    visible={true}
                                                                    allowedPageSizes={allowedPageSizes}
                                                                    displayMode='compact'
                                                                    showPageSizeSelector={true}
                                                                    showInfo={true}
                                                                    showNavigationButtons={true}
                                                                />
                                                                <HeaderFilter visible={true} />
                                                                <FilterRow visible={true} />
                                                                <FilterPanel visible={true} />
                                                                <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                                                <GroupPanel visible={true} />
                                                                <SearchPanel visible={true} />
                                                                <ColumnChooser
                                                                    enabled={true}
                                                                    mode='select'
                                                                >
                                                                    <ColumnChooserSearch
                                                                        enabled={true}
                                                                    />

                                                                    <ColumnChooserSelection
                                                                        allowSelectAll={true}
                                                                        selectByClick={true}
                                                                        recursive={true}
                                                                    />
                                                                </ColumnChooser>
                                                                <Grouping autoExpandAll={autoExpandAll} />
                                                                <StateStoring enabled={true} type="localStorage" storageKey="gridTransaccionesCashRegister" />
                                                                <Export enabled={true} />

                                                                <Editing
                                                                    mode="cell"
                                                                    allowUpdating={activateUpdateGrid}
                                                                    allowAdding={false}
                                                                    allowDeleting={activateDeleteGrid}
                                                                    useIcons={true}
                                                                />
                                                                <Sorting mode="none" />

                                                                <Column dataField="Renglon" width={190} visible={false} allowEditing={false} />
                                                                <Column dataField="SCRama" dataType="string" caption="Pedido" width={90} allowEditing={false} />
                                                                <Column dataField="FechaEmisionPedido" dataType="date" caption="Fecha Pedido" width={120} allowEditing={false} />
                                                                <Column dataField="MovID" dataType="string" caption="No. Pago" width={120} allowEditing={false} />
                                                                <Column dataField="FormaPago" dataType="string" caption="Forma Pago" width={300} allowEditing={false} />
                                                                <Column dataField="Importe" caption="Importe" dataType="number" width={120} allowEditing={false} >
                                                                    <Format
                                                                        type="currency"
                                                                        precision={2}
                                                                    />
                                                                </Column>
                                                                <Column dataField="SCFechaPostfechado" dataType="date" caption="Fecha Listado" width={120} allowEditing={activateDates} />
                                                                <Column dataField="SCFechaRealDeposito" dataType="date" caption="Fecha Real Depósito" width={135} allowEditing={activateDates} />
                                                                <Column caption="" alignment="center" width={60} cellRender={deleteCustPymt} />
                                                                <Summary>
                                                                    <GroupItemGrid
                                                                        column="Importe"
                                                                        summaryType="sum"
                                                                        valueFormat="$,##0.##"
                                                                        displayFormat="Importe {0}"
                                                                        showInGroupFooter={false}>
                                                                    </GroupItemGrid>
                                                                    <TotalItem
                                                                        column="Importe"
                                                                        summaryType="sum"
                                                                        valueFormat="$,##0.##"
                                                                        displayFormat="Importe {0}"
                                                                        showInColumn="Importe">
                                                                    </TotalItem>
                                                                </Summary>
                                                            </DataGrid>
                                                        </GroupItem>
                                                    </Tab>
                                                    <Tab title="Observaciones" >
                                                        <Item dataField="Observaciones" editorType="dxTextArea" >
                                                            <Label text="Observaciones" visible={false} />
                                                        </Item>
                                                    </Tab>
                                                    <Tab title="Log Estatus">
                                                        <DataGrid
                                                            id="gridLogEstatus"
                                                            columnsAutoWidth={true}
                                                            dataSource={cashRegisterPayload.movEstatusLog}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            columnResizingMode={"widget"}
                                                            showBorders={true}
                                                            rowAlternationEnabled={true}
                                                            showRowLines={true}
                                                            showColumnLines={true}
                                                        >
                                                            <HeaderFilter visible={false} />
                                                            <Column dataField="Usuario" dataType="string" width={350} caption="Usuario" />
                                                            <Column caption="Estatus" alignment="center" width={150} cellRender={estatusRelatedRecords} />
                                                            <Column dataField="Fecha" dataType="datetime" width={300} caption="Fecha" />
                                                        </DataGrid>
                                                    </Tab>
                                                    <Tab title="Archivos Anexos" >
                                                        <div className="widget-container">
                                                            <FileUploader
                                                                multiple={true}
                                                                accept="*"
                                                                uploadMode="useButtons"
                                                                chunkSize={20000000}
                                                                maxFileSize={20000000}
                                                                uploadChunk={uploadChunk}
                                                                disabled={!activateAttachments}
                                                            />
                                                        </div>
                                                        <div id="wrapper" className="show-widget" >
                                                            <div id="widget-area">
                                                                <FileManager id="attachmentManager" fileSystemProvider={attachments} onItemDeleting={onAttachmentDeleting} onItemDownloading={onItemDownloading} onSelectedFileOpened={onAttachmentOpen}>
                                                                    <Permissions
                                                                        create={false}
                                                                        copy={false}
                                                                        move={false}
                                                                        delete={activateAttachments}
                                                                        rename={false}
                                                                        upload={false}
                                                                        download={true}
                                                                    >
                                                                    </Permissions>
                                                                </FileManager>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </TabbedItem>
                                            </Form>
                                        </div>
                                    </div>
                                ) :
                                (
                                    <div></div>
                                )
                            }
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );
}
